import { Injectable } from '@angular/core';
import { AppProxy } from './app.proxy';
import { Game } from '../Models/Game';
import { BehaviorSubject } from 'rxjs';
import { LogService } from './log.service';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  private $game = new BehaviorSubject<Game>(null);

  constructor(
    private appProxy: AppProxy,
    private logService: LogService,
    private firebaseService: FirebaseService) { }

  getGameAsync(): BehaviorSubject<Game> {
    return this.$game;
  }

  getGameValue(): Game {
    return this.$game.getValue();
  }

  setGameAsync(game: Game) {
    if (game) {
      game = this.initGame(game);
      this.setLocalStorageGame(game.uGameId);
    } else {
      this.deleteLocalStorageGame();
    }
    return this.$game.next(game);
  }

  deleteCurrentGame() {
    this.setGameAsync(null);
  }

  isGameExist() {
    return this.getGameValue() ? true : false;
  }

  setLocalStorageGame(uGameId: string) {
    localStorage.setItem('uGameId', uGameId);
  }

  deleteLocalStorageGame() {
    localStorage.removeItem('uGameId');
  }

  initGame(game: Game): Game {
    const date = game.dtDate.toString()
      .substring(
        game.dtDate.toString().indexOf('(') + 1,
        game.dtDate.toString().indexOf('+') !== -1 ? game.dtDate.toString().indexOf('+') : game.dtDate.toString().indexOf(')')
      );
    game.dtDate = new Date(parseInt(date));
    return game;
  }

  async loadGameService(uGameId: string, game: Game = null): Promise<boolean> {
    uGameId = uGameId ? uGameId : game.uGameId;
    if (!this.isGameExist() || this.getGameValue().uGameId !== uGameId) {
      if (!game) {
        game = await this.getGameService(uGameId);
        if (!game) {
          this.setGameAsync(null);
          return Promise.resolve(false);
        }
      }
      this.setGameAsync(game);
      return Promise.resolve(true);
    }
    return Promise.resolve(true);
  }

  getGameService(uGameId: string): Promise<Game> {
    console.log(`GameService.getGameService`);
    // debugger שליפה מהפיירסטור
    return this.appProxy
      .post('GetFirestoreGame', { uGameId: uGameId, nvSecretCode: null })
      .then(res => {
        if (res) {
          return res;
        } else {
          throw new Error(`server error. response: ${res}`);
        }
      })
      .catch((error: any) => {
        console.error(`GameService.getGameService error. message: ${error.message}`);
        this.logService.logError(`GameService.getGameService -> GetFirestoreGame error. uGameId: ${uGameId}`, error.message);
        return null;
      });
  }

  getGameBySecretCode(nvSecretCode: string): Promise<Game> {
    console.log(`GameService.getGameBySecretCode`);
    // debugger שליפה מהפיירסטור
    return this.appProxy
      .post('GetFirestoreGame', { nvSecretCode: nvSecretCode })
      .then(res => {
        if (res) {
          return res;
        } else {
          return null;
        }
      })
      .catch((error: any) => {
        console.error(`GameService.getGameBySecretCode error. message: ${error.message}`);
        this.logService.logError(`GameService.getGameBySecretCode -> GetFirestoreGame error. nvSecretCode: ${nvSecretCode}`, error.message);
        return null;
      });
  }

  getSingleFields(nvColumnName: string, nvTableName: string, nvWhereColumnName: string, uSomeId: string): Promise<any> {
    console.log(`GameService.getSingleFields`);
    return this.appProxy
      .post('GetSingleColumnValue', {
        nvColumnName: nvColumnName,
        nvTableName: nvTableName,
        nvWhereColumnName: nvWhereColumnName,
        uSomeId: uSomeId
      })
      .then(result => {
        if (result) {
          const value = {};
          result.forEach(keyValue => {
            value[keyValue.Key] = keyValue.Value;
          });
          return value;
        } else {
          throw new Error(`server error. response: ${result}`);
        }
      })
      .catch((error: any) => {
        console.error(`GameService.getSingleFields error. message: ${error.message}`);
        this.logService.logError(`GameService.getSingleFields -> GetSingleColumnValue error. nvColumnName: ${nvColumnName}, nvTableName: ${nvTableName}, nvWhereColumnName: ${nvWhereColumnName}, uSomeId: ${uSomeId}`, error.message);
        return null;
      });
  }

  async checkMaxUserGame(uGameId: string): Promise<boolean> {
    console.log(`GameService.checkMaxUserGame`);
    const bServerAvailable = await this.firebaseService.getServiceAble('GameService.checkMaxUserGame');
    if (bServerAvailable) {
      return this.appProxy
        .post('CheckMaxUserGame', { uGameId: uGameId })
        .then(result => {
          if (result) {
            return true;
          } else {
            throw new Error(`server error. response: ${result}`);
          }
        })
        .catch((error: any) => {
          console.error(`GameService.checkMaxUserGame error. message: ${error.message}`);
          this.logService.logError(`GameService.checkMaxUserGame -> CheckMaxUserGame. uGameId: ${uGameId}`, error.message);
          return false;
        });
    } else {
      return false;
    }
  }

  async registerUserGame(uGameId: string, nvMailAddress: string): Promise<boolean> {
    console.log(`GameService.registerUserGame`);
    const bServerAvailable = await this.firebaseService.getServiceAble('GameService.registerUserGame');
    if (bServerAvailable) {
      nvMailAddress = nvMailAddress.toLocaleLowerCase();
      return this.appProxy
        .post('UserGameInsert', {
          uGameId: uGameId,
          nvMailAddress: nvMailAddress
        })
        .then(result => {
          if (result) {
            return true;
          } else {
            throw new Error(`server error. response: ${result}`);
          }
        })
        .catch((error: any) => {
          console.error(`GameService.registerUserGame error. message: ${error.message}`);
          this.logService.logError(`GameService.registerUserGame -> UserGameInsert. uGameId: ${uGameId}, nvMailAddress: ${nvMailAddress}`, error.message);
          return false;
        });
    } else {
      return false;
    }
  }

}
