import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FireAuthService } from '../../Services/fireauth.service';
import { FirestoreService } from '../../Services/firestore.service';
import { WaitIconService } from '../../Services/wait-icon.service';
import { AlertService } from '../../Services/alert.service';
import { AlertType } from '../../Models/Alert';

@Component({
  selector: 'mm-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {

  bSubmited: boolean = true;
  bEditPassword: boolean = false;
  userForm = new FormGroup({
    nvDisplayName: new FormControl('', [<any>Validators.required]),
    bMailingList: new FormControl(false)
  });

  constructor(
    private alertService: AlertService,
    private fireAuthService: FireAuthService,
    private firestoreService: FirestoreService,
    private waitIconService: WaitIconService) {

  }

  ngOnInit() {
    this.userForm.get('nvDisplayName').setValue(this.firestoreService.getUserFirestore().nvDisplayName);
    this.userForm.get('bMailingList').setValue(this.firestoreService.getUserFirestore().bMailingList);
  }

  async submitForm(userForm: FormGroup) {
    if (!userForm.valid) {
      // this.alertService.addAlert('זוהו נתונים לא תקינים', AlertType.danger);
      this.alertService.addAlert('user-details.alert.invalid-data', AlertType.danger);
      return;
    }

    this.bSubmited = false;
    this.waitIconService.setWaitVisible(true);
    const updateRes = await this.firestoreService.updateUserDetails(this.fireAuthService.getUserFirebase().uid, true,
      userForm.get('bMailingList').value,
      userForm.get('nvDisplayName').value.trim());
    this.waitIconService.setWaitVisible(false);
    this.bSubmited = true;
    if (updateRes) {
      // this.alertService.addAlert('שמרנו את פרטי המשתמש שלך.', AlertType.success);
      this.alertService.addAlert('user-details.alert.save-your-data', AlertType.success);
    } else {
      // this.alertService.addAlert('אירעה שגיאה במהלך עדכון פרטי המשתמש', AlertType.danger);
      this.alertService.addAlert('user-details.alert.error-occurred-while-update-data', AlertType.danger);
    }
  }
}
