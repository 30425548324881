import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[fit-text]',
    exportAs: 'fit-text'
})
export class FitText {

    @Input() public container: any;
    @Input() public maxFontSize: number = 200;
    @Input() public minFontSize: number = 0;
    @Input() public maxHeight: number;
    @Input() public marginRight: boolean;
    @Input() public marginTop: boolean;

    private innerText: string = '';
    private height: number = 0;
    private width: number = 0;

    constructor(private el: ElementRef) { }

    ngOnInit() {
        if (this.el.nativeElement.style.fontSize == '')
            this.el.nativeElement.style.fontSize = '50px';
    }

    ngAfterViewChecked() {
        if ((this.el.nativeElement.innerText && this.el.nativeElement.innerText != this.innerText) ||
            this.container.clientHeight != this.height ||
            this.container.clientWidth != this.width) {
            this.innerText = this.el.nativeElement.innerText;
            this.height = this.container.clientHeight;
            this.width = this.container.clientWidth;
            this.setFontSize();
        }
    }

    setFontSize() {

        let oldMarginRight = this.el.nativeElement.style.marginRight;
        this.el.nativeElement.style.marginRight = 0;
        let oldMarginTop = this.el.nativeElement.style.marginTop;
        this.el.nativeElement.style.marginTop = 0;


        this.maxHeight = this.maxHeight && this.maxHeight < this.container.clientHeight ? this.maxHeight : this.container.clientHeight;

        let fontSize = parseInt(this.el.nativeElement.style.fontSize);
        fontSize = fontSize <= this.maxFontSize ? fontSize : this.maxFontSize;

        //plus
        for (let i = fontSize; i <= this.maxFontSize; i++) {
            if ((this.container.scrollHeight <= this.container.clientHeight ||
                this.container.scrollWidth <= (this.container.clientWidth)) &&
                (this.el.nativeElement.clientHeight < this.maxHeight)) {
                fontSize++;
                this.el.nativeElement.style.fontSize = fontSize + "px";
            }
        }
        //minus
        for (let i = fontSize; i >= this.minFontSize; i--) {
            if (this.container.scrollHeight > this.container.clientHeight ||
                this.container.scrollWidth > (this.container.clientWidth + 1) ||
                (this.el.nativeElement.clientHeight > this.maxHeight)) {
                fontSize--;
                this.el.nativeElement.style.fontSize = fontSize + "px";
            }
        }

        this.el.nativeElement.style.marginRight = this.marginRight ? 'calc(50% - ' + (this.el.nativeElement.clientWidth / 2) + 'px)' : this.el.nativeElement.style.marginRight = oldMarginRight;
        this.el.nativeElement.style.marginTop = this.marginTop ? ((this.container.clientHeight - this.el.nativeElement.clientHeight) / 2) + 'px' : oldMarginTop;

    }

}