import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatSelectModule, MatInputModule, MatIconModule, MatMenuModule, MatToolbarModule,
  MatProgressBarModule, MatCardModule, MatButtonToggleModule, MatCheckboxModule, MatFormFieldModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/analytics';
// import { HubConnection } from '@aspnet/signalr';

import { FitText } from './Directives/fit-text';

import { SYSTEM } from '../assets/config/config';

// components
import { AppComponent } from './Components/app/app.component';
import { WaitIconComponent } from './Components/wait-icon/wait-icon.component';
import { LoginComponent } from './Components/login/login.component';
import { LoginEmailComponent } from './Components/login-email/login-email.component';
import { ResetPasswordComponent } from './Components/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './Components/forgot-password/forgot-password.component';
import { ResetAndVerifyComponent } from './Components/reset-and-verify/reset-and-verify.component';
import { VerifyEmailComponent } from './Components/verify-email/verify-email.component';
import { UserDetailsComponent } from './Components/user-details/user-details.component';
import { EditPasswordComponent } from './Components/edit-password/edit-password.component';
import { GameNavigationComponent } from './Components/game-navigation/game-navigation.component';
import { GameComponent } from './Components/game/game.component';
import { RegisterGameComponent } from './Components/register-game/register-game.component';
import { ClickerComponent } from './Components/clicker/clicker.component';
import { ClickerModeBtnComponent } from './Components/clicker-mode-btn/clicker-mode-btn.component';
import { ClickerStatusComponent } from './Components/clicker-status/clicker-status.component';
import { ClickerGameStatusComponent } from './Components/clicker-game-status/clicker-game-status.component';
import { ClickerBroadcastComponent } from './Components/clicker-broadcast/clicker-broadcast.component';
import { ClickerQuestionComponent } from './Components/clicker-question/clicker-question.component';
import { ClickerScoreComponent } from './Components/clicker-score/clicker-score.component';
import { ClickerVotingStatusComponent } from './Components/clicker-voting-status/clicker-voting-status.component';
import { ChatMessageComponent } from './Components/chat-message/chat-message.component';
import { AlertsComponent } from './Components/alerts/alerts.component';

// services
import { MyFirstInterceptor } from './Services/json-header.interceptor';
import { AppProxy } from './Services/app.proxy';
import { WaitIconService } from './Services/wait-icon.service';
import { AlertService } from './Services/alert.service';
import { LoginService } from './Services/login.service';
import { PermissionService } from './Services/permission.service';
import { NotificationService } from './Services/notification.service';
import { FireAuthService } from './Services/fireauth.service';
import { FireAnalyticsService } from './Services/fireanalytics.service';
import { FirebaseService } from './Services/firebase.service';
import { FirestoreService } from './Services/firestore.service';
import { FacebookService } from './Services/facebook.service';
import { GlobalService } from './Services/global.service';
import { ChatSupportComponent } from './Components/chat-support/chat-support.component';
import { ChatbotIconComponent } from './Components/chatbot-icon/chatbot-icon.component';
import { ClickerReportComponent } from './Components/clicker-report/clicker-report.component';
import { ReportGameNavigationComponent } from './Components/report-game-navigation/report-game-navigation.component';
import { BroadcastMessageComponent } from './Components/broadcast-message/broadcast-message.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
const routes: Routes = [
  { path: 'resetAndVerify', component: ResetAndVerifyComponent },
  { path: 'forgotPassword', component: ForgotPasswordComponent },
  { path: 'login', component: LoginComponent },
  { path: 'loginEmail', component: LoginEmailComponent },
  { path: 'userDetail', component: UserDetailsComponent, canActivate: [PermissionService] },
  { path: 'gameNavigation', component: GameNavigationComponent, canActivate: [PermissionService] },
  {
    path: 'gameReport/:uClickerId', component: ReportGameNavigationComponent, children: [
      { path: 'gameNavigation', component: GameNavigationComponent},
      { path: 'result/:uGameId/:nvName', component: ClickerReportComponent}
    ]
  },
  {
    path: ':uGameId', component: GameComponent, canActivate: [PermissionService], children: [
      { path: 'register', component: RegisterGameComponent },
      { path: 'clicker', component: ClickerComponent },
    ]
  },

  { path: '', pathMatch: 'full', redirectTo: 'login' },
  // {path:':uGameId',component:LoginComponent},
];

@NgModule({
  declarations: [
    AppComponent,
    WaitIconComponent,
    LoginComponent,
    UserDetailsComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    ResetAndVerifyComponent,
    VerifyEmailComponent,
    EditPasswordComponent,
    GameNavigationComponent,
    GameComponent,
    RegisterGameComponent,
    ClickerComponent,
    ClickerModeBtnComponent,
    ClickerStatusComponent,
    ClickerGameStatusComponent,
    ClickerBroadcastComponent,
    ClickerQuestionComponent,
    ClickerScoreComponent,
    ClickerVotingStatusComponent,
    LoginEmailComponent,
    FitText,
    ChatMessageComponent,
    AlertsComponent,
    ChatSupportComponent,
    ChatbotIconComponent,
    ClickerReportComponent,
    ReportGameNavigationComponent,
    BroadcastMessageComponent
  ],
  imports: [
    DragDropModule,
    HttpClientModule,
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true }),
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(SYSTEM.firebase_config),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAnalyticsModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatCardModule,
    MatButtonToggleModule,
    MDBBootstrapModule.forRoot(),
    MatCheckboxModule,
    MatFormFieldModule,
    TranslateModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
    // HubConnection
  ],
  providers: [
    ScreenTrackingService,
    FireAnalyticsService,
    FireAuthService,
    FirebaseService,
    FirestoreService,
    FacebookService,
    AppProxy,
    WaitIconService,
    AlertService,
    NotificationService,
    GlobalService,
    LoginService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyFirstInterceptor,
      multi: true
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
