export class ClickerResponse {
    public uResponseId: string;
    public uClickerOnGameId: string;
    public uFighterId: string;
    public uQuestionId: string;
    public uAnswerId: string;
    public iResponseTime: number;
    public iIndexResponse: number;
    public iSource: number;

    constructor() {
        this.uResponseId = null;
        this.uClickerOnGameId = null;
        this.uFighterId = null;
        this.uQuestionId = null;
        this.uAnswerId = null;
        this.iResponseTime = null;
        this.iIndexResponse = 1;
        this.iSource = null;
    }
}
