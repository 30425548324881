import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LogService } from './log.service';

// firebase.User
// This gives you a Google Access Token. You can use it to access the Google API.
// var token = result.credential.accessToken;
// additionalUserInfo.isNewUser
// photoURL;


@Injectable()
export class FirebaseService {

    constructor(private fireDataBase: AngularFireDatabase, private logService: LogService) { }

    getSnapshotChanges(path: string): Promise<any> {
        return this.fireDataBase
            .object(path)
            .snapshotChanges()
            .pipe(
                take(1),
                map(data => data.payload.val())
            )
            .toPromise()
            .catch((error: firebase.FirebaseError) => {
                console.error(`FirebaseService.getSnapshotChanges error. message: ${error.message}`);
                this.logService.logError(`FirebaseService.getSnapshotChanges -> fireDataBase.snapshotChanges. path: ${path}, error code: ${error.code} `, error.message);
                return null;
            });
    }

    getGameOnAirChanges(uGameId: string): Observable<any> {
        return this.fireDataBase
            .object(`games/${uGameId}/isOnAir`)
            .valueChanges();
    }

    getVotingStatusChanges(uGameId: string): Observable<any> {
        return this.fireDataBase
            .object(`games/${uGameId}/iVotingStatus`)
            .valueChanges();
    }

    getCurrentQuestionChanges(uGameId: string): Observable<any> {
        return this.fireDataBase
            .object(`games/${uGameId}/currentQuestion`)
            .valueChanges();
    }

    getShowResultsChanges(uGameId: string): Observable<any> {
        return this.fireDataBase
            .object(`games/${uGameId}/bShowResults`)
            .valueChanges();
    }

    getEndCollectAndCalcScoresChanges(uGameId: string): Observable<any> {
        return this.fireDataBase
            .object(`games/${uGameId}/bEndCollectAndCalcScores`)
            .valueChanges();
    }

    getGameLiveBroadcastChanges(uGameId: string): Observable<any> {
        return this.fireDataBase
            .object(`games/${uGameId}/bLiveBroadcast`)
            .valueChanges();
    }

    getGameBroadcastChanges(uGameId: string): Observable<any> {
        return this.fireDataBase
            .object(`games/${uGameId}/gameBroadcast`)
            .valueChanges();
    }

    getMaxAnswerChanges(uGameId: string): Observable<any> {
        return this.fireDataBase
            .object(`games/${uGameId}/iMaxAnswer`)
            .valueChanges();
    }

    getbFighterOnly(uGameId: string): Promise<any> {
        return this.getSnapshotChanges(`games/${uGameId}/bFighterOnly`);
    }
   
    // getVotingOpenTime(uGameId: string): Promise<any> {
    //     return this.getSnapshotChanges(`games/${uGameId}/iVotingOpenTime`);
    // }

    // getQuestionAnswers(uGameId: string): Promise<any> {
    //     return this.getSnapshotChanges(`games/${uGameId}/currentQuestion/lstAnswers`);
    // }

    async getServiceAble(funcName: string): Promise<boolean> {
        const isAvailable = await this.getSnapshotChanges(`serviceAble`);
        if (!isAvailable) {
            console.error(`${funcName} error. server unavailable`);
            return false;
        }
        return true;
    }
   
    async getHideBroadcast(): Promise<boolean> {
        const isHideBroadcast = await this.getSnapshotChanges(`bHideBroadcast`);
        return isHideBroadcast;
    }
    async getLicenseTextSnapshot(): Promise<string> {
        const nvLicenseText = await this.getSnapshotChanges(`nvLicenseText`);
        return nvLicenseText;
    }
}
