import { Injectable } from '@angular/core';
import { AppProxy } from './app.proxy';
import { ClickerOnGame } from '../Models/ClickerOnGame';
import { LogService } from './log.service';
import { FirestoreService } from './firestore.service';
import { SysTable } from './SysTables.service';
import { FirebaseService } from './firebase.service';
import { FirestoreClickerOnGame, FirestoreClickerOnGameGroup } from '../Models/FirestoreClickerOnGame';

@Injectable({
  providedIn: 'root'
})
export class ClickerOnGameService {

  constructor(
    private appProxy: AppProxy,
    private firestoreService: FirestoreService,
    private firebaseService: FirebaseService,
    private logService: LogService) { }

  getClickerOnGameServer(uGameId: string, uClickerId: string): Promise<Array<ClickerOnGame>> {
    if (!uGameId || !uClickerId) {
      return null;
    }
    // debugger getServiceAble
    //TODO: tammy - שהפונקציה תעדכן את המשתמש לשחקן פעיל
    console.log(`ClickerOnGameService.getClickerOnGameServer`);
    return this.appProxy
      .post('GetClickerOnGameByGameWrap', {
        uClickerId: uClickerId,
        uGameId: uGameId
      })
      .then(res => {
        if (!res) {
          throw new Error(`server error. response: ${res}`);
        }
        return res;
      })
      .catch((error: any) => {
        console.error(`ClickerOnGameService.getClickerOnGameServer error. message: ${error.message}`);
        this.logService.logError(`ClickerOnGameService.getClickerOnGameServer -> GetClickerOnGameByGameWrap. uGameId: ${uGameId}, uClickerId: ${uClickerId}`, error.message);
        return null;
      });
  }

  async updateClickerStatus(uid: string, iUserId: number, uGameId: string, clickerOnGameGroups: Array<FirestoreClickerOnGameGroup>, iStatus: number): Promise<boolean> {
    let clickerOnGameIds = clickerOnGameGroups.map(r => { return r.uClickerOnGameId; });
    const updateServerRes = await this.updateClickerOnGameStatus(clickerOnGameIds, iStatus, iUserId);
    if (!updateServerRes) {
      return false;
    }

    const cogDetails = { iStatus: iStatus };
    const updateFirestoreRes = await this.firestoreService.addOrUpdateClickerOnGame(uid, uGameId, cogDetails);
    if (!updateFirestoreRes) {
      console.error(`ClickerOnGameService.updateClickerStatus error. The clicker status was updated only in DB`);
      this.logService.logError(`ClickerOnGameService.updateClickerStatus -> firestoreService.addOrUpdateClickerOnGame. uGameId: ${uGameId}, lstClickerOnGame: ${JSON.stringify(clickerOnGameIds)}, iStatus: ${iStatus}`, `The clicker status was updated only in DB`);
      return false;
    }
    return true;
  }

  async addOrUpdateClickerOnGame(uid: string, iUserId: number, uClickerId: string, uGameId: string, clickerOnGame: FirestoreClickerOnGame): Promise<number> {
    let clicker: ClickerOnGame;
    const lstClickerOnGame = Array<ClickerOnGame>();
    clickerOnGame.clickerOnGameGroups.forEach(cg => {
      clicker = new ClickerOnGame(cg.uClickerOnGameId);
      clicker.uGameId = uGameId;
      clicker.uClickerId = uClickerId;
      clicker.nvPlayerName = clickerOnGame.nvPlayerName;
      clicker.uGroupId = cg.uGroupId;
      lstClickerOnGame.push(clicker);
    });
    const addClickerOnGameRes = await this.addOrUpdateClickerOnGameWeb(iUserId, lstClickerOnGame);
    if (addClickerOnGameRes == 1) {
      clickerOnGame.iStatus = SysTable.constantClickerStatus.play;
      delete clickerOnGame.iScore;
      const uddOrUpdateRes = await this.firestoreService.addOrUpdateClickerOnGame(uid, uGameId, clickerOnGame);
      if (uddOrUpdateRes) {
        return addClickerOnGameRes;
      } else {
        console.error(`ClickerOnGameService.addOrUpdateClickerOnGame error. The clickerOnGame was updated only in DB`);
        this.logService.logError(`ClickerOnGameService.addOrUpdateClickerOnGame -> firestoreService.addOrUpdateClickerOnGame. uGameId: ${uGameId}, clickerOnGame: ${JSON.stringify(clickerOnGame)}`, `The clickerOnGame was updated only in DB`);
        return 0;
      }
    } else {
      return addClickerOnGameRes;
    }

  }

  async deleteClickerOnGame(uid: string, iUserId: number, uGameId: string, lstClickerOnGameId: Array<string>): Promise<boolean> {

    let deleteRes = await Promise.all(
      [this.deleteClickerOnGameWeb(iUserId, lstClickerOnGameId),
      this.firestoreService.deleteClickerOnGame(uid, uGameId)])
    if (!deleteRes[0] || !deleteRes[1]) {
      console.error(`ClickerOnGameService.deleteClickerOnGame error. The clickerOnGame wasn't deleted from: ${!deleteRes[0] ? 'DB' : ''}, ${!deleteRes[1] ? 'Firestore' : ''}`);
      this.logService.logError(`ClickerOnGameService.deleteClickerOnGame -> firestoreService.addOrUpdateClickerOnGame. uGameId: ${uGameId}, lstClickerOnGame: ${JSON.stringify(lstClickerOnGameId)}`, `The clickerOnGame wasn't deleted from: ${!deleteRes[0] ? 'DB' : ''}, ${!deleteRes[1] ? 'Firestore' : ''}`);

      return false;
    }


    // let deleteRes = await this.deleteClickerOnGameWeb(iUserId, lstClickerOnGameId);
    // if (!deleteRes) {
    //   return false;
    // }

    // deleteRes = await this.firestoreService.deleteClickerOnGame(uid, uGameId);
    // if (!deleteRes) {
    //   console.error(`ClickerOnGameService.deleteClickerOnGame error. The clickerOnGame was deleted only from DB`);
    //   this.logService.logError(`ClickerOnGameService.deleteClickerOnGame -> firestoreService.addOrUpdateClickerOnGame. uGameId: ${uGameId}, lstClickerOnGame: ${JSON.stringify(lstClickerOnGameId)}`, `The clickerOnGame was deleted only from DB`);
    //   return false;
    // }

    return true;
  }

  //--------------------------------------------------------------------

  async addOrUpdateClickerOnGameWeb(iUserId: number, lstClickerOnGame: Array<ClickerOnGame>): Promise<number> {
    console.log(`ClickerOnGameService.addOrUpdateClickerOnGameWeb`);
    const bServerAvailable = await this.firebaseService.getServiceAble('ClickerOnGameService.addOrUpdateClickerOnGameWeb');
    if (bServerAvailable) {
      return this.appProxy
        .post('AddUserClickerOnGameWeb', {
          lstClickerOnGame: lstClickerOnGame,
          iUserId: iUserId,
          bCheckMaxUser: true
          , bUpdateGroupOnly: true
        })
        .then(async (res) => {
          if (res) {
            return res;
          } else {
            throw new Error(`server error. response: ${res}`);
          }
        })
        .catch((error: any) => {
          console.error(`ClickerOnGameService.addOrUpdateClickerOnGameWeb error. message: ${error.message}`);
          this.logService.logError(`ClickerOnGameService.addOrUpdateClickerOnGameWeb -> AddUserClickerOnGameWeb. lstClickerOnGame: ${JSON.stringify(lstClickerOnGame)}`, error.message);
          return 0;
        });
    } else {
      return Promise.resolve(3);
    }
  }

  async updateClickerOnGameStatus(clickerOnGameId: Array<string>, iStatus: number, iUserId: number): Promise<number> {
    console.log(`ClickerOnGameService.updateClickerOnGameStatus`);
    if (clickerOnGameId.length === 0) {
      return Promise.resolve(0);
    }
    let bServerAvailable = true;
    if (iStatus === SysTable.constantClickerStatus.gameDelayed) {
      bServerAvailable = await this.firebaseService.getServiceAble('ClickerOnGameService.updateClickerOnGameStatus');
    }
    // debugger getServiceAble
    if (bServerAvailable) {
      return this.appProxy
        .post('UpdateClickerOnGameStatus', {
          clickerOnGameId: clickerOnGameId,
          iStatus: iStatus,
          bAvoidActivate: false,
          iUserId: iUserId
        })
        .then(res => {
          if (!res) {
            throw new Error(`server error. response: ${res}`);
          } else if (res != clickerOnGameId.length) {
            throw new Error(`Not all records have been updated. server response: ${res} != clickerOnGameId.length: ${clickerOnGameId.length}`);
          } else {
            return res;
          }
        })
        .catch((error: any) => {
          console.error(`ClickerOnGameService.updateClickerOnGameStatus error. message: ${error.message}`);
          this.logService.logError(`ClickerOnGameService.updateClickerOnGameStatus -> UpdateClickerOnGameStatus. clickerOnGameId: ${clickerOnGameId}, iStatus: ${iStatus}`, error.message);
          return 0;
        });
    } else {
      return 0;
    }
  }

  async deleteClickerOnGameWeb(iUserId: number, lstClickerOnGameId: Array<string>): Promise<boolean> {
    if (lstClickerOnGameId.length === 0) {
      return Promise.resolve(true);
    }
    const bServerAvailable = await this.firebaseService.getServiceAble('ClickerOnGameService.deleteClickerOnGameWeb');
    if (bServerAvailable) {
      console.log(`ClickerOnGameService.deleteClickerOnGameWeb`);
      this.logService.logExtraWebError("deleteClickerOnGameWeb", "iUserId:" + iUserId + ", " + JSON.stringify(lstClickerOnGameId))
      return this.appProxy
        .post('DeleteClickerOnGame', {
          lstClickerOnGameId: lstClickerOnGameId,
          iUserId: iUserId
        })
        .then(res => {
          if (res) {
            return res;
          } else {
            throw new Error(`server error. response: ${res}`);
          }
        })
        .catch((error: any) => {
          console.error(`ClickerOnGameService.deleteClickerOnGameWeb error. message: ${error.message}`);
          this.logService.logError(`ClickerOnGameService.deleteClickerOnGameWeb -> DeleteClickerOnGame. lstClickerOnGameId: ${lstClickerOnGameId}`, error.message);
          return false;
        });
    } else {
      return Promise.resolve(false);
    }
  }
}
