import { Injectable } from '@angular/core';
import { ChatMessage } from '../Models/ChatMessage';
import { FirestoreService } from './firestore.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, QueryFn } from '@angular/fire/firestore';
import { LogService } from './log.service';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChatMessageService {

  constructor(private firestoreService: FirestoreService, private firestore: AngularFirestore, private logService: LogService) {

  }

  getMessagesCollection(): AngularFirestoreCollection<ChatMessage> {
    return this.firestore.collection('messages');
  }

  getMessagesDocumentRef(uid: string): AngularFirestoreDocument<ChatMessage> {
    return this.getMessagesCollection().doc<ChatMessage>(uid);
  }

  getMessageDocumentSnapshot(uid: string): Promise<ChatMessage> {
    const docRef = this.getMessagesDocumentRef(uid);
    return this.firestoreService.getDocumentSnapshotChanges(docRef);
  }

  getPublishMessageValueChange(uGameId: string): Observable<Array<ChatMessage>> {
    const docRef = this.getMessagesDocumentRef("broadcastMessage");
    const coll = docRef.collection(uGameId,ref => ref.where('bPublish','==', true ));
    return coll.valueChanges()
    .pipe(
      map((data: Array<any>) => {
        return data
      })
    )
  }

  // getMessageCollection(uid,uGameId: string, queryFn?: QueryFn) {
  //   return this.getTableDocument(uid,uGameId)
  //     .doc<ChatMessage>(uGameId);
  // }
  // gameData: AngularFirestoreCollection;
  // getTableDocument(uid,uGameId: string) {
  //   if (!this.gameData) {
  //     this.gameData = this.firestore
  //       .collection('messages')
  //       .doc(uid).collection(uGameId);
  //   }
  //   return this.gameData;
  // }
  addMessageGameCollection(nvMessageType: string, uGameId: string, message: ChatMessage) {
    try {
    const docRef = this.getMessagesDocumentRef(nvMessageType);
    docRef.collection(uGameId).doc(message.uMessageId).set(Object.assign({}, message));
  } catch (error) {
    this.logService.logError(`ChatMessageService.addMessageGameCollection. 
    nvMessageType: ${nvMessageType}, 
    uGameId: ${uGameId}, 
    uMessageId: ${message.uMessageId}, 
    nvMessage: ${message.nvMessage}, 
    error code: ${error.code}`,
     error.message);
  }
    // this.getMessageCollection(uid,uGameId)
    //         .set(Object.assign({}, new ChatMessage()));
  }
  addMessageToArray(nvMessageType: string, uGameId: string, message: ChatMessage) {
    try {
      const docRef = this.getMessagesDocumentRef(nvMessageType);
      docRef.collection(uGameId).doc(message.uMessageId).set({
        lstMessage: firebase.firestore.FieldValue.arrayUnion(message.nvMessage)
      }, { merge: true })
    } catch (error) {
      this.logService.logError(`ChatMessageService.addMessageToArray. 
      nvMessageType: ${nvMessageType}, 
      uGameId: ${uGameId}, 
      uMessageId: ${message.uMessageId}, 
      nvMessage: ${message.nvMessage}, 
      error code: ${error.code}`,
       error.message);
    }

  }

}
