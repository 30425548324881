// import { SysTable } from "../services/SysTables.service";
import { Guid } from "./Guid";

export class Clicker {

   public uClickerId: string;
   public iNumber: number;
   public iType: number;
   public iVerison: number;
   public iReciverIndex: number;
   public bIsNew: boolean;


   // public iCreatedByUserId: number;
   // public dtCreatedate: Date;
   // public iLastModifyUserId: number;
   // public dtLastModifyDate: Date;
   // public bSysRowStatus: boolean;

   constructor() {
      this.uClickerId = Guid.newGuid();
      //    this.iType=SysTable.constantClickerType.student;
      //    this.iVerison=SysTable.constantClickerVerison.RF317;
      this.iReciverIndex = 0;
      this.bIsNew = true;
   }
}