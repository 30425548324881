
export class SysTableRow {
    iSysTableRowId: number;
    nvValue: string;
    nvShowValue: string;
    constructor(private _iSysTableRowId: number, private _nvValue: string) {
        this.iSysTableRowId = _iSysTableRowId;
        this.nvValue = _nvValue;
    }
}

export class SysTable {

    static constantStatusTypeGame = { ready: 1, run: 2, done: 3 };
    static constantStatusTypeQuestion = { ready: 30, run: 31, done: 32, delayed: 33 };
    static constantQuestionType = { regular: 4, survey: 5, debate: 6, pluralityQuestion: 71, amount: 120, sale: 121 };
    static constantClickerType = { student: 8, teacher: 7 };
    static constantClickerVerison = { RF217: 9, RF317: 10 };
    static constantColumnName = { logo: 17, openMedia: 18, closeMedia: 19, image: 20, sound: 21, generalBackground: 34, resultBackground: 35, fighterOpenMedia: 180 };
    static constantTableName = { game: 22, topic: 23, question: 24, answer: 25, rescue: 199, clickerOnGame: 201, fighter: 208 };
    static constantClickerStatus = { play: 26, groupDelayed: 27, personalDelayed: 28, gameDelayed: 29 };
    //static constantLevelOfDifficulty = { low: 11, medium: 12, high: 13 };
    static constantGroupStatus = { play: 37, groupDelayed: 38 };
    static constantCloseMediaTime = { beforAnswer: 39, beforResult: 40, afterAll: 41 };
    static constantOpenMediaTime = { beforAnswer: 39, beforAll: 211 };
    static constantGameTimingOpenMedia = { beforeHoarding: 74, afterHoarding: 73, withOutMedia: 72, afterExampleQuestion: 80 };
    static constantGameTimingCloseMedia = { beforWinners: 77, afterWinners: 76, withOutMedia: 75, betweenWinners: 82 };
    static constantUserRoleType = { management: 78, host: 79 };
    static constantFunctionName = { changeClickerMode: 93, none: 0 };
    static constantClickerOnGameType = { regular: 122, judge: 123, rival: 200 };
    static constantResponseSource = { web: 226 };
    public static FunctionName =
        {
            84: { name: "next", index: 1 }//V
            , 85: { name: "previous", index: 1 }//V
            , 86: {}
            , 87: {}
            , 88: { name: "changeGroups", index: 2 }//V
            , 89: { name: "jumpPrev", index: 1 }
            , 90: { name: "jumpNext", index: 1 }
            , 91: { name: "hideScores", index: 2, boolType: true }
            , 92: { name: "playStopMedia", index: 2 }
            , 93: { name: "changeClickerMode", index: 2 }
            , 94: { name: "endGame", index: 10 }
            , 95: { name: "resultsClickers", index: 3 }//V
            , 96: { name: "resultsGroups", index: 3 }//V
            , 215: { name: "domainsClickers", index: 3 }//V
            , 196: { name: "domainsGroups", index: 3 }//V
            , 197: { name: "domainsLeader", index: 3 }//V
            , 194: { name: "fighterScore", index: 3 }//V
            // , 195: { name: "fighterFaildScore", index: 3 }//V
            , 97: { name: "topClickersInGroups", index: 3 }//V
            , 98: { name: "topClickersInGame", index: 3 }//V
            , 99: { name: "freeMoveFunctions", index: 1 }//V
            , 100: { name: 'raiseSurveyQuestion', index: 10 }
            , 101: { name: 'moveTopic', index: 1 }//V
            , 102: { name: 'moveQuestion', index: 1 }
            , 103: { name: 'showResponsesWithinTimer', index: 4, boolType: true }
            , 104: { name: 'showMediaControl', index: 2, boolType: true }
            , 105: { name: 'answersBeforeQuestion', index: 10, boolType: true }
            , 107: { name: 'sortClickers', index: 4 }
            , 114: { name: 'muteBackgroundSound', index: 10, boolType: true }
            , 117: { name: 'changeDisplay', index: 2 }
            , 118: { name: 'showResponseColor', index: 4, boolType: true }
            , 119: { name: 'showPieResults', index: 4, boolType: true }//V
            , 124: { name: 'freeAllClickers', index: 9 }//V
            , 198: { name: 'specialScore', index: 3 }//V
            , 209: { name: 'restartReceiver', index: 10 }//V
            , 210: { name: 'resetResponses', index: 10 }//V
            , 212: { name: 'sortClickersByAnswer', index: 8 }//V
            , 213: { name: 'showLimitedClickers', index: 8, boolType: true }//V
            , 214: { name: 'showCountActiveClicker', index: 4, boolType: true }//V
            , 216: { name: 'showNewResponse', index: 2, boolType: true }//V
        };

    public static constantTeacherClickerButton =
        {
            f1: { id: 57, innerShowValue: "F1", innerIcon: '' }
            , f2: { id: 58, innerShowValue: "F2", innerIcon: '' }
            , up: { id: 59, innerShowValue: 'up', innerIcon: '' }
            , down: { id: 60, innerShowValue: '', innerIcon: 'icon-triangular' }
            , ok: { id: 61, innerShowValue: "ok", innerIcon: '' }
            , start_pause: { id: 62, innerShowValue: '', innerIcon: 'icon-paused' }
            , question: { id: 63, innerShowValue: 6, innerIcon: '' }
            , mode: { id: 64, innerShowValue: 5, innerIcon: '' }
            , report: { id: 65, innerShowValue: 3, innerIcon: '' }
            , result: { id: 66, innerShowValue: 4, innerIcon: '' }
            , power: { id: 67, innerShowValue: 2, innerIcon: '' }
            , stop: { id: 83, innerShowValue: '', innerIcon: 'icon-square' }
        };

    public static constantPermissionTypes =
        {
            noPermission: 125,
            baseEditing: 127,
            fullEditing: 128
        }

    public static constantUserTypes = { level1: 202, level2: 203, level3: 204, level4: 205, level5: 206 }
    public static constantFighterActivityStatus = { active: 168, unfit: 169, delayed: 170 }
    public static constantRescueType = { showResults: 171, blockWrongAnswer: 172, showRightWrongPlayer: 173, skipQuestion: 174, googleHelp: 175, dynamic: 176, showPlayerPerAnswer: 181 }
    public static constantRescueStatus = { ready: 177, active: 178, used: 179 }
    public static readonly VotingStatus = { RegistrationClickers: 220, LoadAnswersClickers: 221, close: 222, selectOption: 223, new: 111 };

    iSysTableId: number;
    nvSysTableName: string;
    nvShowSysTableName: string;
    sysTableRows: Array<SysTableRow> = [];

    constructor(private _iSysTableId: number, private _nvSysTableName: string) {
        this.iSysTableId = _iSysTableId;
        this.nvSysTableName = _nvSysTableName;
    }
}

export class SysTables {

    public static StatusTypeGame: SysTable = new SysTable(1, "StatusTypeGame");
    public static QuestionType: SysTable = new SysTable(2, "QuestionType");
    public static ClickerType: SysTable = new SysTable(3, "ClickerType");
    public static ClickerVerison: SysTable = new SysTable(4, "ClickerVerison");
    public static LevelOfDifficulty: SysTable = new SysTable(5, "LevelOfDifficulty");
    public static ClickerOnGameType: SysTable = new SysTable(6, "ClickerOnGameType");
    public static ColumnName: SysTable = new SysTable(7, "ColumnName");
    public static TableName: SysTable = new SysTable(8, "TableName");
    public static StatusTypeQuestion: SysTable = new SysTable(10, "StatusTypeQuestion");
    public static BeatSound: SysTable = new SysTable(11, "BeatSound");
    public static ClickerStatus: SysTable = new SysTable(9, "ClickerStatus");
    public static GroupStatus: SysTable = new SysTable(12, "GroupStatus");
    public static OpenMediaTime: SysTable = new SysTable(30, "OpenMediaTime");
    public static CloseMediaTime: SysTable = new SysTable(13, "CloseMediaTime");
    public static GameTimingOpenMedia: SysTable = new SysTable(16, "GameTimingOpenMedia");
    public static GameTimingCloseMedia: SysTable = new SysTable(18, "GameTimingCloseMedia");
    public static UserRoleType: SysTable = new SysTable(19, "UserRoleType");
    public static FunctionName: SysTable = new SysTable(20, "FunctionName");
    public static TeacherClickerButton: SysTable = new SysTable(14, "TeacherClickerButton");
    public static PermissionTypes: SysTable = new SysTable(21, "PermissionTypes");
    public static PermissionList: SysTable = new SysTable(22, "PermissionList");
    public static UserTypes: SysTable = new SysTable(23, "UserTypes");
    public static FighterActivityStatus: SysTable = new SysTable(24, "FighterActivityStatus");
    public static RescueType: SysTable = new SysTable(25, "RescueType");
    public static RescueStatus: SysTable = new SysTable(26, "RescueStatus");
}
