import { Component, OnInit, Input } from '@angular/core';
import { SysTable } from '../../Services/SysTables.service';

@Component({
  selector: 'mm-clicker-voting-status',
  templateUrl: './clicker-voting-status.component.html',
  styleUrls: ['./clicker-voting-status.component.css']
})
export class ClickerVotingStatusComponent implements OnInit {

  get staticVotingStatus() {
    return SysTable.VotingStatus;
  }

  @Input() iVotingStatus: number;

  constructor() { }

  ngOnInit() { }

}
