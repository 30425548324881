import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { FireAuthService } from '../../Services/fireauth.service';
import { Router } from '@angular/router';
import { WaitIconService } from '../../Services/wait-icon.service';
import { FirestoreService } from '../../Services/firestore.service';
import { LogService } from '../../Services/log.service';
import { AlertService } from '../../Services/alert.service';
import { AlertType } from '../../Models/Alert';

@Component({
  selector: 'mm-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  bSubmited: boolean = true;
  oobCode: string;
  @Input('oobCode')
  set OobCode(oobCode: string) {
    this.oobCode = oobCode;
    if (oobCode) {
      this.verifyPasswordResetCode(oobCode);
    }
  }

  nvMailAddress: string;
  successReset: boolean = false;
  showPassword: boolean;

  resetPasswordForm = new FormGroup({
    nvPassword: new FormControl('', [<any>Validators.required
      , Validators.minLength(6)
      , Validators.pattern('^[A-Za-z0-9]+$')]),
    nvConfirmPassword: new FormControl('', [<any>Validators.required])
  });

  get checkPassword() {
    return this.resetPasswordForm.get('nvPassword').value == this.resetPasswordForm.get('nvConfirmPassword').value;
  }

  constructor(
    private alertService: AlertService,
    private logService: LogService,
    private fireAuthService: FireAuthService,
    private firestoreService: FirestoreService,
    private router: Router,
    private waitIconService: WaitIconService) { }

  ngOnInit() {
  }

  async verifyPasswordResetCode(oobCode) {
    const verifyRes = await this.fireAuthService.verifyPasswordResetCode(oobCode);
    if (verifyRes) {
      this.nvMailAddress = verifyRes;
    } else {
      // TODO: tammy - לא להציג את השדות והכפתורים
    }
  }

  async submitForm(form: FormGroup) {

    if (!form.valid || form.get('nvPassword').value != form.get('nvConfirmPassword').value) {
      this.alertService.addAlert('כדי לקבוע סיסמה חדשה צריך למלא את כל השדות', AlertType.danger);
      return;
    }

    const nvPassword = form.get('nvPassword').value;

    this.bSubmited = false;
    this.waitIconService.setWaitVisible(true);
    const confirmResetRes = await this.fireAuthService.confirmPasswordReset(this.oobCode, nvPassword);
    if (!confirmResetRes) {
      this.waitIconService.setWaitVisible(false);
      this.bSubmited = true;
      return;
    }

    const signInRes = await this.fireAuthService.signInWithEmailAndPassword(this.nvMailAddress, nvPassword, false);
    if (signInRes.uid) {
      const updatePasswordRes = await this.firestoreService.updateUser(signInRes.uid, { nvPassword: nvPassword }, false);
      if (!updatePasswordRes) {
        console.error(`reset-password -> firestoreService.updateUser error.`);
        this.logService.logError(`reset-password -> firestoreService.updateUser . uid: ${signInRes.uid}, email: ${this.nvMailAddress}, new nvPassword: ${nvPassword}`, 'failed to update new password in firestore');
      }
    } else {
      const code = signInRes.code ? signInRes.code : '';
      const message = signInRes.message ? signInRes.message : `signIn result = ${signInRes}`;
      console.error(`reset-password -> signInWithEmailAndPassword error. message: ${message}`);
      this.logService.logError(`reset-password -> signInWithEmailAndPassword . email: ${this.nvMailAddress}, nvPassword: ${nvPassword}, error code: ${code}`, message);
    }

    this.waitIconService.setWaitVisible(false);
    this.bSubmited = true;

    this.successReset = true;
    this.alertService.addAlert('הסיסמה התעדכנה בהצלחה', AlertType.success);
    this.router.navigate(['login'], { queryParams: { nvMailAddress: this.nvMailAddress } });
  }
}
