import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FirebaseService } from 'src/app/Services/firebase.service';
import { GameService } from 'src/app/Services/game.service';
import { ResponseService } from 'src/app/Services/response.service';

@Component({
  selector: 'mm-clicker-report',
  templateUrl: './clicker-report.component.html',
  styleUrls: ['./clicker-report.component.css']
})
export class ClickerReportComponent implements OnInit {
  clicker: any;
  subscription: Subscription = new Subscription();
  uClickerId: string;
  gameData: any = {};
  lstQuestionResult = [];
  constructor(private route: ActivatedRoute, private responseService: ResponseService, private gameService: GameService) {
    // this.firebaseService.getSnapshotChanges("currentGameResult").then(gameData => {
    //   if (gameData) {
    // this.gameData = gameData;
    this.subscription.add(
      this.route.parent.params.subscribe(params => {
        if (params && params.uClickerId) {
          this.uClickerId = params.uClickerId;
          this.gameData = { uGameId: params.uGameId, nvName: params.nvName }
        }
      })
    );
    this.subscription.add(
      this.route.params.subscribe(params => {
        if (params && params.uGameId) {
          this.gameData = { uGameId: params.uGameId, nvName: params.nvName }
          this.loadReport();
        }
      })
    );
    // }
    // });

  }

  ngOnInit() {
  }

  loadReport() {
    if (this.gameData.uGameId)
      this.responseService.GetResponseByClicker(this.gameData.uGameId, this.uClickerId).then(res => {
        if (res.length > 0) {
          this.clicker = res[0];
          this.clicker.nvPlayerName=this.clicker.nvPlayerName.indexOf('player')>-1?'אנונימי':this.clicker.nvPlayerName
          this.lstQuestionResult = res;
        }

      })
  }
}
