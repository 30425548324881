import { Component, OnInit, Input } from '@angular/core';
import { AlertType } from '../../Models/Alert';
import { FirestoreClickerOnGameGroup } from '../../Models/FirestoreClickerOnGame';
import { AlertService } from '../../Services/alert.service';
import { ClickerOnGameService } from '../../Services/clicker-on-game.service';
import { WaitIconService } from '../../Services/wait-icon.service';
import { SysTable } from '../../Services/SysTables.service';
import { FireAuthService } from '../../Services/fireauth.service';
import { FirestoreService } from '../../Services/firestore.service';

@Component({
  selector: 'mm-clicker-status',
  templateUrl: './clicker-status.component.html',
  styleUrls: ['./clicker-status.component.css']
})
export class ClickerStatusComponent implements OnInit {

  bSubmited = true;
  constantClickerStatus = SysTable.constantClickerStatus;

  @Input() iClickerStatus: number;
  @Input() uGameId: string;
  @Input() iGameStatusType: number;
  @Input() clickerOnGameGroups: Array<FirestoreClickerOnGameGroup>;

  constructor(
    private clickerOnGameService: ClickerOnGameService,
    private fireAuthService: FireAuthService,
    private firestoreService: FirestoreService,
    private waitIconService: WaitIconService,
    private alertService: AlertService) {

  }

  ngOnInit() { }

  async activeClicker() {

    if (this.iGameStatusType === SysTable.constantStatusTypeGame.done) {
      // this.alertService.addAlert('לא ניתן להפעיל שחקן במשחק שהסתיים', AlertType.danger);
      this.alertService.addAlert("clicker-status.alert.active-invalid-game-end", AlertType.warning);
      return;
    }

    if (this.iClickerStatus === SysTable.constantClickerStatus.play) {
      // this.alertService.addAlert("השחקן שלך כבר פעיל", AlertType.warning);
      this.alertService.addAlert("clicker-status.alert.player-already-active", AlertType.warning);
      return;
    }

    if (!this.clickerOnGameGroups) {
      return;
    }

    this.bSubmited = false;
    this.waitIconService.setWaitVisible(true);
    const activateRes = await this.clickerOnGameService.updateClickerStatus(
      this.fireAuthService.getUserFirebase().uid, this.firestoreService.getUserFirestore().iUserId,
      this.uGameId, this.clickerOnGameGroups, SysTable.constantClickerStatus.play);
    this.waitIconService.setWaitVisible(false);
    this.bSubmited = true;
    if (activateRes) {
      // this.alertService.addAlert('השחקן שלך הופעל בהצלחה', AlertType.success);
      this.alertService.addAlert('clicker-status.alert.active-player-success', AlertType.success);
    } else {
      // this.alertService.addAlert('אירעה שגיאה בהפעלת השחקן שלך', AlertType.danger);
      this.alertService.addAlert('clicker-status.alert.active-player-fail', AlertType.danger);
    }
  }

}
