import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FireAuthService } from '../../Services/fireauth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WaitIconService } from '../../Services/wait-icon.service';
import { FirestoreService } from '../../Services/firestore.service';
import { FirestoreUser } from '../../Models/FirestoreUser';
import { AlertService } from '../../Services/alert.service';
import { AlertType } from '../../Models/Alert';

@Component({
  selector: 'mm-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.css']
})
export class EditPasswordComponent implements OnInit {

  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  bSubmited: boolean = true;
  userFirestore: FirestoreUser;
  showPassword: boolean;
  bVerifyOldPassword = true;
  resetPasswordForm = new FormGroup({
    nvOldPassword: new FormControl('', [<any>Validators.required]),
    nvPassword: new FormControl('', [<any>Validators.required
      , Validators.minLength(6)
      , Validators.pattern('^[A-Za-z0-9]+$')]),
    nvConfirmPassword: new FormControl('', [<any>Validators.required])
  });

  get checkPassword() {
    return this.resetPasswordForm.get('nvPassword').value == this.resetPasswordForm.get('nvConfirmPassword').value;
  }

  constructor(
    private alertService: AlertService,
    private fireAuthService: FireAuthService,
    private firestoreService: FirestoreService,
    private waitIconService: WaitIconService) {

  }

  async ngOnInit() {
    this.resetPasswordForm.reset();
    this.userFirestore = this.firestoreService.getUserFirestore();
    const userProviders = await this.fireAuthService.getUserProviders(this.userFirestore.nvMailAddress);
    if (!userProviders) {
      this.closeEditPassword();
      return;
    }

    if (userProviders.filter(provider => provider === 'password').length === 0) {
      this.bVerifyOldPassword = false;
      this.resetPasswordForm.removeControl('nvOldPassword');
    }
  }

  async submitForm(form: FormGroup) {

    if (!form.valid || form.get('nvPassword').value != form.get('nvConfirmPassword').value) {
      // this.alertService.addAlert('כדי לשנות לסיסמה חדשה צריך למלא את כל השדות.', AlertType.danger);
      this.alertService.addAlert('edit-password.alert.all-field-required', AlertType.danger);
      return;
    } else if (this.bVerifyOldPassword && form.get('nvOldPassword').value === form.get('nvPassword').value) {
      // this.alertService.addAlert('הסיסמה החדשה צריכה להיות שונה מהסיסמה הישנה', AlertType.danger);
      this.alertService.addAlert('edit-password.alert.new-password-invalid', AlertType.danger);
      return;
    }

    this.bSubmited = false;
    this.waitIconService.setWaitVisible(true);
    
    let user;
    if (this.bVerifyOldPassword) {
      user = await this.fireAuthService.verifyUserPassword(form.get('nvOldPassword').value);
      if (!user) {
        this.waitIconService.setWaitVisible(false);
        this.bSubmited = true;
        return;
      }
    }
    // TODO: tammy - לבדוק שינוי סיסמה במשתמשים שרשומים רק עם חשבון גוגל
    let updateRes = await this.fireAuthService.updateUserPassword(user, form.get('nvPassword').value);
    if (!updateRes) {
      // this.alertService.addAlert('אירעה שגיאה במהלך שמירת הסיסמה', AlertType.danger);
      this.alertService.addAlert('edit-password.alert.update-password-fail', AlertType.danger);
      this.waitIconService.setWaitVisible(false);
      this.bSubmited = true;
    } else {
      updateRes = await this.firestoreService.updateUser(this.fireAuthService.getUserFirebase().uid, { nvPassword: form.get('nvPassword').value });
      // this.alertService.addAlert('עדכנו את הסיסמה החדשה שלך.', AlertType.success);
      this.alertService.addAlert('edit-password.alert.update-password-success', AlertType.success);
      this.waitIconService.setWaitVisible(false);
      this.bSubmited = true;
      this.closeEditPassword();
    }

  }

  closeEditPassword() {
    this.close.emit();
  }
}
