import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GameService } from '../../Services/game.service';
import { Subscription } from 'rxjs';
import { AppComponent } from '../app/app.component';
import { ClickerOnGameService } from '../../Services/clicker-on-game.service';
import { FirestoreService } from '../../Services/firestore.service';
import { FireAuthService } from '../../Services/fireauth.service';
import { SysTable } from '../../Services/SysTables.service';
import { ClickerOnGame } from '../../Models/ClickerOnGame';
import { WaitIconService } from '../../Services/wait-icon.service';
import { FacebookService } from '../../Services/facebook.service';
import { FirestoreClickerOnGame, FirestoreClickerOnGameGroup } from '../../Models/FirestoreClickerOnGame';
import { AlertService } from '../../Services/alert.service';
import { AlertType } from '../../Models/Alert';
import { GlobalService } from '../../Services/global.service';
import { SYSTEM } from 'src/assets/config/config';
import { FirebaseService } from 'src/app/Services/firebase.service';

@Component({
  selector: 'mm-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css']
})
export class GameComponent implements OnInit, OnDestroy {
  uGameId: string;
  subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private gameService: GameService,
    private globalService: GlobalService,
    private clickerOnGameService: ClickerOnGameService,
    private fireAuthService: FireAuthService,
    private firestoreService: FirestoreService,
    private facebookService: FacebookService,
    private firebaseService: FirebaseService,
    private waitIconService: WaitIconService) {
    this.subscription.add(
      this.route.params.subscribe(params => {
        this.uGameId = params.uGameId
        if (params.uGameId.toLowerCase() == SYSTEM.firebase_config.CONST_GAME.uGameId) {
          this.firebaseService.getSnapshotChanges("currentGameResult/uGameId").then(gameId => {
            if (gameId && gameId != params.uGameId) {
              this.uGameId = gameId;
              let url = location.hash.split('/');
              url = url.slice(1, url.length);
              url[0] = gameId;

              this.router.navigateByUrl(`${url[0]}/${decodeURIComponent(url[1])}`);
              // this.router.navigate(
              //   [], 
              //   {
              //     relativeTo: this.route,
              //     queryParams: { uGameId: gameId },
              //     queryParamsHandling: 'merge'
              //   });
            }
            else {
              this.loadGame(this.uGameId);
            }
          });

        } else {
          this.loadGame(this.uGameId);
        }
      })
    );
  }

  ngOnInit() {

  }

  async loadGame(uGameId: string) {
    this.waitIconService.setWaitVisible(true);
    const loadGameRes = await this.gameService.loadGameService(this.uGameId);
    if (!loadGameRes) {
      this.alertService.addAlert('משחק לא נמצא', AlertType.danger);
      this.router.navigate([this.firestoreService.getUserFirestore()['bTemporaryUser'] ? `login` : `gameNavigation`]);
    } else {
      this.facebookService.addTrack();
      await this.loadClickerOnGame(this.uGameId);
    }
    this.waitIconService.setWaitVisible(false);
  }

  async loadClickerOnGame(uGameId: string) {
    const loadCogRes = await this.firestoreService.setClickerOnGame(this.fireAuthService.getUserFirebase().uid, this.uGameId);
    if (!loadCogRes) {
      this.alertService.addAlert('אירעה שגיאה במהלך שליפת השחקן', AlertType.danger);
      this.router.navigate([`gameNavigation`]);
      return;
    }

    let bUpdateStatus = true;
    let cog = this.firestoreService.getClickerOnGameValue();
    if (cog && cog['bIsNew']) {// TODO: tammy - שליפת נתוני השחקן מהשרת במידה והם קיימים
      const clickerOnGameLst = await this.clickerOnGameService.getClickerOnGameServer(this.uGameId, this.firestoreService.getUserFirestore().uClickerId);
      if (!clickerOnGameLst) {
        this.alertService.addAlert('אירעה שגיאה במהלך שליפת השחקן', AlertType.danger);
        this.router.navigate([`gameNavigation`]);
        return;
      }
      // set on firestore
      if (clickerOnGameLst.length > 0) {
        const cogGroups = new Array<FirestoreClickerOnGameGroup>();
        clickerOnGameLst.forEach(c => {
          cogGroups.push(new FirestoreClickerOnGameGroup(c.uGroupId, c.uClickerOnGameId));
        });
        const clickerOnGame = new FirestoreClickerOnGame(clickerOnGameLst[0].iScore, clickerOnGameLst[0].iStatus, clickerOnGameLst[0].nvPlayerName, cogGroups);
        const addRes = await this.firestoreService.addOrUpdateClickerOnGame(this.fireAuthService.getUserFirebase().uid, this.uGameId, clickerOnGame);
        if (!addRes) {
          this.alertService.addAlert('אירעה שגיאה במהלך שליפת השחקן', AlertType.danger);
          this.router.navigate([`gameNavigation`]);
          return;
        }

        cog = clickerOnGame;
      } else {
        bUpdateStatus = false;
      }
    }

    if (bUpdateStatus) {
      const updateRes = await this.clickerOnGameService.updateClickerStatus(
        this.fireAuthService.getUserFirebase().uid, this.firestoreService.getUserFirestore().iUserId,
        uGameId, cog.clickerOnGameGroups, SysTable.constantClickerStatus.play);
      if (!updateRes) {
        this.alertService.addAlert(`שים לב! השחקן שלך מושהה מהמשחק יש ללחוץ על סמל ההשהיה בצד שמאל על מנת להפעיל אותו`, AlertType.danger);
      }
    }
  }

  onRouterOutletActivate(event) {
    AppComponent.bFooterIcn = true;
    this.globalService.setCurrentPageAsync(event.constructor.name);
    switch (event.constructor.name) {
      case 'ClickerComponent':
        AppComponent.bFooterIcn = false;
        break;
    }
  }

  async deleteTempraryUser(lstClickerOnGameId: Array<string>, uGameId: string) {
    this.fireAuthService.deleteLocalStorageUser();
    this.gameService.deleteLocalStorageGame();
    await this.clickerOnGameService.deleteClickerOnGame(this.fireAuthService.getUserFirebase().uid, this.firestoreService.getUserFirestore().iUserId, uGameId, lstClickerOnGameId);
  }

  async destroyComponent() {
    const game = this.gameService.getGameValue();
    const clickerOnGame = this.firestoreService.getClickerOnGameValue();
    if (game && clickerOnGame && clickerOnGame.clickerOnGameGroups.length > 0) {
      if (this.firestoreService.getUserFirestore()['bTemporaryUser']) {
        const isNewTemporaryUser = !clickerOnGame.nvPlayerName || clickerOnGame.nvPlayerName.trim() === '';
        if (isNewTemporaryUser) {
          const lstClickerOnGameId = clickerOnGame.clickerOnGameGroups.map(r => r.uClickerOnGameId);
          await this.deleteTempraryUser(lstClickerOnGameId, game.uGameId);
          return;
        }
      }
      if (clickerOnGame.iStatus == SysTable.constantClickerStatus.play && game.iStatusType != SysTable.constantStatusTypeGame.done) {
        await this.clickerOnGameService.updateClickerStatus(
          this.fireAuthService.getUserFirebase().uid, this.firestoreService.getUserFirestore().iUserId,
          game.uGameId, clickerOnGame.clickerOnGameGroups, SysTable.constantClickerStatus.gameDelayed);
      }
    }
  }

  async ngOnDestroy() {
    await this.destroyComponent();
    this.subscription.unsubscribe();
    this.gameService.deleteCurrentGame();
    this.firestoreService.deleteCurrentClickerOnGame();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {
    return !this.firestoreService.getUserFirestore()['bTemporaryUser']
  }

  @HostListener('window:unload', ['$event'])
  async unloadHandler(event) {
    await this.ngOnDestroy();
  }
}
