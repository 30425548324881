import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { WaitIconService } from '../../Services/wait-icon.service';

@Component({
  selector: 'mm-wait-icon',
  templateUrl: './wait-icon.component.html',
  styleUrls: ['./wait-icon.component.css']
})
export class WaitIconComponent implements OnInit, OnDestroy {

  bShowWaitIcon = false;
  subscription: Subscription = new Subscription();

  constructor(private waitIconService: WaitIconService) { }

  ngOnInit() {
    this.subscription.add(
      this.waitIconService.getWaitVisibleAsync()
        .subscribe((isVisible: boolean) => {
          this.bShowWaitIcon = isVisible;
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
