import { Component, OnInit, Input } from '@angular/core';
import { FireAuthService } from '../../Services/fireauth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'mm-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {

  bSeccess: boolean = false;
  oobCode: string;
  @Input('oobCode')
  set OobCode(oobCode: string) {
    this.oobCode = oobCode;
    if (oobCode) {
      this.applyEmail(oobCode);
    }
  }

  constructor(private fireAuthService: FireAuthService, private router: Router) { }

  ngOnInit() { }

  async applyEmail(oobCode: string) {
    const applyRes = await this.fireAuthService.applyEmail(oobCode);
    if (applyRes) {
      this.bSeccess = true;
      // this.nvMailAddress = applyRes;
    } else {
      this.router.navigate(['login']);
    }
  }

}
