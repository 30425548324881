import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, QueryFn } from '@angular/fire/firestore';
import { map, first, take } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import DocumentReference = firebase.firestore.DocumentReference;
import { ClickerResponse } from '../Models/Response';
import { BehaviorSubject } from 'rxjs';
import { FirestoreUser } from '../Models/FirestoreUser';
import { LogService } from './log.service';
import { FirestoreGame, FirestoreGameWelcomeTxt } from '../Models/FirestoreGame';
import { FirestoreClickerOnGame, FirestoreClickerOnGameGroup } from '../Models/FirestoreClickerOnGame';
import { SysTable } from './SysTables.service';
import { GameService } from './game.service';
import { AlertService } from './alert.service';
import { AlertType } from '../Models/Alert';
import { Global } from '../Models/Global';

//https://firestore.googleapis.com/v1/projects/milchemet-mochot/databases/(default)/documents/users/uid
// return this.firestore.collection<FirestoreGame>('users',ref=> ref.where('uid', '==', uid).limit(1)) // חיפוש
// https://firebase.google.com/docs/firestore/query-data/get-data#web_1
@Injectable()
export class FirestoreService {

    firestorUser: FirestoreUser;
    private $firestorUser = new BehaviorSubject<FirestoreUser>(null);

    constructor(
        private firestore: AngularFirestore,
        private alertService: AlertService,
        private gameService: GameService,
        private logService: LogService
    ) {

    }

    setErrorFirestoreMessage(error: firebase.firestore.FirestoreError, linkMsg = '') {
        let msg;// = 'אירעה שגיאה בלתי צפויה';
        let duration = linkMsg ? 20000 : 5000;
        switch (error.code) {
            case 'unavailable':
                if (error.message == 'Failed to get document because the client is offline.') {
                    msg = 'בעיות ברשת האינטרנט. וודא שיש לך חיבור יציב לרשת';
                }
                break;
            case 'permission-denied':
                msg = 'בעיות הרשאה';
                break;
            case 'not-found':
                break;
        }

        if (msg) {
            this.alertService.addAlert(msg + linkMsg, AlertType.danger, duration);
        }
    }

    /* return types
    true: exists
    false: doesn't exist
    null: error */
    isDocumentExist(documentRef: AngularFirestoreDocument, funcName: string): Promise<boolean> {
        return documentRef.get()
            .toPromise()
            .then((docSnapshot) => {
                return docSnapshot.exists;
            })
            .catch((error: firebase.firestore.FirestoreError) => {
                this.setErrorFirestoreMessage(error);
                console.error(`FirestoreService.isDocumentExist (${funcName}) error. message: ${error.message}`);
                this.logService.logError(`FirestoreService.isDocumentExist (${funcName}). path: ${documentRef.ref.path}, error code: ${error.code}`, error.message);
                return null;
            });
    }

    getDocumentSnapshotChanges(doc: AngularFirestoreDocument): Promise<any> {
        return doc
            .snapshotChanges()
            .pipe(
                take(1),
                map(data => data.payload.data())
            )
            .toPromise()
            .catch((error: firebase.firestore.FirestoreError) => {
                this.setErrorFirestoreMessage(error);
                console.error(`FirestoreService.getDocumentSnapshotChanges error. message: ${error.message}`);
                this.logService.logError(`FirestoreService.getDocumentSnapshotChanges. path: ${doc.ref.path}, error code: ${error.code} `, error.message);
                return null;
            });
    }

    getCollectionSnapshotChanges(collection: AngularFirestoreCollection): Promise<Array<FirestoreUser>> {
        return collection
            .snapshotChanges()
            .pipe(
                take(1),
                map((data: Array<any>) => {
                    return data.map(d => d.payload.doc.data());
                })
            )
            .toPromise()
            .catch((error: firebase.firestore.FirestoreError) => {
                this.setErrorFirestoreMessage(error);
                console.error(`FirestoreService.getCollectionSnapshotChanges error. message: ${error.message}`);
                this.logService.logError(`FirestoreService.getCollectionSnapshotChanges. collection: ${collection.ref}, error code: ${error.code} `, error.message);
                return null;
            });
    }

    getUsersCollection(): AngularFirestoreCollection<FirestoreUser> {
        return this.firestore.collection('users');
    }

    getUserDocumentRef(uid: string): AngularFirestoreDocument<FirestoreUser> {
        return this.getUsersCollection().doc<FirestoreUser>(uid);
    }

    getUserDocumentSnapshot(uid: string): Promise<FirestoreUser> {
        const docRef = this.getUserDocumentRef(uid);
        return this.getDocumentSnapshotChanges(docRef);
    }

    async addUser(uid: string, user: FirestoreUser): Promise<boolean> {
        console.log(`FirestoreService.addUser`);
        const userDocumentRef = this.getUserDocumentRef(uid);
        const isExist = await this.isDocumentExist(userDocumentRef, 'addUser');
        if (isExist) {
            return false;
        } else if (isExist === false) {
            return userDocumentRef
                .set((Object.assign({}, user)))
                .then(res => {
                    return true;
                })
                .catch((error: firebase.firestore.FirestoreError) => {
                    this.setErrorFirestoreMessage(error);
                    console.error(`FirestoreService.addUser error. message: ${error.message}`);
                    this.logService.logError(`FirestoreService.addUser. uid: ${uid}, user: ${JSON.stringify(user)}, error code: ${error.code}`, error.message);
                    return false;
                });
        } else {
            return Promise.resolve(false);
        }
    }

    updateUser(uid: string, userDetails: any, bSetUser = true): Promise<boolean> {
        console.log(`FirestoreService.updateUser`);
        return this.getUserDocumentRef(uid.trim())
            .update(userDetails)
            .then(res => {
                if (bSetUser) {
                    const user = this.getUserFirestore();
                    this.setUser(Object.assign(user, userDetails));
                }
                return true;
            })
            .catch((error: firebase.firestore.FirestoreError) => {
                this.setErrorFirestoreMessage(error);
                console.error(`FirestoreService.updateUser error. message: ${error.message}`);
                this.logService.logError(`FirestoreService.updateUser. uid: ${uid}, userDetails: ${JSON.stringify(userDetails)}, error code: ${error.code}`, error.message);
                return false;
            });
    }

    updateUserDetails(uid: string, bAcceptedRules: boolean, bMailingList: boolean, nvDisplayName: string = null): Promise<boolean> {

        // const userDetails = Global.getUpdateDetailsObj(); // TODO: tammy - להשתמש בפונקציה הזאת במקום השוואה של מאפיינים אחד אחד

        const fireUser = this.getUserFirestore();
        const userDetails = {};
        if (nvDisplayName && fireUser.nvDisplayName != nvDisplayName) {
            userDetails['nvDisplayName'] = nvDisplayName;
        }
        if (!fireUser.bAcceptedRules) {
            userDetails['bAcceptedRules'] = bAcceptedRules;
        }
        if (bMailingList && !fireUser.bMailingList) {
            userDetails['bMailingList'] = bMailingList;
        }
        if (userDetails.hasOwnProperty('nvDisplayName') ||
            userDetails.hasOwnProperty('bAcceptedRules') ||
            userDetails.hasOwnProperty('bMailingList')) {
            return this.updateUser(uid, userDetails);
        }
        return Promise.resolve(true);
    }

    /* return types
    true: success
    false: failed
    null: user document doesn't exist */
    async setUserFirestore(uid: string): Promise<boolean> {
        if (uid) {
            const userRef = this.getUserDocumentRef(uid);
            const isExist = await this.isDocumentExist(userRef, 'setUserFirestore');
            if (isExist) {
                const user = await this.getUserDocumentSnapshot(uid);
                if (!user) {
                    console.error(`FirestoreService.setUserFirestore. user document does not found`);
                    this.logService.logError(`FirestoreService.setUserFirestore -> getUserDocumentSnapshot. uid: ${uid}`, 'user document does not found');
                    return false;
                }
                console.log(`user logged in`);
                this.setLocalStorageUserId(user.iUserId);
                this.setUser(user);
            } else if (isExist === false) {
                //
                console.error(`FirestoreService.setUserFirestore. user document does not exist`);
                this.logService.logError(`FirestoreService.setUserFirestore -> getUserDocumentSnapshot. uid: ${uid}`, 'user document does not exist');
                return Promise.resolve(false);
            } else {
                return Promise.resolve(false);
            }
        } else {
            this.deleteLocalStorageUserId();
            this.setUser(null);
        }
        return true;
    }

    setUser(user: FirestoreUser) {
        if (user) {
            user['bTemporaryUser'] = this.isTemporaryUser(user.nvMailAddress);
        }
        this.$firestorUser.next(user);
    }

    isTemporaryUser(nvMailAddress: string): boolean {
        return nvMailAddress.toLocaleLowerCase().includes('@milchemetmochot.com');
    }

    setTemporaryDisplayName(nvDisplayName: string) {
        const user = this.getUserFirestore();
        if (user && user['bTemporaryUser']) {
            const tempUser = Object.assign({}, user);
            tempUser['temporaryDisplayName'] = nvDisplayName;
            this.setUser(tempUser);
        }
    }

    getUserFirestore(): FirestoreUser {
        return this.$firestorUser.getValue();
    }

    getUserFirestoreAsync(): BehaviorSubject<FirestoreUser> {
        return this.$firestorUser;
    }

    setLocalStorageUserId(iUserId: number) {
        localStorage.setItem('iUserId', iUserId ? iUserId.toString() : null);
    }

    deleteLocalStorageUserId() {
        localStorage.removeItem('iUserId');
    }

    //------------------------------------------

    getGamesCollection(): AngularFirestoreCollection<FirestoreGame> {
        return this.firestore.collection<FirestoreGame>('games');
    }

    getGameDocumentRef(uGameId: string): AngularFirestoreDocument<FirestoreGame> {
        return this.getGamesCollection().doc<FirestoreGame>(uGameId);
    }

    getGameDocumentSnapshot(uGameId: string): Promise<FirestoreGame> {
        const docRef = this.getGameDocumentRef(uGameId);
        return this.getDocumentSnapshotChanges(docRef);
    }

    async getGameWelcomeTxtDocumentSnapshot(uGameId: string): Promise<string> {
        // const docRef = this.getGamesCollection().doc<FirestoreGameWelcomeTxt>(uGameId + '/welcomeTxt');
        // return this.getDocumentSnapshotChanges(docRef);
        const game = await this.getGameDocumentSnapshot(uGameId);
        return game ? game.nvWelcomeTxt : null;
    }

    //------------------------------------------

    private $clickerOnGame = new BehaviorSubject<FirestoreClickerOnGame>(null);

    getClickerOnGameAsync(): BehaviorSubject<FirestoreClickerOnGame> {
        return this.$clickerOnGame;
    }

    getClickerOnGameValue(): FirestoreClickerOnGame {
        return this.$clickerOnGame.getValue();
    }

    setClickerOnGameAsync(clickerOnGameLst: FirestoreClickerOnGame) {
        return this.$clickerOnGame.next(clickerOnGameLst);
    }

    deleteCurrentClickerOnGame() {
        this.setClickerOnGameAsync(null);
    }

    isClickerOnGameExist() {
        const cog = this.getClickerOnGameValue();
        return cog ? true : false;
    }

    getClickerOnGameDocument(uid: string, uGameId: string): AngularFirestoreDocument<FirestoreClickerOnGame> {
        return this.getUserDocumentRef(uid).collection('games').doc<FirestoreClickerOnGame>(uGameId);
    }

    async addOrUpdateClickerOnGame(uid: string, uGameId: string, clickerOnGameDetails: any): Promise<boolean> {
        const clickerOnGameRef = this.getClickerOnGameDocument(uid, uGameId);
        const isExist = await this.isDocumentExist(clickerOnGameRef, 'addOrUpdateClickerOnGame');
        if (isExist) {
            return this.updateClickerOnGame(clickerOnGameRef, clickerOnGameDetails);
        } else if (isExist === false) {
            return this.addClickerOnGame(clickerOnGameRef, clickerOnGameDetails);
        } else {
            return Promise.resolve(false);
        }
    }

    addClickerOnGame(documentRef: AngularFirestoreDocument, clickerOnGameDetails: any): Promise<boolean> {
        console.log(`FirestoreService.addClickerOnGame`);
        const cogDetails = Object.assign({}, clickerOnGameDetails);
        if (cogDetails.clickerOnGameGroups) {
            cogDetails.clickerOnGameGroups = JSON.stringify(cogDetails.clickerOnGameGroups);
        }
        if (!cogDetails.iScore) {
            cogDetails.iScore = 0;
        }
        return documentRef
            .set(cogDetails)
            .then(res => {
                this.setClickerOnGameAsync(clickerOnGameDetails);
                return true;
            })
            .catch((error: firebase.firestore.FirestoreError) => {
                this.setErrorFirestoreMessage(error);
                console.error(`FirestoreService.addClickerOnGame error. message: ${error.message}`);
                this.logService.logError(`FirestoreService.addClickerOnGame. path: ${documentRef.ref.path}, clickerOnGameDetails: ${JSON.stringify(clickerOnGameDetails)}, error code: ${error.code}`, error.message);
                return false;
            });
    }

    updateClickerOnGame(documentRef: AngularFirestoreDocument, clickerOnGameDetails: any): Promise<boolean> {
        console.log(`FirestoreService.updateClickerOnGame`);
        const cogDetails = Global.getUpdateDetailsObj(this.getClickerOnGameValue(), clickerOnGameDetails);
        if (!cogDetails) {
            return Promise.resolve(true);
        }
        if (cogDetails.clickerOnGameGroups) {
            cogDetails.clickerOnGameGroups = JSON.stringify(cogDetails.clickerOnGameGroups);
        }
        return documentRef
            .update(cogDetails)
            .then(res => {
                let cog = this.getClickerOnGameValue();
                cog = Object.assign((cog ? cog : {}), clickerOnGameDetails);
                this.setClickerOnGameAsync(cog);
                return true;
            })
            .catch((error: firebase.firestore.FirestoreError) => {
                this.setErrorFirestoreMessage(error);
                console.error(`FirestoreService.updateClickerOnGame error. message: ${error.message}`);
                this.logService.logError(`FirestoreService.updateClickerOnGame. path: ${documentRef.ref.path}, clickerOnGameDetails: ${JSON.stringify(clickerOnGameDetails)}, error code: ${error.code}`, error.message);
                return false;
            });
    }

    /* return types
    firestoreClickerOnGame: success
    firestoreClickerOnGame with bIsNew = true: new object
    null: error (isDocumentExist | getDocumentSnapshotChanges) */
    async getClickerOnGameSnapshot(uid: string, uGameId: string): Promise<FirestoreClickerOnGame> {
        console.log(`FirestoreService.getClickerOnGameSnapshot`);
        const clickerOnGameRef = this.getClickerOnGameDocument(uid, uGameId);
        const exist = await this.isDocumentExist(clickerOnGameRef, 'getClickerOnGameSnapshot');
        if (exist) {
            const clickerOnGame = await this.getDocumentSnapshotChanges(clickerOnGameRef);
            if (!clickerOnGame) {
                console.error(`FirestoreService.getClickerOnGameSnapshot. clickerOnGame document does not found`);
                this.logService.logError(`FirestoreService.getClickerOnGameSnapshot -> getDocumentSnapshotChanges. path: ${clickerOnGameRef.ref.path}`, 'clickerOnGame document does not found');
                return null;
            }

            if (clickerOnGame.clickerOnGameGroups) {
                clickerOnGame.clickerOnGameGroups = JSON.parse(clickerOnGame.clickerOnGameGroups);
            } else {
                clickerOnGame.clickerOnGameGroups = new Array<FirestoreClickerOnGameGroup>();
            }
            return clickerOnGame;
        } else if (exist === false) {
            const newClickerOnGame = new FirestoreClickerOnGame(0, SysTable.constantClickerStatus.play, null);
            newClickerOnGame['bIsNew'] = true; // TODO: tammy - זמני כל עוד יש שליפה של האובייקט מהשרת
            return newClickerOnGame;
        } else {
            return null;
        }
    }

    async deleteClickerOnGame(uid: string, uGameId: string): Promise<boolean> {
        console.log(`FirestoreService.deleteClickerOnGame`);
        const clickerOnGameRef = this.getClickerOnGameDocument(uid, uGameId);
        const exist = await this.isDocumentExist(clickerOnGameRef, 'deleteClickerOnGame');
        if (exist) {
            return clickerOnGameRef
                .delete()
                .then(res => {
                    return true;
                })
                .catch((error: firebase.firestore.FirestoreError) => {
                    this.setErrorFirestoreMessage(error);
                    console.error(`FirestoreService.deleteClickerOnGame error. message: ${error.message}`);
                    this.logService.logError(`FirestoreService.deleteClickerOnGame. path: ${clickerOnGameRef.ref.path}, error code: ${error.code}`, error.message);
                    return false;
                });
        } else if (exist === false) {
            return true;
        } else {
            return false;
        }
    }

    async setClickerOnGame(uid: string, uGameId: string): Promise<boolean> {
        if (!uid || !uGameId) {
            return Promise.resolve(false);
        }
        const clickerOnGame = await this.getClickerOnGameSnapshot(uid, uGameId);
        if (!clickerOnGame) {
            this.setClickerOnGameAsync(null);
            return Promise.resolve(false);
        } else {
            this.setClickerOnGameAsync(clickerOnGame);
            return Promise.resolve(true);
        }
    }

    //------------------------------------------



    ////##Region RESPONSE
    // להוסיף uClickerId בשביל שיהיה נח לשלוף??

    gameData: AngularFirestoreDocument;
    getTableDocument(uGameId: string) {
        if (!this.gameData) {
            this.gameData = this.firestore
                .collection('tables')
                .doc(uGameId);
        }
        return this.gameData;
    }

    getResponseCollection(uGameId: string, uQuestion: string, queryFn?: QueryFn) {
        return this.getTableDocument(uGameId)
            .collection<ClickerResponse>('TResponse-' + uQuestion, queryFn);
    }

    addResponse(uGameId: string, uQuestion: string, clickerResponse: ClickerResponse): Promise<DocumentReference> {
        return this.getResponseCollection(uGameId, uQuestion)
            .add(Object.assign({}, clickerResponse)); // להוסיף גם עם guid id = uResponseId
    }

    addResponseLst(uGameId: string, uQuestion: string, clickerResponseLst: Array<ClickerResponse>): Promise<DocumentReference> {
        console.log(`FirestoreService.addResponseLst`);

        const promiseLst = [];
        clickerResponseLst.forEach(clickerResponse => {
            promiseLst.push(this.addResponse(uGameId, uQuestion, clickerResponse));
        });

        return Promise.all<DocumentReference>(promiseLst)
            .then((res: Array<any>) => {
                return res;
            })
            .catch((error: firebase.firestore.FirestoreError) => {
                this.setErrorFirestoreMessage(error);
                console.error(`FirestoreService.addResponseLst error. message: ${error.message}`);
                this.logService.logError(`FirestoreService.addResponseLst. uGameId: ${uGameId}, uQuestion: ${uQuestion}, clickerResponseLst: ${JSON.stringify(clickerResponseLst)}, error code: ${error.code}`, error.message);
                return null;
            });
    }

    deleteResponse(uGameId: string, uQuestion: string, uResponse: string): Promise<void> {
        return this.getResponseCollection(uGameId, uQuestion)
            .doc(uResponse)
            .delete();
    }

    updateResponse(uGameId: string, uQuestion: string, uResponse: string, newData: any): Promise<void> {
        return this.getResponseCollection(uGameId, uQuestion)
            .doc(uResponse)
            .update(newData);
    }

    // getResponseByQueryId(uGameId: string, uQuestionId: string, clickerOnGameLst: Array<string>): Promise<Array<ClickerResponse>> {
    //     const queryResponse =
    //         this.getResponseCollection(uGameId, (ref => ref
    //             .where('uClickerOnGameId', 'in', clickerOnGameLst)
    //             .where('uQuestionId', '==', uQuestionId)));
    //     return this.getFirestoreSnapshotChanges(queryResponse);
    // }

    getFirestoreSnapshotChanges(collection: AngularFirestoreCollection): Promise<any> {
        return collection.snapshotChanges()
            .pipe(
                map(data => data.map((d: any) => d.payload.doc.data())),
                first()
            )
            .toPromise();
    }

    // addUserPhone() {
    //     let userPhone = { iUserId: 1234567, nvPlayerName: "שם משתמש חדש",nvPhoneNumber:"0548477777" ,bBroadcast:true}
    //     this.firestore.collection('usersPhone').doc<any>(userPhone.iUserId.toString()).set(userPhone, { merge: true });
    // }
    ////##endregion
}
