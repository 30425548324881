import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertType } from 'src/app/Models/Alert';
import { ChatMessage } from 'src/app/Models/ChatMessage';
import { AlertService } from 'src/app/Services/alert.service';
import { ChatMessageService } from 'src/app/Services/chat-message.service';
import { FireAuthService } from 'src/app/Services/fireauth.service';
import { FirestoreService } from 'src/app/Services/firestore.service';
import { GameService } from 'src/app/Services/game.service';
import { MESSAGE_TYPE } from '../chat-support/chat-support.component';
import { FirestoreClickerOnGame } from 'src/app/Models/FirestoreClickerOnGame';

@Component({
  selector: 'mm-broadcast-message',
  templateUrl: './broadcast-message.component.html',
  styleUrls: ['./broadcast-message.component.css']
})
export class BroadcastMessageComponent implements OnInit {
  uGameId: string;
  message: ChatMessage = new ChatMessage();
  subscription: Subscription = new Subscription();
  firebaseUser: firebase.User;

  @Output() closeMe = new EventEmitter<number>();
  constructor(private fireAuthService: FireAuthService
    , private chatMessageService: ChatMessageService
    , private gameService: GameService
    , private alertService: AlertService
    , private firestoreService: FirestoreService) {
    this.subscription.add(
      this.fireAuthService.getUserFirebaseAsync()
        .subscribe((firebaseUser: firebase.User) => {
          this.firebaseUser = firebaseUser;
          this.setUserMessage();
          console.log(this.firebaseUser);
        })
    );
    this.uGameId = this.gameService.getGameValue().uGameId;
    
  }

  ngOnInit() {
  }

  setUserMessage() {

    if (this.firebaseUser && this.message) {
      let nvClickerName = this.firestoreService.getClickerOnGameValue().nvPlayerName;
      this.message.uid = this.firebaseUser.uid;
      this.message.nvMailAdress = this.firebaseUser.email.includes("@milchemetmochot.com") ? '' : this.firebaseUser.email;
      // this.message.nvDisplayName = this.firebaseUser.displayName;
      this.message.nvDisplayName =nvClickerName ? nvClickerName : this.firestoreService.getUserFirestore().nvDisplayName;
      this.message.nvPage = "fast access";
    }
  }

  closeMessage() {
    this.closeMe.emit();
  }

  addMessageToArray() {
    if (this.message.nvMessage) {
      this.message.nvColor = "yellow";
      this.chatMessageService.addMessageGameCollection(MESSAGE_TYPE.broadcastMessage.fireStoreUid, this.uGameId, this.message);
      this.alertService.addAlert("ההודעה נשלחה בהצלחה", AlertType.success);
      this.closeMessage();
    } else {
      this.alertService.addAlert("יש למלא הודעה לשליחה", AlertType.danger);
      this.closeMessage();
    }

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
