import { Component, OnInit, OnDestroy } from '@angular/core';
import { GameService } from '../../Services/game.service';
import { Game } from '../../Models/Game';
import { ClickerOnGameService } from '../../Services/clicker-on-game.service';
import { ClickerOnGame } from '../../Models/ClickerOnGame';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { SysTable } from '../../Services/SysTables.service';
import { FirestoreService } from '../../Services/firestore.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WaitIconService } from '../../Services/wait-icon.service';
import { FirestoreClickerOnGame, FirestoreClickerOnGameGroup } from '../../Models/FirestoreClickerOnGame';
import { FireAuthService } from '../../Services/fireauth.service';
import { AlertService } from '../../Services/alert.service';
import { AlertType } from '../../Models/Alert';
import { URL_GOOGLE_GROUP } from 'src/app/Models/Constants';

@Component({
  selector: 'mm-register-game',
  templateUrl: './register-game.component.html',
  styleUrls: ['./register-game.component.css']
})
export class RegisterGameComponent implements OnInit, OnDestroy {

  registerGameForm = new FormGroup({
    nvPlayerName: new FormControl('', [
      <any>Validators.required,
      Validators.maxLength(35),
      Validators.pattern(/^([0-9\u05D0-\u05EA\s]|\uD83D[\uDC00-\uDFFF]|[\u2694-\u2697]|\uD83E[\uDD10-\uDD5D])*$/) // /^([\u05D0-\u05EA\s])*$/
    ])
    
  });
  subscription: Subscription = new Subscription();
  timeOutIds = new Array<any>();
  initGroupId = null;
 
  randomEmail: boolean = false;
  bAcceptedRules: boolean = false;
  

  bReregister: boolean = false;
  bMaxUsersValid: boolean = true;
  bSubmited: boolean = true;
  game: Game;
  clickerOnGame: FirestoreClickerOnGame;

  get staticGameStatus() {
    return SysTable.constantStatusTypeGame;
  }

  get getGoogleGroupUrl(){
    return URL_GOOGLE_GROUP;
  }
  
  constructor(
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private clickerOnGameService: ClickerOnGameService,
    private firestoreService: FirestoreService,
    private fireAuthService: FireAuthService,
    private gameService: GameService,
    private waitIconService: WaitIconService,
    private router: Router) {

    this.subscription.add(
      this.activatedRoute.queryParams.subscribe(params => {
        if (params && params.bReregister) {
          this.bReregister = params.bReregister;
        }
        if (params && params.uGroupId) {
          this.initGroupId = params.uGroupId;
        }
        if (params && params.email) {
          this.randomEmail = params.email == 'random';
        }
      })
    );

  }

  ngOnInit() {
    this.subscription.add(
      this.gameService.getGameAsync()
        .subscribe(async (game: Game) => {
          if (game) {
            this.game = Object.assign({}, game);
            this.initClickerOnGame();
          } else {
            this.game = null;
            this.clearData();
          }
        })
    );
  }

  async initClickerOnGame() {
    this.subscription.add(
      this.firestoreService.getClickerOnGameAsync()
        .subscribe(async (clickerOnGame: FirestoreClickerOnGame) => {
          if (!clickerOnGame) {
            this.clearData();
          } else {
            if (clickerOnGame.clickerOnGameGroups.length === this.game.lstDivision.length) { // אם השחקן רשום לכל החלוקות
              if (!this.bReregister) {
                this.router.navigate([`${this.game.uGameId}/clicker`]);
                return;
              }
            } else if (clickerOnGame.clickerOnGameGroups.length === 0) { // שחקן חדש
              if (this.game.iStatusType !== this.staticGameStatus.done) {
                this.waitIconService.setWaitVisible(true);
                const valid = await this.gameService.checkMaxUserGame(this.game.uGameId);
                this.waitIconService.setWaitVisible(false);
                if (!valid) {
                  this.setMaxUserInvalid();
                } else {
                  this.bMaxUsersValid = valid;
                }
              }
            }

            this.setClickerOnGame(clickerOnGame);

            this.game['lstShowDivision'] = this.game.lstDivision.filter(d => !d["bHideDivision"]);
          }
        })
    )
  }

  setClickerOnGame(clickerOnGame: FirestoreClickerOnGame) {
    this.clickerOnGame = new FirestoreClickerOnGame(clickerOnGame.iScore, clickerOnGame.iStatus, clickerOnGame.nvPlayerName);
    this.game.lstDivision.forEach(division => {

      let tempCog: FirestoreClickerOnGameGroup;
      const cog = clickerOnGame.clickerOnGameGroups.find(c => division.lstGroups.find(g => g.uGroupId == c.uGroupId));
      if (cog) {
        tempCog = Object.assign({}, cog);
      } else {
        tempCog = new FirestoreClickerOnGameGroup();
      }
      if (this.initGroupId) {
        const group = division.lstGroups.find(g => g.uGroupId == this.initGroupId);
        if (group) {
          division["bHideDivision"] = true;
          if (!tempCog.uGroupId || (this.randomEmail && this.initGroupId)) {
            tempCog.uGroupId = this.initGroupId;
          }
        }
      }
      // if (this.randomEmail && !this.initGroupId){
      //   tempCog.uGroupId = null;
      // }
      division['clickerOnGameGroup'] = tempCog;
      this.clickerOnGame.clickerOnGameGroups.push(tempCog);
    });

    // אם השחקן רשום רק לחלק מהחלוקות (שחקן זמני)
    const countWithNoGroup = this.clickerOnGame.clickerOnGameGroups.filter(cog => !cog.uGroupId).length;
    if ((countWithNoGroup > 0 && countWithNoGroup !== this.clickerOnGame.clickerOnGameGroups.length) || (this.randomEmail && !this.initGroupId)) {
      this.clickerOnGame.clickerOnGameGroups.forEach(cog => cog.uGroupId = null);
    }

    this.registerGameForm.get('nvPlayerName').setValue(this.clickerOnGame.nvPlayerName);
  }

  clearData() {
    this.clickerOnGame = null;
  }

  async registerToGame(valueForm: FormGroup) {
    this.bSubmited = false;
    if (!valueForm.valid || !valueForm.value.nvPlayerName.trim()) {
      // this.alertService.addAlert('יש להזין שם תקין לתצוגה - שם התצוגה חייב להיות באותיות עבריות בלבד ועד 35 תויים.', AlertType.danger);
      this.alertService.addAlert('register-game.alert.name-invalid-length', AlertType.danger);
      this.bSubmited = true;
      return;
    } else if (this.randomEmail && !this.bAcceptedRules) {
      // this.alertService.addAlert('יש לאשר את תנאי האתר.', AlertType.danger);
      this.alertService.addAlert('register-game.alert.acceptedRules-invalid', AlertType.danger);
      this.bSubmited = true;
      return;
    }
    //  if (!this.randomEmail && this.nvLicenseText && !this.bAcceptedSendMail ) {
    //   // this.alertService.addAlert('יש לאשר את תנאי האתר.', AlertType.danger);
    //   this.alertService.addAlert('שכחת לאשר את העברת המייל', AlertType.danger);
    //   this.bSubmited = true;
    //   return;
    // }

    let save = true;
    this.clickerOnGame.nvPlayerName = valueForm.get('nvPlayerName').value.trim();
    let cog;
    this.game.lstDivision.forEach(division => {
      if (!division['clickerOnGameGroup'].uGroupId) {
        save = false;
      }
      cog = this.clickerOnGame.clickerOnGameGroups.find(cog => cog.uClickerOnGameId == division['clickerOnGameGroup'].uClickerOnGameId);
      if (cog) {
        cog.uGroupId = division['clickerOnGameGroup'].uGroupId;
      }
    });

    if (!save) {
      // this.alertService.addAlert('אצלינו אף פעם לא משחקים לבד. צריך לסמן קבוצה נבחרת בכל חלוקה. ', AlertType.danger);
      this.alertService.addAlert('register-game.alert.group-invalid', AlertType.danger);
      this.bSubmited = true;
      return;
    }

    const oldClickerOnGame = this.firestoreService.getClickerOnGameValue();
    const isEquals = this.isClickerOnGameEquals(this.clickerOnGame, oldClickerOnGame);
    if (isEquals) {
      this.router.navigate([`${this.game.uGameId}/clicker`]);
    } else {
      if (this.game.iStatusType == this.staticGameStatus.done ||
        (this.game.iStatusType == this.staticGameStatus.run &&
          //שחקנים כבר רשומים
          oldClickerOnGame.nvPlayerName && oldClickerOnGame.nvPlayerName.trim() !== '' &&
          oldClickerOnGame.clickerOnGameGroups.length === this.game.lstDivision.length
        )) {
        // this.alertService.addAlert((this.game.iStatusType == this.staticGameStatus.done ?
        //    `לא ניתן להצטרף או לערוך פרטים למשחק שהסתיים` :
        //    `לא ניתן לערוך שם שחקן או לשנות קבוצות אחרי שהמשחק התחיל`
        //   ), AlertType.danger);
        this.alertService.addAlert((this.game.iStatusType == this.staticGameStatus.done ?
          `register-game.alert.game-over` :
          `register-game.alert.game-started`
        ), AlertType.danger);
        this.setClickerOnGame(this.firestoreService.getClickerOnGameValue());
        this.bSubmited = true;
        return;
      }

      this.waitIconService.setWaitVisible(true);
      const addClickerOnGameRes = await this.clickerOnGameService.addOrUpdateClickerOnGame(
        this.fireAuthService.getUserFirebase().uid, this.firestoreService.getUserFirestore().iUserId,
        this.firestoreService.getUserFirestore().uClickerId, this.game.uGameId, this.clickerOnGame);
      this.waitIconService.setWaitVisible(false);
      if (addClickerOnGameRes == 1) {
        // this.alertService.addAlert('יופי, נכנסת! עכשיו אפשר לשחק.', AlertType.success);
        this.alertService.addAlert('register-game.alert.register-success', AlertType.success);
        this.router.navigate([`${this.game.uGameId}/clicker`]);
      } else if (addClickerOnGameRes === 2) {
        // this.alertService.addAlert('שם תפוס. אנה החלף שם לתצוגה במשחק', AlertType.danger);
        this.alertService.addAlert('register-game.alert.name-invalid-busy', AlertType.danger);
      } else if (addClickerOnGameRes === 3) {
        // this.alertService.addAlert('המשחק בתפוסה מלאה. נסו שוב בעוד מספר דקות', AlertType.danger);
        this.alertService.addAlert('register-game.alert.game-fully', AlertType.danger);
        this.setMaxUserInvalid();
      } else {
        // this.alertService.addAlert('הרישום למשחק נכשל', AlertType.danger);
        this.alertService.addAlert('register-game.alert.register-faild', AlertType.danger);
      }
    }

    this.bSubmited = true;
  }

  setMaxUserInvalid() {
    // TODO: tammy - להוסיף טיימר לשחקן בתצוגה
    this.bMaxUsersValid = false;
    this.timeOutIds.push(
      setTimeout(() => this.bMaxUsersValid = true, (3 * 60 * 1000)) // 3 דקות
    );
  }

  isClickerOnGameEquals(clickerOnGame1: FirestoreClickerOnGame, clickerOnGame2: FirestoreClickerOnGame): boolean {

    if ((clickerOnGame1.nvPlayerName && !clickerOnGame2.nvPlayerName) ||
      (clickerOnGame1.nvPlayerName.trim() !== clickerOnGame2.nvPlayerName.trim()) ||
      clickerOnGame1.clickerOnGameGroups.length !== clickerOnGame2.clickerOnGameGroups.length) {
      return false;
    }

    const cog1 = clickerOnGame1.clickerOnGameGroups.sort((value1: ClickerOnGame, value2: ClickerOnGame) =>
      (value1.uClickerOnGameId > value2.uClickerOnGameId ? -1 : 1)
    );
    const cog2 = clickerOnGame2.clickerOnGameGroups.sort((value1: ClickerOnGame, value2: ClickerOnGame) =>
      (value1.uClickerOnGameId > value2.uClickerOnGameId ? -1 : 1)
    );
    for (let i = 0; i < cog1.length; i++) {
      if (cog1[i].uGroupId !== cog2[i].uGroupId) {
        return false;
      }
    }

    return true;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.timeOutIds.forEach(id => clearTimeout(id));
  }

}
