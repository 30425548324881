import { Component, Input, OnInit } from '@angular/core';
import { GameService } from '../../Services/game.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WaitIconService } from '../../Services/wait-icon.service';
import { UserService } from '../../Services/user.service';
import { AlertService } from '../../Services/alert.service';
import { AlertType } from '../../Models/Alert';
import { SYSTEM } from 'src/assets/config/config';
import { FirebaseService } from 'src/app/Services/firebase.service';

@Component({
  selector: 'mm-game-navigation',
  templateUrl: './game-navigation.component.html',
  styleUrls: ['./game-navigation.component.css']
})
export class GameNavigationComponent implements OnInit {

  gameNavigationForm = new FormGroup({
    nvSecretCode: new FormControl('', [<any>Validators.required, Validators.pattern('^\\d+$')])
  });
  failMessage: string = '';
  bSubmited: boolean = true;
  uClickerId: string = null;
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private gameService: GameService,
    private firebaseService: FirebaseService,
    private router: Router,
    private waitIconService: WaitIconService) {
    this.route.parent.params.subscribe(params => {
      //  console.log(params);
      this.uClickerId = params.uClickerId;
    })

  }

  ngOnInit() {
    // this.userService.addUsersToFirestore();
  }

  async enterToGame(valueForm: FormGroup) {
    this.bSubmited = false;

    if (!valueForm.valid) {
      this.bSubmited = true;
      return;
    }
    let nvSecretCode=valueForm.get('nvSecretCode').value;
    if (nvSecretCode == SYSTEM.firebase_config.CONST_GAME.nvSecretCode) {
      let fireSecretCode = await this.firebaseService.getSnapshotChanges("currentGameResult/nvSecretCode");
      if (fireSecretCode && fireSecretCode!=nvSecretCode)
      {
        nvSecretCode=fireSecretCode;
      }
    }
    this.waitIconService.setWaitVisible(true);
    const game = await this.gameService.getGameBySecretCode(nvSecretCode);
    this.waitIconService.setWaitVisible(false);

    if (game) {
      const res = await this.gameService.loadGameService(null, game);
      if (!this.uClickerId) {
        this.router.navigate([this.gameService.getGameValue().uGameId + '/register']);
      } else {
        this.router.navigate(['result/' + game.uGameId + '/' + game.nvName], { relativeTo: this.route.parent });
      }
    } else {
      // this.alertService.addAlert('קוד לא נכון. קוד משחק מכיל כ- 7 ספרות או יותר.', AlertType.danger);
      this.alertService.addAlert('game-navigation.alert.game-code-invalid', AlertType.danger);
    }

    this.bSubmited = true;
  }
}
