export class FireBaseData {
    public uOwnerId: string;
    public isOnAir: boolean;
    public bLiveBroadcast: boolean;
    public currentUrl: string;
    public gameBroadcast: GameBroadcast;

    // question
    public uQuestionId: string;
    public currentQuestion: FireBaseQuestion;
    public iVotingStatus: number;
    public bShowResults: boolean;
    public bEndCollectAndCalcScores: boolean;
    public iCurrentTimer: number;
    public bPlayTimer: boolean;
    public iMaxAnswer: number;
    public iIndexResponse: number;
    public header: FireBaseHeader;
    // public uVotingCode: string;
}

export const HeaderType = { header: 1, tops_in_game: 2, fasters_in_question: 3, active_clickers: 4, game_by_amount: 5 };
export class FireBaseHeader {
    public headerType: number;
    public content: any;
}

export class FireBaseQuestion {
    uQuestionId: string;
    nvText: string;
    iType: number;
    iStatusType: number;
    lstAnswers: Array<FireBaseAnswer>;
}

export class FireBaseAnswer {
    public uAnswerId: string;
    public nvText: string;
    public bCorrectAnswer: boolean;
    public cClickerSign: string;
}

export class GameBroadcast {
    public iBroadcastType: number;
    public nvVideoType: string;
    public nvBroadcastUrl: string;

    constructor(iBroadcastType: number, nvBroadcastUrl: string, nvVideoType: string = 'mp4') {
        this.iBroadcastType = iBroadcastType;
        this.nvVideoType = nvVideoType;
        this.nvBroadcastUrl = nvBroadcastUrl;
    }
}

export const GameBroadcastTypesObj = { broadcast: 0, iframe: 1, video: 2, drive: 3 };
export const GameBroadcastTypes = [
    { name: 'שידור ישיר', type: 'broadcast', id: 0 },
    { name: 'אתר חיצוני', type: 'iframe', id: 1 },
    { name: 'וידאו', type: 'video', id: 2 },
    { name: 'קובץ דרייב', type: 'drive', id: 3 }];
