import { Injectable } from '@angular/core';
import { FirestoreUser } from '../Models/FirestoreUser';
import { User } from '../Models/User';
import { AppProxy } from './app.proxy';
import { FirebaseService } from './firebase.service';
import { FirestoreService } from './firestore.service';
import { LogService } from './log.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    playerUserId = 1;

    constructor(
        private appProxy: AppProxy,
        private firestoreService: FirestoreService,
        private firebaseService: FirebaseService,
        private logService: LogService) { }

    async registerUserWeb(user: User): Promise<User | boolean> {
        console.log(`UserService.registerUserWeb`);
        // const bServerAvailable = await this.firebaseService.getServiceAble('UserService.registerUserWeb');
        // if (bServerAvailable) {
        user.nvMailAddress = user.nvMailAddress ? user.nvMailAddress.toLocaleLowerCase() : user.nvMailAddress;
        return this.appProxy
            .post('RegisterUserWeb', { iUserId: this.playerUserId, user: user })
            .then(res => {
                if (!res || res.iUserId === 0) {
                    throw new Error(`server error. response: ${JSON.stringify(res)}`);
                } else {
                    return res;
                }
            })
            .catch((error: any) => {
                console.error(`UserService.registerUserWeb error. message: ${error.message}`);
                this.logService.logError(`UserService.registerUserWeb -> RegisterUserWeb error. user: ${JSON.stringify(user)}`, error.message);
                return null;
            });
        // } else {
        //     return Promise.resolve(false);
        // }
    }

    async loginWithRandomUser(uGameId: string): Promise<any> {
        console.log(`UserService.loginWithRandomUser`);
        const bServerAvailable = await this.firebaseService.getServiceAble('UserService.loginWithRandomUser');
        if (bServerAvailable) {
            return this.appProxy
                .post('LoginWithRandomUser', { uGameId: uGameId })
                .then(res => {
                    if (!res || res.key == 0) {
                        throw new Error(`server error. response: ${JSON.stringify(res)}`);
                    } else if (res.key == 1) {
                        return res.value;
                    } else {
                        return res.key;
                    }
                })
                .catch((error: any) => {
                    console.error(`UserService.loginWithRandomUser error. message: ${error.message}`);
                    this.logService.logError(`UserService.loginWithRandomUser -> LoginWithRandomUser error. uGameId: ${uGameId}`, error.message);
                    return 0;
                });
        } else {
            return Promise.resolve(3);
        }
    }





    //זמני כדי להכניס משתמשים לפיירסטור שלא נרשמו טוב

    async addUsersToFirestore() {
        const firebaseIds = [];

        const users = [];
        return
        debugger
        let help, index, user, userRef;
        for (let i = 0; i < users.length; i++) {
            user = await this.getUserWeb(users[i])
            const firestoreUser = new FirestoreUser(
                user.uClickerId,
                user.iUserId,
                user.nvDisplayName,
                user.nvMailAddress,
                user.nvPassword,
                user.bAcceptedRules,
                user.bMailingList);
            if (user.uFirebaseId) {

                index = firebaseIds.indexOf(user.uFirebaseId);
                if (index > -1) {
                    firebaseIds.splice(index, 1);
                }

                userRef = this.firestoreService.getUserDocumentRef(user.uFirebaseId);
                help = await this.firestoreService.isDocumentExist(userRef, 'addUsersToFirestore');
                if (!help) {
                    help = await this.firestoreService.addUser(user.uFirebaseId, firestoreUser);
                    if (help) {
                        console.log(`add to firestore uid: ${user.uFirebaseId}, email: ${user.nvMailAddress}`);
                    } else {
                        console.error(`add to firestore uid: ${user.uFirebaseId}, email: ${user.nvMailAddress}`);
                    }
                }
            } else {
                console.log(`missimg uid, email: ${user.nvMailAddress}`);
            }
        }
        debugger
        alert(firebaseIds.length);
        debugger
    }

    getUserWeb(iUserId: number): Promise<User> {
        if (!iUserId || iUserId == 0) {
            return null;
        }
        console.log(`UserService.getUserWeb`);
        return this.appProxy
            .post('GetUsers', { iUserId: iUserId })
            .then(res => {
                if (!res || res.length == 0 || res[0].iUserId === 0) {
                    throw new Error(`server error. response: ${JSON.stringify(res)}`);
                } else {
                    return res[0];
                }
            })
            .catch((error: any) => {
                console.error(`UserService.getUserWeb error. message: ${error.message}`);
                this.logService.logError(`UserService.getUserWeb -> ? error. iUserId: ${iUserId}`, error.message);
                return null;
            });
    }
}
