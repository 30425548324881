import { Injectable } from '@angular/core';
declare let fbq: Function;

@Injectable()
export class FacebookService {

    constructor() {
    }

    addTrack() {
        this.addPageView();
        this.addLead();
    }

    addPageView() {
        fbq('track', 'PageView');
    }

    addViewContent() {
        fbq('track', 'ViewContent');
    }

    addLead() {
        fbq('track', 'Lead');
    }

}
