import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, Params } from '@angular/router';
import { FireAuthService } from './fireauth.service';
import { LoginService } from './login.service';
import { WaitIconService } from './wait-icon.service';
import { FirestoreService } from './firestore.service';
import { AlertService } from './alert.service';
import { AlertType } from '../Models/Alert';

// import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService implements CanActivate {

  constructor(
    private alertService: AlertService,
    private router: Router,
    private fireAuthService: FireAuthService,
    private loginService: LoginService,
    private waitIconService: WaitIconService,
    private firestoreService: FirestoreService) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> | boolean {// | UrlTree

    const gameId = next.routeConfig.path === ':uGameId' ? next.params.uGameId : null;
    const groupid = next.queryParams.uGroupId ? next.queryParams.uGroupId.trim() : null;
    const email = next.queryParams.email ? next.queryParams.email.trim().toLocaleLowerCase() : null;
    const multiUsers = next.queryParams.multiUsers ? next.queryParams.multiUsers.trim() : null;

    if (this.fireAuthService.isLogin()) {
      return true;
    } else if (email && email !== '') {// לוגין מה URL
      return this.loginWithUrlEmail(email, multiUsers, gameId, groupid);
    } else if (!multiUsers && localStorage.getItem('nvMailAddress') && localStorage.getItem('nvPassword')) {
      return this.loginWithEmailAndPassword(localStorage.getItem('nvMailAddress'), localStorage.getItem('nvPassword'), {
        uGameId: gameId,
        nvMailAddress: email
      } as Params);
    } else {
      return this.fireAuthService.getUserIDAsync().then(id => {
        if (id) {
          return true;
        } else {
          return this.navigateToLogin({ uGameId: gameId } as Params);
        }
      });
    }
  }

  loginWithUrlEmail(email: string, multiUsers: boolean, gameId: string, groupid: string): Promise<boolean> {
    if (email === 'random') { // הגרלת שחקן זמני
      if (!multiUsers &&
        localStorage.getItem('nvMailAddress') && this.firestoreService.isTemporaryUser(localStorage.getItem('nvMailAddress')) &&
        localStorage.getItem('nvPassword') &&
        localStorage.getItem('uGameId') === gameId) {// נתוני שחקן זמני שמורים בדפדפן למשחק המתבקש
        return this.loginWithEmailAndPassword(localStorage.getItem('nvMailAddress'), localStorage.getItem('nvPassword'), {
          uGameId: gameId,
          nvMailAddress: email
        } as Params);
      } else {// הגרלת שחקן
        return this.loginWithRandomUser(gameId, { uGameId: gameId, uGroupId: groupid, email: email } as Params);
      }
    } else { // כניסה עם המייל של השחקן
      return this.loginOrRegisterWithEmailAndPassword(email, { uGameId: gameId, nvMailAddress: email } as Params);
    }
  }

  async loginWithEmailAndPassword(nvMailAddress: string, nvPassword: string, queryParams: Params): Promise<boolean> {
    this.waitIconService.setWaitVisible(true);
    const loginRes = await this.loginService.loginWithEmailAndPassword(nvMailAddress, nvPassword, false, true);
    this.waitIconService.setWaitVisible(false);
    if (!loginRes) {
      return this.navigateToLogin(queryParams);
    }
    return Promise.resolve(true);
  }

  async loginWithRandomUser(uGameId: string, queryParams: Params): Promise<boolean> {
    this.waitIconService.setWaitVisible(true);
    const loginRes = await this.loginService.loginWithRandomUser(uGameId);
    this.waitIconService.setWaitVisible(false);
    if (!loginRes) {
      return this.navigateToLogin(queryParams);
    }

    this.router.navigate([`${uGameId}/register`], { queryParams: { bReregister: true, uGroupId: queryParams.uGroupId, email: queryParams.email } });
    return Promise.resolve(false);
  }

  async loginOrRegisterWithEmailAndPassword(nvMailAddress: string, queryParams: Params): Promise<boolean> {
    const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (!regexp.test(nvMailAddress)) {
      this.alertService.addAlert('מייל לא חוקי', AlertType.danger);
      return this.navigateToLogin(queryParams);
    } else {
      this.waitIconService.setWaitVisible(true);
      const loginRes = await this.loginService.loginOrRegisterWithEmail(nvMailAddress, false, false);
      this.waitIconService.setWaitVisible(false);
      if (!loginRes) {
        return this.navigateToLogin(queryParams);
      }
      return Promise.resolve(true);
    }
  }

  navigateToLogin(queryParams: Params): Promise<boolean> {
    this.router.navigate([`login`], { queryParams: queryParams });
    return Promise.resolve(false);
  }
}
