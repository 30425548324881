import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class WaitIconService {

    private $waitVisible = new BehaviorSubject<boolean>(false);

    constructor() { }

    getWaitVisibleAsync(): BehaviorSubject<boolean> {
        return this.$waitVisible;
    }
    setWaitVisible(isVisible: boolean) {
        return this.$waitVisible.next(isVisible);
    }
}
