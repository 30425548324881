import { Component, OnInit, Input, OnDestroy, ElementRef, ViewChild, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FirebaseService } from '../../Services/firebase.service';
import { Subscription } from 'rxjs';
import { GameBroadcast, GameBroadcastTypesObj } from '../../Models/FirebaseModels';
import { ChatMessage } from 'src/app/Models/ChatMessage';
import { ChatMessageService } from 'src/app/Services/chat-message.service';

declare var SLDP: any;//TODO: tammy - לשנות את הvar ל let

@Component({
  selector: 'mm-clicker-broadcast',
  templateUrl: './clicker-broadcast.component.html',
  styleUrls: ['./clicker-broadcast.component.scss']
})
export class ClickerBroadcastComponent implements OnInit, OnDestroy, AfterContentChecked {

  lstPublishMessage = new Array<ChatMessage>();
  gameBroadcastTypes = GameBroadcastTypesObj;
  gameBroadcast: GameBroadcast;
  sldpPlayer;
  broadcastWidth: number = 400;
  isMobile: boolean = false;
  @Input() uGameId: string;

  // @Input('broadcastLink')
  // set broadcastId(nvBroadcast: string) {
  //   // this.setGameBroadcastSrc(nvBroadcast);
  // }

  bLiveBroadcast: boolean;
  gameBroadcastSrc: SafeResourceUrl;
  subscription: Subscription = new Subscription();

  //TODO: tammy - לבטל ViewChild!!!
  @ViewChild('videoBroadcast', null) videoBroadcast: ElementRef<any>;
  @ViewChild('iframeBroadcast', null) iframeBroadcast: ElementRef<any>;
  @ViewChild('broadcastContainer', { static: true }) parentDiv;
  @ViewChild('broadcastVideo', { static: true }) broadcastVideo;

  constructor(
    private sanitizer: DomSanitizer,
    private firebaseService: FirebaseService,
    private chatMessageService: ChatMessageService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.updatingLiveBroadcastChanges();
    this.updatingGameBroadcastChanges();
    this.getMessage();
  }

  ngAfterContentChecked() {
    try {
      this.broadcastWidth = ((this.parentDiv.nativeElement.offsetWidth - this.broadcastVideo.nativeElement.offsetWidth) / 2 - 3)
      this.cdr.detectChanges();
    } catch {

    }
  }

  getMessage() {
    this.subscription.add(
      this.chatMessageService.getPublishMessageValueChange(this.uGameId).subscribe(res => {
        this.lstPublishMessage = res.sort((m1, m2) => {return this.sortMessage(m1,m2)}).reverse();
      })
    );
  }

  sortMessage(message1: ChatMessage, message2: ChatMessage) {
     return message1.dtPublishDate - message2.dtPublishDate;
  }

  updatingLiveBroadcastChanges() {
    this.subscription.add(
      this.firebaseService
        .getGameLiveBroadcastChanges(this.uGameId)
        .subscribe(bLiveBroadcast => {
          this.bLiveBroadcast = bLiveBroadcast;
        })
    );
  }

  updatingGameBroadcastChanges() {
    let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if (/iPhone|iPad|iPod|CriOS/.test(navigator.userAgent)) {
      this.isMobile = true;
      // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) {
      this.gameBroadcast = new GameBroadcast(this.gameBroadcastTypes.iframe,
        `https://live1.shidur.net:8080/app/kasher1/playlist.m3u8`);
      this.setGameBroadcastSrc();
    }
    else {
      this.subscription.add(
        this.firebaseService
          .getGameBroadcastChanges(this.uGameId)
          .subscribe(gameBroadcast => {
            this.gameBroadcast = gameBroadcast;
            this.setGameBroadcastSrc();
          })
      );
    }
  }

  setGameBroadcastSrc() {
    if (this.gameBroadcast && this.gameBroadcast.nvBroadcastUrl && this.gameBroadcast.nvBroadcastUrl !== '') {
      switch (this.gameBroadcast.iBroadcastType) {
        case this.gameBroadcastTypes.broadcast:
          this.gameBroadcastSrc = this.gameBroadcast.nvBroadcastUrl;
          this.initSldpPlayer();
          break;
        case this.gameBroadcastTypes.iframe:
          this.gameBroadcastSrc = this.gameBroadcast.nvBroadcastUrl.includes('youtube') ?
            this.getTrustUrl(this.getYoutubeLink(this.gameBroadcast.nvBroadcastUrl)) :
            this.getTrustUrl(this.gameBroadcast.nvBroadcastUrl);
          break;
        case this.gameBroadcastTypes.drive:
          this.gameBroadcastSrc = this.getFileDriveLink(this.gameBroadcast.nvBroadcastUrl);
          break;
        default: // video
          this.gameBroadcastSrc = this.getTrustUrl(this.gameBroadcast.nvBroadcastUrl);
          break;
      }
      if (this.videoBroadcast) {
        this.videoBroadcast.nativeElement.load();
      } else if (this.iframeBroadcast) {
        this.iframeBroadcast.nativeElement.load();
      }
    } else {
      this.gameBroadcastSrc = null;
    }
  }

  getTrustUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getYoutubeLink(url: string) {
    return url + '?rel=0&amp;showinfo=0&amp;autoplay=1';
    // return url + '?rel=0&amp;controls=0&amp;showinfo=0&amp;autoplay=1';
  }

  getFileDriveLink(fileId: string): SafeResourceUrl {
    return 'https://drive.google.com/uc?export=view&id=' + fileId;
  }

  initSldpPlayer() {
    if (this.sldpPlayer) {
      this.sldpPlayer.destroy();
    } else {
      this.sldpPlayer = SLDP.init({
        container: 'sldp_player_wrapper',
        stream_url: this.gameBroadcastSrc,
        // stream_url: 'wss://live1.shidur.net:8080/abr_app/ran1?steady=true&buffering=1000', // שידור רגיל
        // stream_url: 'wss://live1.shidur.net:8080/abr_app/kasher1?steady=true&buffering=1000', // שידור כשר
        height: 'parent',
        width: 'parent',
        autoplay: true,
        // controls: false,
        // muted: true
      });

      // const video = document.getElementById('sldp_player_wrapper').firstElementChild.firstElementChild;
      // video.setAttribute('allowfullscreen', 'false');
      // video.onfullscreenchange = () => {
      //   // if (video.isFullscreen()) {
      //   //   video.exitFullscreen();
      //   // }
      // };

    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.sldpPlayer) {
      this.sldpPlayer.destroy();
    }
  }

}
