import { Injectable } from '@angular/core';
import { AppProxy } from './app.proxy';
import { LogService } from './log.service';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class ResponseService {
  constructor(
    private appProxy: AppProxy,
    private firebaseService: FirebaseService,
    private logService: LogService) { }

  insertResponsesWeb(iUserId: number, lstResponse, uGameId: string, bCalcResponseTime: boolean): Promise<boolean> {
    console.log(`ResponseService.insertResponsesWeb`);
    return this.appProxy
      .post('InsertResponsesWeb', {
        iUserId: iUserId,
        lstResponse: lstResponse, uGameId: uGameId,
        bCalcResponseTime: bCalcResponseTime
      })
      .then(res => {
        if (!res) {
          throw new Error(`server error. response: ${res}`);
        } else {
          return res;
        }
      })
      .catch((error: any) => {
        console.error(`ResponseService.insertResponsesWeb error. message: ${error.message}`);
        this.logService.logError(`ResponseService.insertResponsesWeb -> InsertResponsesWeb error. lstResponse: ${JSON.stringify(lstResponse)}, uGameId: ${uGameId}, bCalcResponseTime: ${bCalcResponseTime}`, error.message);
        return false;
      });
  }

  async getResponseByQuestion(uQuestionId: string, uClickerId: string): Promise<boolean> {
    console.log(`ResponseService.GetResponseByQuestion`);
    // debugger // getServiceAble לבדוק
    const bServerAvailable = await this.firebaseService.getServiceAble('ResponseService.GetResponseByQuestion');
    if (bServerAvailable) {
      return this.appProxy
        .post('GetResponseByQuestionWrap', { uQuestionId: uQuestionId, uClickerId: uClickerId })
        .then(res => {
          if (!res) {
            throw new Error(`server error. response: ${res}`);
          }
          return res;
        })
        .catch((error: any) => {
          console.error(`ResponseService.GetResponseByQuestionWrap error. message: ${error.message}`);
          this.logService.logError(`ResponseService.GetResponseByQuestion -> GetResponseByQuestionWrap error. uQuestionId: ${uQuestionId}, uClickerId: ${uClickerId}`, error.message);
          return null;
        });
    } else {
      console.error(`ResponseService.GetResponseByQuestionWrap error. server unavailable`);
      return null;
    }
  }

  GetResponseByClicker(uGameId:string,uClickerId:string):Promise<any>{
      return this.appProxy
        .post('GetResponseByClicker', { uGameId: uGameId,uClickerId:uClickerId })
        .then(res => {
          if (res) {
            return res;
          } else {
            return null;
          }
        })
        .catch((error: any) => {
          console.error(`ResponseService.GetResponseByClicker error. message: ${error.message}`);
          this.logService.logError(`ResponseService.GetResponseByClicker -> GetResponseByClicker error. uGameId: ${uGameId}, uClickerId:${uClickerId}`, error.message);
          return null;
        });
   
  }
}
