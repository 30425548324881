import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FirebaseService } from 'src/app/Services/firebase.service';

@Component({
  selector: 'mm-clicker-mode-btn',
  templateUrl: './clicker-mode-btn.component.html',
  styleUrls: ['./clicker-mode-btn.component.css']
})
export class ClickerModeBtnComponent implements OnInit {

  displayModes = [
    { name: 'broadcast', index: 0, text: 'תצוגת משחק', nextModeIndex: null },
    { name: 'clicker', index: 1, text: 'תצוגת שלט', nextModeIndex: null }];

  displayMode;

  @Output()
  modeChange = new EventEmitter<number>();

  constructor(private firebaseService:FirebaseService) {
    this.changeDisplayMode(this.displayModes[0]);
    this.firebaseService.getHideBroadcast().then(bHideBroadcast => {
      this.changeDisplayMode(this.displayModes[bHideBroadcast ? 1 : 0]);
    });
  }

  ngOnInit() { }

  changeDisplayMode(displayMode = null) {
    if (displayMode !== null) {
      this.displayMode = displayMode;
    } else {
      this.displayMode = this.getNextMode(this.displayMode);
    }

    if (!this.displayMode.nextModeId) {
      this.displayMode.nextModeIndex = this.getNextMode(this.displayMode).index;
    }

    this.modeChange.emit(this.displayMode.index);
  }

  getNextMode(displayMode) {
    return this.displayModes.length !== displayMode.index + 1 ? this.displayModes[displayMode.index + 1] : this.displayModes[0];
  }
}
