export class Global {

    static getUpdateDetailsObj(oldDetails: any, newDetails: any): any {
        const diff = Object.keys(newDetails).reduce((diff, key) => {

            if (oldDetails[key] === newDetails[key]) {
                return diff;
            }

            return {
                ...diff,
                [key]: newDetails[key]
            }
        }, {})

        return Object.keys(diff).length > 0 ? diff : null;
    }
}

