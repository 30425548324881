import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  private $nvCurrentPage = new BehaviorSubject<string>(null);

  constructor() { }

  getCurrentPageAsync(): BehaviorSubject<string> {
    return this.$nvCurrentPage;
  }

  setCurrentPageAsync(nvCurrentPage: string) {
    return this.$nvCurrentPage.next(nvCurrentPage);
  }
}
