import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';


@Injectable()
export class FireAnalyticsService {

    constructor(private analytics: AngularFireAnalytics) {
    }

    addEvent() {
        // this.analytics.logEvent('custom_event', { ... });
    }

    setUserId(uid: string) {
        this.analytics.setUserId(uid);
    }

}
