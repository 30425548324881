import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { LogService } from './log.service';
import { FirestoreService } from './firestore.service';
import { FireAnalyticsService } from './fireanalytics.service';
import { AlertService } from './alert.service';
import { AlertType } from '../Models/Alert';
import { first } from 'rxjs/operators';
import { User } from '../Models/User';

/* לקבל את רשימת המשתמשים שרשומים לפיירבייס
https://firebase.google.com/docs/auth/admin/manage-users#node.js_9
auth().listUsers()
https://stackoverflow.com/questions/41011844/how-can-i-list-all-users-using-firebase-admin-sdk#:~:text=There%20is%20no%20public%20API,from%20the%20Firebase%20Admin%20SDK.&text=Its%20not%20a%20good%20solution,and%20integrate%20with%20your%20application.
*/

/* כל סוגי השגיאות הקיימות
 https://firebase.google.com/docs/reference/js/firebase.User - פונקציות משתמש
 https://firebase.google.com/docs/reference/js/firebase.auth.Auth.html - פונקציות fireauth
 https://stackoverflow.com/questions/39152004/list-of-all-auth-errors-for-firebase-web-api
*/

@Injectable()
export class FireAuthService {

    firebaseUser: firebase.User;
    private $firebaseUser = new BehaviorSubject<firebase.User>(null);

    constructor(
        private alertService: AlertService,
        private fireAuth: AngularFireAuth,
        private firestoreService: FirestoreService,
        private fireAnalyticsService: FireAnalyticsService,
        private logService: LogService) {
        this.listenAuthStateChanges();
    }

    // updateUser() {
    //     this.fireAuth.updateCurrentUser(this.getUserFirebase()).then().catch();
    // }
    // deleteUser() {
    //     this.getUserFirebase().delete()
    //         .then(result => {
    //             // User deleted.
    //         }).catch(error => {
    //             // An error happened.
    //         });
    // }
    setErrorAuthMessage(error: firebase.auth.AuthError, linkMsg = '') {
        let msg = 'אירעה שגיאה בלתי צפויה';
        let duration = linkMsg ? 20000 : 5000;
        switch (error.code) {
            case undefined:
                if (error.message == 'Loading chunk firebase-auth failed.') {
                    msg = 'האתר נטען בצורה לא תקינה, אנא רפרש את הדפדפן כדי לטעון אותו מחדש';
                }
                break;
            case '11':
                //Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.
                if (error.message.includes('The database connection is closing')) {
                    msg = 'החיבור שלך עם המערכת התנתק. וודא שהמערכת לא פתוחה אצלך בכמה חלונות במקביל ונסה לרפרש את הטאב ';
                }
                break;
            case 'auth/invalid-email':
                msg = 'מייל לא חוקי';
                break;
            case 'auth/weak-password':
                msg = 'סיסמה חלשה/קצרה מידי';
                break;
            case 'auth/wrong-password':
                msg = 'אחד המפרטים שגויים או שהינך רשום רק עם חשבון google ללא סיסמה';
                break;
            case 'auth/email-already-in-use':
                msg = 'כתובת המייל הזו כבר רשומה אצלינו. אין צורך להרשם שוב.';
                break;
            case 'auth/too-many-requests':
                msg = 'הגישה לחשבון נחסמה בעקבות מספר רב של קריאות. אתה יכול לאפס את הסיסמה עכשיו או לנסות להיכנס שוב מאוחר יותר';
                // msg = 'הגישה לחשבון נחסמה בעקבות מספר רב של נסיונות כניסה שגויים. אתה יכול לאפס את הסיסמה עכשיו או לנסות להיכנס שוב מאוחר יותר';
                break;
            case 'auth/quota-exceeded':
                msg = 'מספר קריאות חריג';//TODO: tammy - ניסוח אחר?
                //The project's quota for this operation has been exceeded.
                break;
            case 'auth/user-not-found':
                msg = 'משתמש לא קיים במערכת';
                break;
            case 'auth/popup-blocked':
                msg = 'כדי שתוכל להיכנס עם חשבון גוגל אתה צריך לבטל את החסימה של חלונות קופצים';
                break;
            case 'auth/network-request-failed':
                msg = 'לא ניתן לבצע את הפעולה בעקבות בעיות ברשת האינטרנט';
                break;
            case 'auth/invalid-action-code':
                msg = 'קישור לא תקין. היה כבר בשימוש או שעבר זמן והקישור כבר לא פעיל';
                break;
            case 'auth/expired-action-code':
                msg = 'תוקף הקישור פג';
                break;
            case 'auth/popup-closed-by-user':
            case 'auth/cancelled-popup-request':
            case 'auth/user-cancelled':
                return;
        }

        this.alertService.addAlert(msg + linkMsg, AlertType.danger, duration);
    }

    setUserFirebase(user: firebase.User) {
        this.$firebaseUser.next(user);
    }

    getUserFirebase(): firebase.User {
        return this.$firebaseUser.getValue();
    }

    clearUserFirebase() {
        this.setUserFirebase(null);
        this.firestoreService.setUserFirestore(null);
    }

    getUserFirebaseAsync(): BehaviorSubject<firebase.User> {
        return this.$firebaseUser;
    }

    /* return types
    firebase.User: success
    null: auth error */
    createUserWithEmailAndPassword(email: string, password: string): Promise<firebase.User> {
        console.log(`FireAuthService.createUserWithEmailAndPassword`);
        return this.fireAuth
            .createUserWithEmailAndPassword(email, password)
            .then(async (res: firebase.auth.UserCredential) => {
                await this.sendEmailVerification(res.user);
                return res.user;
            })
            .catch((error: firebase.auth.AuthError) => {
                // auth/user-disabled // auth/user-not-found // auth/wrong-password
                if (error.code === 'auth/email-already-in-use') {
                    this.setErrorAuthMessage(error, ' <a class="embeds-link text-blue" href="#/login?bIsNew=0">להתחברות</a>');
                    return null;
                } else if (error.code === 'auth/invalid-email') {
                    this.setErrorAuthMessage(error);
                    return null;
                }
                this.setErrorAuthMessage(error);
                console.error(`FireAuthService.createUserWithEmailAndPassword error. message: ${error.message}`);
                this.logService.logError(`FireAuthService.createUserWithEmailAndPassword. email: ${email}, password: ${password}, error code: ${error.code}`, error.message);
                return null;
            });
    }

    /* return types
    true: success
    false: auth error */
    sendEmailVerification(user: firebase.User): Promise<boolean> {
        console.log(`FireAuthService.sendEmailVerification`);
        return user
            .sendEmailVerification()
            .then(res => {
                return true;
            })
            .catch((error: firebase.auth.AuthError) => {
                this.setErrorAuthMessage(error);
                if (error.code === 'auth/too-many-requests') {
                    return false;
                }
                console.error(`FireAuthService.sendEmailVerification error. message: ${error.message}`);
                this.logService.logError(`FireAuthService.sendEmailVerification. user.uid: ${user.uid}, user.email: ${user.email}, error code: ${error.code}`, error.message);
                return false;
            });
    }

    /* return types
    true: success
    false: auth error */
    sendPasswordResetEmail(passwordResetEmail: string): Promise<boolean> {
        console.log(`FireAuthService.sendPasswordResetEmail`);
        return this.fireAuth
            .sendPasswordResetEmail(passwordResetEmail)
            .then(res => {
                return true;
            })
            .catch((error: firebase.auth.AuthError) => {
                // auth/missing-continue-uri // auth/invalid-continue-uri // auth/unauthorized-continue-uri
                this.setErrorAuthMessage(error);
                if (error.code === 'auth/user-not-found' || error.code === 'auth/invalid-email') {
                    return false;
                }
                console.error(`FireAuthService.sendPasswordResetEmail error. message: ${error.message}`);
                this.logService.logError(`FireAuthService.sendPasswordResetEmail. passwordResetEmail: ${passwordResetEmail}, error code: ${error.code}`, error.message);
                return false;
            });
    }

    /* return types
    true: success
    false: auth error */
    confirmPasswordReset(code: string, password: string): Promise<boolean> {
        console.log(`FireAuthService.confirmPasswordReset`);
        return this.fireAuth
            .confirmPasswordReset(code, password)
            .then(() => {
                return true;
            })
            .catch((error: firebase.auth.AuthError) => {
                // auth/user-disabled
                if (error.code === 'auth/invalid-action-code' ||
                    error.code === 'auth/expired-action-code') {
                    this.setErrorAuthMessage(error, ' <a class="embeds-link text-blue" href="#/forgotPassword"> לאיפוס סיסמה </a> שוב');
                    return false;
                } else if (error.code === 'auth/user-not-found' || error.code === 'auth/weak-password') {
                    this.setErrorAuthMessage(error);
                    return false;
                }
                this.setErrorAuthMessage(error);
                console.error(`FireAuthService.confirmPasswordReset error. message: ${error.message}`);
                this.logService.logError(`FireAuthService.confirmPasswordReset. code: ${code}, password: ${password}, error code: ${error.code}`, error.message);
                return false; // check this helper class at the bottom
            });
    }

    /* return types
    email: success
    null: auth error */
    verifyPasswordResetCode(code: string): Promise<string> {
        console.log(`FireAuthService.verifyPasswordResetCode`);
        return this.fireAuth
            .verifyPasswordResetCode(code)
            .then((email) => {
                return email;
            })
            .catch((error: firebase.auth.AuthError) => {
                // auth/user-disabled
                if (error.code === 'auth/invalid-action-code' ||
                    error.code === 'auth/expired-action-code') {
                    this.setErrorAuthMessage(error, ' <a class="embeds-link text-blue" href="#/forgotPassword">  לאיפוס סיסמה</a>');
                    return null;
                } else if (error.code === 'auth/user-not-found') {
                    this.setErrorAuthMessage(error);
                    return null;
                }

                this.setErrorAuthMessage(error);
                console.error(`FireAuthService.verifyPasswordResetCode error. message: ${error.message}`);
                this.logService.logError(`FireAuthService.verifyPasswordResetCode. code: ${code}, error code: ${error.code}`, error.message);
                return null;
            });
    }

    async updateUserPassword(user: firebase.User, newPassword: string): Promise<boolean> {
        console.log(`FireAuthService.updateUserPassword`);
        user = user ? user : this.getUserFirebase();
        return user
            .updatePassword(newPassword)
            .then(res => {
                this.setLocalStorageUser(user.email, newPassword);
                return true;
            })
            .catch((error: firebase.auth.AuthError) => {
                this.setErrorAuthMessage(error);
                console.error(`FireAuthService.updateUserPassword error. message: ${error.message}`);
                this.logService.logError(`FireAuthService.updateUserPassword. uid: ${user.uid}, newPassword: ${newPassword}, error code: ${error.code}`, error.message);
                return false;
            });
    }

    /* return types
    true: success
    false: auth error */
    updateUserProfil(displayName: string, photoURL: string, user: firebase.User = this.getUserFirebase()) {
        console.log(`FireAuthService.updateUserProfil`);
        return user
            .updateProfile({ displayName: displayName, photoURL: photoURL })
            .then(res => {
                return true;
            })
            .catch((error: firebase.auth.AuthError) => {
                this.setErrorAuthMessage(error);
                console.error(`FireAuthService.updateUserProfil error. message: ${error.message}`);
                this.logService.logError(`FireAuthService.updateUserProfil. user.uid: ${user.uid}, displayName: ${displayName}, photoURL: ${photoURL}, error code: ${error.code}`, error.message);
                return false;
            });
    }

    /* return types
    true: success
    false: setUserFirestore failed
    null: firestore user document doesn't exist */
    async setLogin(user: firebase.User, password: string = null, bSetUserFirestore: boolean = true): Promise<boolean> {
        if (password) {
            this.setLocalStorageUser(user.email, password);
        }
        this.fireAnalyticsService.setUserId(user.uid);
        this.setUserFirebase(user);
        console.log(this.firestoreService.isTemporaryUser(user.email) ? `random user: ${user.email.split('@')[0]}` : `user: ${user.email}`);
        if (bSetUserFirestore) {
            const bSetUser = await this.firestoreService.setUserFirestore(user.uid);
            if (!bSetUser) {
                return bSetUser;
            }
        }
        return true;
    }
    /* return types
    firebase.User: success
    error: auth error */
    signInWithEmailAndPassword(email: string, password: string, bMessageWrongPass): Promise<firebase.User | any> {
        console.log(`FireAuthService.signInWithEmailAndPassword`);
        return this.fireAuth
            .signInWithEmailAndPassword(email, password)
            .then(async (res: firebase.auth.UserCredential) => {
                return res.user;
            })
            .catch((error: firebase.FirebaseError) => {
                if (error.code !== 'auth/wrong-password' || (bMessageWrongPass && error.code === 'auth/wrong-password')) {
                    this.setErrorAuthMessage(error);
                }
                // auth/invalid-email  // auth/user-disabled
                if (error.code === 'auth/wrong-password' ||
                    error.code === 'auth/too-many-requests' ||
                    error.code === 'auth/user-not-found' ||
                    error.code === 'auth/network-request-failed') {
                    return error;
                }

                console.error(`FireAuthService.signInWithEmailAndPassword error. message: ${error.message}`);
                this.logService.logError(`FireAuthService.signInWithEmailAndPassword -> fireAuth.signInWithEmailAndPassword. email: ${email}, password: ${password}, error code: ${error.code}`, error.message);
                return error;
            });
    }

    /* return types
    user: correct password
    null: auth error */
    verifyUserPassword(password: string): Promise<boolean> {
        console.log(`FireAuthService.verifyUserPassword`);
        const user = this.getUserFirebase();
        const credential = firebase.auth.EmailAuthProvider.credential(
            user.email,
            password
        );
        return user.reauthenticateWithCredential(credential)
            .then(res => {
                return res.user;
            }).catch((error: firebase.auth.AuthError) => {
                this.setErrorAuthMessage(error);
                if (error.code === 'auth/wrong-password') {
                    return null;
                }

                console.error(`FireAuthService.verifyUserPassword error. message: ${error.message}`);
                this.logService.logError(`FireAuthService.verifyUserPassword -> reauthenticateWithCredential. mail: ${user.email}, credential: ${error.credential}, error code: ${error.code}`, error.message);
                return null;
            });
    }

    /* return types
    true: success
    firebase.User: email doesn't verified
    error: auth error    
    false: setLogin failed
    null: firestore user document doesn't exist */
    async loginWithEmailAndPassword(email: string, password: string, bEmailVerification: boolean, bMessageWrongPass: boolean): Promise<boolean | firebase.User | any> {
        const signInRes = await this.signInWithEmailAndPassword(email, password, bMessageWrongPass);
        if (signInRes.uid) {
            if (bEmailVerification && !this.isEmailVerified(signInRes)) {
                this.fireAnalyticsService.setUserId(signInRes.uid);
                return signInRes;
            } else {
                const bSetUser = await this.setLogin(signInRes, password, true);
                if (!bSetUser) {
                    return bSetUser;
                }
                return true;
            }
        }
        return signInRes;
    }

    getLastLoginTime(user: firebase.User): Date | any {
        return new Date(user.metadata.lastSignInTime);
    }

    getCreationTime(user: firebase.User): Date | any {
        return new Date(user.metadata.creationTime);
    }

    /*return types
    email: user doesn't exist in DB or/and firestore or new user
    true: success    
    false: auth error | setLogin failed  */
    loginWithGoogle(bMailingList: boolean): Promise<boolean | string> {
        console.log(`FireAuthService.loginWithGoogle`);
        const provider = new firebase.auth.GoogleAuthProvider();
        return this.fireAuth
            .signInWithPopup(provider)
            .then(async (result: firebase.auth.UserCredential) => {
                const bSetLogin = await this.setLogin(result.user, null, !result.additionalUserInfo.isNewUser);
                if (bSetLogin === null) { //user doesn't exist in DB or/and firestore
                    return 'old user';
                } else if (bSetLogin === false) { //bSetLogin failed
                    if (result.additionalUserInfo.isNewUser) {
                        console.error(`FireAuthService.loginWithGoogle error. failed to created new user`);
                        this.logService.logError(`FireAuthService.loginWithGoogle -> register error. uid: ${result.user.uid}, displayName: ${result.user.displayName}, email: ${result.user.email}, bMailingList: ${bMailingList}`, 'user exists only on firebase');
                        return null;
                    }
                    throw new Error(`setLogin error. uid: ${result.user.uid}, displayName: ${result.user.displayName}, email: ${result.user.email}, bMailingList: ${bMailingList}`);
                }
                return result.additionalUserInfo.isNewUser ? 'new user' : true;
            })
            .catch((error: firebase.auth.AuthError) => {
                // auth/account-exists-with-different-credential // auth/auth-domain-config-required
                // auth/operation-not-allowed // auth/operation-not-supported-in-this-environment
                // auth/unauthorized-domain
                this.setErrorAuthMessage(error);
                // error.message === 'setLogin error' ||
                if (error.code === 'auth/popup-closed-by-user' ||
                    error.code === 'auth/cancelled-popup-request' ||
                    error.code === 'auth/popup-blocked' ||
                    error.code === 'auth/network-request-failed') {
                    return false;
                }

                console.error(`FireAuthService.loginWithGoogle error. message: ${error.message}`);
                this.logService.logError(`FireAuthService.loginWithGoogle -> fireAuth.signInWithPopup. mail: ${error.email}, credential: ${error.credential}, error code: ${error.code}`, error.message);
                return false;
            });
    }

    /*return types
    Array<string>: providers
    null: auth error */
    getUserProviders(email: string): Promise<Array<string>> {
        console.log(`FireAuthService.getUserProviders`);
        return this.fireAuth
            .fetchSignInMethodsForEmail(email)
            .then((res: Array<string>) => {
                return res;
            })
            .catch((error: firebase.auth.AuthError) => {
                this.setErrorAuthMessage(error);
                if (error.code === 'auth/network-request-failed') {
                    return null;
                }
                console.error(`FireAuthService.getUserProviders error. message: ${error.message}`);
                this.logService.logError(`FireAuthService.getUserProviders -> fireAuth.fetchSignInMethodsForEmail. email: ${email}, error code: ${error.code} `, error.message);
                return null;
            });
    }

    /*return types
    true: exist
    false: doesn't exist
    null: auth error */
    async isUserExist(email: string): Promise<boolean> {
        console.log(`FireAuthService.isUserExist`);
        const userProviders = await this.getUserProviders(email);
        if (!userProviders) {
            return null;
        }
        return userProviders.length > 0;
    }

    isEmailVerified(user: firebase.User): boolean {
        return user.emailVerified;
    }

    /*return types
    true: apply
    false: auth error */
    applyEmail(actionCode: string) {
        console.log(`FireAuthService.applyEmail`);
        return this.fireAuth
            .applyActionCode(actionCode)
            .then((res) => {
                return true;
            })
            .catch((error: firebase.auth.AuthError) => {
                // auth/user-disabled
                this.setErrorAuthMessage(error);
                if (error.code === 'auth/invalid-action-code' ||
                    error.code === 'auth/expired-action-code' ||
                    error.code === 'auth/user-not-found') {
                    return false;
                }
                console.error(`FireAuthService.applyEmail error. message: ${error.message}`);
                this.logService.logError(`FireAuthService.applyEmail -> fireAuth.applyActionCode. actionCode: ${actionCode}, error code: ${error.code} `, error.message);
                return false;
            });
    }

    isLogin() {
        // this.fireAuth.authenticated
        return this.getUserFirebase() ? true : false;
    }

    logOut() {
        this.fireAuth.signOut();
        this.clearUserFirebase();
        this.deleteLocalStorageUser();
        console.log(`user logged out`);
    }

    async getUserIDAsync() {
        const user = await this.fireAuth.authState.pipe(first()).toPromise();
        if (user) {
            this.setLogin(user);
            // const userFb = this.getUserFirebase();
            // userFb.displayName = user.displayName ? user.displayName : this.getDisplayNameFromEmailAddress(user.email);
           
            await this.firestoreService.setUserFirestore(user.uid)
            return user.uid;
        }
        return null;
    }
    getDisplayNameFromEmailAddress(email: any): any {
        throw new Error('Method not implemented.');
    }
    listenAuthStateChanges() {
        this.fireAuth
            .onAuthStateChanged((user: firebase.User) => {
                if (user == null) {
                    this.logOut();
                }
            });
    }

    setLocalStorageUser(nvMailAddress: string, nvPassword: string) {
        localStorage.setItem('nvMailAddress', nvMailAddress ? nvMailAddress.toLocaleLowerCase() : nvMailAddress);
        localStorage.setItem('nvPassword', nvPassword);
    }

    deleteLocalStorageUser() {
        localStorage.removeItem('nvMailAddress');
        localStorage.removeItem('nvPassword');
    }
}
