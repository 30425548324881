import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SysTable } from '../../Services/SysTables.service';

@Component({
  selector: 'mm-clicker-game-status',
  templateUrl: './clicker-game-status.component.html',
  styleUrls: ['./clicker-game-status.component.css']
})
export class ClickerGameStatusComponent implements OnInit, OnDestroy {

  countDownTimerTxt: string;
  timeOutIDs: Array<any> = new Array<any>();

  @Input() dtStartGame: Date;
  @Input() iStatusType: number;

  get staticGameStatus() {
    return SysTable.constantStatusTypeGame;
  }

  constructor() { }

  ngOnInit() {
    this.setCountDownTimer();
  }

  setCountDownTimer() {
    const difference = this.dtStartGame.getTime() - (new Date()).getTime();
    if (difference >= 0) {
      let seconds = Math.floor(difference / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      seconds %= 60;
      minutes %= 60;
      hours %= 24;
      this.countDownTimerTxt =
        // `${days > 0 ? days + ' : ' : ''}
        // ${hours > 0 ? hours + ' : ' : ''}
        // ${minutes > 0 ? minutes + ' : ' : ''}
        // ${seconds}`
        `${days > 0 ? days + ' : ' : ''}${hours} : ${minutes} : ${seconds}`;
      this.timeOutIDs.push(setTimeout(this.setCountDownTimer.bind(this), 10));
    } else { // סיום הטיימר סופית
      this.countDownTimerTxt = null;
      this.clearTimeOuts();
    }
  }

  clearTimeOuts() {
    this.timeOutIDs.forEach(id => clearTimeout(id));
    this.timeOutIDs = new Array<any>();
  }

  ngOnDestroy() {
    this.clearTimeOuts();
  }

}
