import { Component, HostListener, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Game } from '../../Models/Game';
import { GameService } from '../../Services/game.service';
import { ChatMessage } from '../../Models/ChatMessage';
import { ChatMessageService } from '../../Services/chat-message.service';
import { FireAuthService } from '../../Services/fireauth.service';
import { GlobalService } from '../../Services/global.service';
const POOL_ID: string = "00000000-0000-0000-0000-000000000000";
const MESSAGE_TYPE: any = {
  feedback: { fireStoreUid: "feedback", nvText: "שליחת משוב", nvResponse: "איזה משוב תרצה לתת לנו?" },
  broadcastMessage: { fireStoreUid: "broadcastMessage", nvText: "שליחת הודעה לשידור", nvResponse: "מה תרצה שנקריא בשידור?" },
  bugReport: { fireStoreUid: "bugReport", nvText: "דיווח על תקלה", nvResponse: "פרט בדיוק היכן התקלה, כל פרט חשוב מאוד" }
};
const PAGE_DATA = {
  'ClickerComponent': { nvName: "שלט" },
  'GameNavigationComponent': { nvName: "כניסה למשחק (עם קוד סודי)" },
  'RegisterGameComponent': { nvName: "רישום שלט לקבוצות במשחק" },
  'UserDetailsComponent': { nvName: "עריכת פרטי משתמש" },
  'LoginComponent': { nvName: "הרשמה / התחברות" },
  'LoginEmailComponent': { nvName: "רישום מוקדם עם מייל" },
  'ForgotPasswordComponent': { nvName: "שכחתי סיסמה" },
  'ResetAndVerifyComponent': { nvName: "קביעת סיסמה חדשה לאחר איפוס" },
}
const STEP_TYPE: any = { choseType: 1, fillText: 2, successMessage: 3, type: -1, exit: 4, typeConcat: 5 };
@Component({
  selector: 'mm-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnInit {
  readonly objectKeys = Object.keys;
  readonly staticStepType = STEP_TYPE;
  readonly staticMessageType = MESSAGE_TYPE;
  readonly staticPageData = PAGE_DATA;

  iCurrentStep: number = STEP_TYPE.choseType;
  uGameId: string = POOL_ID;
  subscription: Subscription = new Subscription();
  firebaseUser: firebase.User;
  nvTextMessage: string = "";

  nvCurrentPage: string = "";

  nvResponse: string = "";
  typeMessage: any = null;
  message: ChatMessage;
  robotMessage = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private fireAuthService: FireAuthService,
    private chatMessageService: ChatMessageService,
    private gameService: GameService,
    private globalService: GlobalService) {
    this.subscription.add(
      this.fireAuthService.getUserFirebaseAsync()
        .subscribe((firebaseUser: firebase.User) => {
          this.firebaseUser = firebaseUser;
          console.log(this.firebaseUser);
        })
    );
    this.subscription.add(
      this.activatedRoute.queryParams.subscribe(params => {
        if (params.uGameId) {
          this.uGameId = params.uGameId;
        } else {
          this.uGameId = POOL_ID;
        }
      })
    )
    this.subscription.add(
      this.gameService.getGameAsync()
        .subscribe(async (game: Game) => {
          if (game) {
            this.uGameId = game.uGameId;
          }
        })
    );
    this.subscription.add(
      this.globalService.getCurrentPageAsync()
        .subscribe((nvCurrentPage: string) => {
          this.nvCurrentPage = nvCurrentPage;
        })
    );
  }

  ngOnInit() {
    this.robotResponse(STEP_TYPE.choseType);

  }

  choseType(value: string) {
    this.typeMessage = MESSAGE_TYPE[value];
    this.robotMessage.push({ nvMessage: this.typeMessage.nvText, iType: 2 });
    this.robotResponse(STEP_TYPE.fillText, this.typeMessage.nvResponse);
  }

  robotResponse(iStep, nvMessage: string = "") {
    let indx = 0;
    setTimeout(() => {
      indx = this.robotMessage.push({ iType: 1, iStep: STEP_TYPE.type, nvMessage: nvMessage }) - 1;
      this.scrollDown();
    }, 1000);
    setTimeout(() => {
      this.robotMessage[indx].iStep = iStep;
      this.scrollDown();
    }, 3000);
    this.iCurrentStep = iStep;
  }

  private scrollDown(): void {
    setTimeout(() => {
      document.getElementById('phone-screen').scrollTo(0, 2000);
    }, 10);
  }

  onSubmit(): void {
    this.robotMessage.push({ nvMessage: this.nvTextMessage, iType: 2 });
    if (this.iCurrentStep == STEP_TYPE.fillText) {
      this.message = new ChatMessage();
      this.message.nvMessage = this.nvTextMessage;
      if (this.firebaseUser) {
        this.message.uid = this.firebaseUser.uid;
        this.message.nvMailAdress = this.firebaseUser.email;
        this.message.nvDisplayName = this.firebaseUser.displayName;
        this.message.nvPage = this.nvCurrentPage;
      }
      else if (this.typeMessage.fireStoreUid == MESSAGE_TYPE.bugReport.fireStoreUid) {
        this.robotResponse(STEP_TYPE.typeConcat, "תוכל לתת לנו את כתובת המייל ליצירת קשר לפרטים נוספים?");
        this.nvTextMessage = null;
        return;
      }
    } else {
      this.message.nvMailAdress = this.nvTextMessage;
    }
    this.sendMessage();
    // this.message.nvMailAdress = this.firebaseUser?this.firebaseUser.email:'';

  }

  sendMessage() {
    this.chatMessageService.addMessageGameCollection(this.typeMessage.fireStoreUid, this.uGameId, this.message)
    this.robotResponse(STEP_TYPE.successMessage);
    this.typeMessage = null;
    this.nvTextMessage = null;
    this.scrollDown();
  }

  confirmExit(bExit: boolean) {
    if (bExit) {
      this.robotMessage.push({ nvMessage: 'לא, תודה', iType: 2 });
      this.robotResponse(STEP_TYPE.exit);
    } else {
      this.robotMessage.push({ nvMessage: 'כן, יש לי עוד משהו לומר', iType: 2 });
      this.robotResponse(STEP_TYPE.choseType);
    }

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
