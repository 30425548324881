import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mm-reset-and-verify',
  templateUrl: './reset-and-verify.component.html',
  styleUrls: ['./reset-and-verify.component.css']
})
export class ResetAndVerifyComponent implements OnInit, OnDestroy {

  mode: string;
  oobCode: string;
  subscription: Subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute) {
    this.subscription.add(
      this.activatedRoute.queryParams.subscribe(params => {
        if (params) {
          this.mode = params['mode']
          this.oobCode = params['oobCode'];
        }
      })
    );
  }

  ngOnInit() { }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
