export class FirestoreUser {
    public uClickerId: string;
    public iUserId: number;
    public nvDisplayName: string;
    public nvMailAddress: string;
    public nvPassword: string;
    public bAcceptedRules: boolean;
    public bMailingList: boolean;

    constructor(
        uClickerId: string,
        iUserId: number,
        nvDisplayName: string,
        nvMailAddress: string,
        nvPassword: string,
        bAcceptedRules: boolean,
        bMailingList: boolean) {
        this.uClickerId = uClickerId;
        this.iUserId = iUserId;
        this.nvDisplayName = nvDisplayName;
        this.nvMailAddress = nvMailAddress;
        this.nvPassword = nvPassword;
        this.bAcceptedRules = bAcceptedRules;
        this.bMailingList = bMailingList;
    }
}
