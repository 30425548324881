import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mm-clicker-score',
  templateUrl: './clicker-score.component.html',
  styleUrls: ['./clicker-score.component.css']
})
export class ClickerScoreComponent implements OnInit {

  iScore: number = 0;
  @Input() set iClickerScore(iClickerScore: number) {
    this.iScore = iClickerScore ? Math.floor(iClickerScore) : 0;
  }

  constructor() { }

  ngOnInit() { }

}
