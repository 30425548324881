import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mm-chatbot-icon',
  templateUrl: './chatbot-icon.component.html',
  styleUrls: ['./chatbot-icon.component.css']
})
export class ChatbotIconComponent implements OnInit {

  @Input() bDark_bg:boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
