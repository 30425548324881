import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginService } from '../../Services/login.service';
import { WaitIconService } from '../../Services/wait-icon.service';
import { FireAuthService } from '../../Services/fireauth.service';
import { FirestoreService } from '../../Services/firestore.service';
import { AlertService } from '../../Services/alert.service';
import { AlertType } from '../../Models/Alert';
import { FirebaseService } from 'src/app/Services/firebase.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { URL_GOOGLE_GROUP } from 'src/app/Models/Constants';

@Component({
  selector: 'mm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();
  uGameId: string;
  bIsNew: boolean = true;
  bResetPassword: boolean = false;
  showPassword: boolean = false;
  bSubmited: boolean = true;
  nvLicenseText: SafeHtml = "";

  get getGoogleGroupUrl(){
    return URL_GOOGLE_GROUP;
  }

  loginForm = new FormGroup({
    nvMailAddress: new FormControl('', [<any>Validators.required, Validators.email]),
    nvPassword: new FormControl('', [<any>Validators.required, Validators.minLength(6), Validators.pattern('^[A-Za-z0-9]+$')]),
    // nvConfirmPassword: new FormControl('', [<any>Validators.required]),
    nvDisplayName: new FormControl('', [<any>Validators.required, Validators.maxLength(35)]),
    bAcceptedRules: new FormControl(false, [<any>Validators.required, Validators.pattern('true')]),
    bAcceptedSendMail: new FormControl(false),
    bMailingList: new FormControl(false)
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private router: Router,
    private loginService: LoginService,
    private firestoreService: FirestoreService,
    private fireAuthService: FireAuthService,
    private waitIconService: WaitIconService,
    private sanitizer: DomSanitizer,
    private firebaseService: FirebaseService) {

    this.subscription.add(
      this.activatedRoute.queryParams.subscribe(params => {
        if (params) {
          this.bIsNew = params.bIsNew == 1;
          if (params.nvMailAddress) {
            this.loginForm.get('nvMailAddress').setValue(params.nvMailAddress);
            this.loginForm.get('nvMailAddress').markAsDirty();
          }
          if (params.uGameId) {
            this.loginForm.controls['bAcceptedSendMail'].setValidators([]);
            this.uGameId = params.uGameId;
          } else {
            this.loadLicenseTextTxt();
          }
          this.router.navigate(['login']);
        }
      })
    );
  }

  ngOnInit() {
    setTimeout(() => { // בשביל לתת זמן לפונקציות שדורשות הרשאה להתבצע לפני יציאת המשתמש מהחשבון
      this.loginService.logOut();
    }, 3000);
  }

  submitForm(valueForm: FormGroup) {
    if (this.bIsNew) {
      this.registerWithEmailAndPassword(valueForm);
    } else {
      this.loginWithEmailAndPassword(valueForm);
    }
  }
  loadLicenseTextTxt() {
    this.firebaseService
      .getLicenseTextSnapshot()
      .then(res => {
        if (res) {
          this.nvLicenseText = this.sanitizer.bypassSecurityTrustHtml(res);
          this.loginForm.controls['bAcceptedSendMail'].setValidators([Validators.required, Validators.pattern('true')]);
        } else {
          this.nvLicenseText = null;
          this.loginForm.controls['bAcceptedSendMail'].setValidators([]);
        }
      })
  }

  addValidationMessage() {
    const strRegisterlink = `<a class="embeds-link text-blue" href="#/login?bIsNew=1">להרשם</a>`;
    const strLoginlink = `<a class="embeds-link text-blue" href="#/login?bIsNew=0">להתחבר</a>`;
    const validationMessage = `אי אפשר להכנס למשחק בלי ${strRegisterlink} או ${strLoginlink} עם שם, מייל וסיסמה.`;
    // this.alertService.addAlert("login.alert.need-register-or-login", AlertType.danger, 20000);
    this.alertService.addAlert(validationMessage, AlertType.danger, 20000);
  }

  async registerWithEmailAndPassword(valueForm: FormGroup) {

    if (!valueForm.valid || !this.checkPassword) {
      this.addValidationMessage();
      return;
    }

    this.bSubmited = false;
    const registerRes = await this.loginService.register(valueForm.value, true);
    if (registerRes) {
      this.bIsNew = false;
      // valueForm.reset();
      valueForm.get('nvPassword').reset();
    }

    this.bSubmited = true;
  }

  async loginWithEmailAndPassword(valueForm: FormGroup) {

    if (valueForm.get('nvPassword').invalid || valueForm.get('nvMailAddress').invalid) {
      this.addValidationMessage();
      return;
    }
    else if (this.checkValidCheckbox(valueForm)) {
      // this.alertService.addAlert("אפשר להירשם רק אחרי הסכמה לתנאים", AlertType.danger, 20000);
      //this.alertService.addAlert("login.alert.accepted-rules-invalid", AlertType.danger, 20000);
      return;
    }

    this.bSubmited = false;
    this.waitIconService.setWaitVisible(true);
    const loginRes = await this.loginService.loginWithEmailAndPassword(valueForm.get('nvMailAddress').value, valueForm.get('nvPassword').value, true, true);
    this.waitIconService.setWaitVisible(false);
    this.bSubmited = true;
    if (loginRes) {
      //TODO: tammy - זמני, עד שכל המשתמשים יאשרו את התנאים
      this.bSubmited = false;
      this.waitIconService.setWaitVisible(true);
      await this.firestoreService.updateUserDetails(this.fireAuthService.getUserFirebase().uid, valueForm.get('bAcceptedRules').value, valueForm.get('bMailingList').value);
      this.waitIconService.setWaitVisible(false);
      this.bSubmited = true;
      // -----------------------

      this.moveToSystem();
    }
  }

  async loginWithGoogle(valueForm: FormGroup) {

    if (this.checkValidCheckbox(valueForm)) {
      return;
    }

    this.bSubmited = false;
    this.waitIconService.setWaitVisible(true);
    const loginRes = await this.loginService.loginWithGoogle(valueForm.get('bAcceptedRules').value, valueForm.get('bMailingList').value);
    this.waitIconService.setWaitVisible(false);
    this.bSubmited = true;
    if (loginRes) {
      this.moveToSystem();
    }
  }

  checkValidCheckbox(valueForm: FormGroup): boolean {
    if (valueForm.get('bAcceptedRules').invalid) {
      // this.alertService.addAlert("אפשר להיכנס רק אחרי הסכמה לתנאים", AlertType.danger, 20000);
      this.alertService.addAlert("login.alert.accepted-rules-invalid", AlertType.danger, 20000);
      this.loginForm.get('bAcceptedRules').markAsDirty();
      return true;
    }
    else if (valueForm.get('bAcceptedSendMail').invalid) {
      // this.alertService.addAlert("אפשר להיכנס רק אחרי הסכמה לתנאים", AlertType.danger, 20000);
      this.alertService.addAlert(`שכחת לאשר את העברת המייל`, AlertType.danger, 20000);
      this.loginForm.get('bAcceptedSendMail').markAsDirty();
      return true;
    }
    return false
  }
  // addUserPhone(){
  //   this.firestoreService.addUserPhone();
  // }
  get checkPassword() {
    // return this.loginForm.get('nvPassword').value == this.loginForm.get('nvConfirmPassword').value;
    return true;
  }

  moveToSystem() {
    if (this.uGameId) {
      this.router.navigate([`${this.uGameId}/register`]);
    } else {
      this.router.navigate(['gameNavigation']);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
