import { Injectable } from '@angular/core';
import { StringifyOptions } from 'querystring';
import { EMAIL_DATA } from '../Components/chat-support/chat-support.data';
import { AppProxy } from './app.proxy';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private proxy: AppProxy) { }

  sendEmail(targetEmail: string, nvSubject: string, nvBody: string) {
    return this.proxy
      .post('SendEmail', {
        from: EMAIL_DATA.message
        , displayName: "דני- מערכת צ'טבוט"
        , to: targetEmail
        , subject: nvSubject
        , body: nvBody
      })
      .then(res => { })
      .catch((error: any) => {
        console.error(`EmailService.SendEmail error. message: ${error.message}`);
      });
  }
}
