export const EMAIL_DATA = {
    x93131: "x93131@gmail.com"
    , x93132: "x93132@gmail.com"
    , message: "milchemetmochot.messages@gmail.com"
}
export class RobotResponse {
    public nvMessage: string[];
    public nvTextBtn: string;
    public nvActionName: string | string[];
    // public iLstBtnPtr: Array<RobotResponse>;
    public iLstBtnPtr: number;
    public dataParams: any | any[];
    public lstPages: Array<number>;
    public userForm: Array<UserForm> = null;
    public iSubmitBtn: number;
    public nvTitleForm: string;
    // public nvColor: string;
    public nvTextToSend: string;
    public bResetText: boolean;
    public nvActionSubmit: string;
    public submitParams: any;
}
// https://did.li/XioCN
export class UserForm {
    nvQuestion: string;
    nvFieldName: string;
    nvType: string;
}
export const PAGES_ID = {
    all: 0,
    ClickerComponent: 1,
    GameNavigationComponent: 2,
    RegisterGameComponent: 3,
    UserDetailsComponent: 4,
    LoginComponent: 5,
    LoginEmailComponent: 6,
    ForgotPasswordComponent: 7,
    ResetAndVerifyComponent: 8
}

const userDetailForm = [
    {
        nvQuestion: "מה שמך?",
        nvFieldName: "שם המזמין",
        nvType: "text"
    },
    {
        nvQuestion: "כתובת המייל:",
        nvFieldName: "כתובת מייל",
        nvType: "text"
    },
    {
        nvQuestion: "מס' טלפון",
        nvFieldName: "מס' טלפון",
        nvType: "text"
    },
    {
        nvQuestion: "משחק לכמה משתתפים (בערך)?",
        nvFieldName: "כמות משתתפים משוערת",
        nvType: "number"
    },
    {
        nvQuestion: "עוד משהו שחשוב שנדע?",
        nvFieldName: "הערות",
        nvType: "text"
    },
]
const user2DetailForm = [
    {
        nvQuestion: "רק השם שלך, שנדע את מי לעדכן...",
        nvFieldName: "שם המבקש",
        nvType: "text"
    },
    {
        nvQuestion: "וכתובת המייל:",
        nvFieldName: "וכתובת המייל",
        nvType: "text"
    }
]
export const userGlobalForm = [
    {
        nvQuestion: "במה העניין?",
        nvFieldName: "",
        nvType: "text"
    },
    {
        nvQuestion: "אוקי, למי נחזור עם תשובה? מה כתובת המייל שלך?",
        nvFieldName: "מה כתובת המייל שלך?",
        nvType: "text"
    }
]
const freeTextToMail = {
    nvTextBtn: "משהו אחר"
    , nvActionName: ["setAvoidEnter", "startFillForm"]
    , dataParams: [true]
    , lstPages: [PAGES_ID.all]
    , iSubmitBtn: 61
    , userForm: userGlobalForm
}
const userBroadcastMessage = [
    {
        nvQuestion: "השם שלך: (האמיתי או הכינוי במשחק, זה לא משנה)",
        nvFieldName: ""
    },
    {
        nvQuestion: "וההודעה שלך:",
        nvFieldName: ""
    }
]

export const ROBOT_RESPONSE_DATA = {
    response_1: {
        nvMessage: ["היי אני דני, הבוט של מלחמת מוחות אונליין. <br> אני פה כדי לענות על שאלות טכניות וליצור קשר עם הצוות באולפן.",
            "כחובב טריוויה, אציג לך כאן בחלונית הצ’אט מספר אופציות שונות, <br> לפי הבחירות שלך - נתקדם הלאה. שנתחיל?"],
        nvTextBtn: null,
        nvActionName: null,
        iLstBtnPtr: 11,
        lstPages: [PAGES_ID.all],
        userForm: null,
        dataParams: null,
        iSubmitBtn: 0,
        nvTitleForm: "",
        nvTextToSend: null,
        bResetText: false,
        nvActionSubmit: null

    },
    response_empty: {
        nvMessage: null,
        nvTextBtn: "",
        nvActionName: "",
        iLstBtnPtr: 0,
        lstPages: [PAGES_ID.all],
        userForm: null,
        iSubmitBtn: 0,
        dataParams: null,
        nvTitleForm: "",
        nvTextToSend: null,
        bResetText: false,
        nvActionSubmit: null
    },
    resetPassord:
    {
        nvMessage: ["שלחתי.<br>זה בתיבת המייל שלך עם הוראות. איפוס נעים:)"],
        iLstBtnPtr: 37,
        lstPages: [PAGES_ID.all],
        userForm: null
    }
    ,
    wellcome:
    {
        nvMessage: [`כיף שהצטרפת! מחכים לראות אותך במשחק הבא.<br>
        נעדכן כמובן...`,
            "תודה על המשוב"
        ],
        iLstBtnPtr: 14,
        lstPages: [PAGES_ID.all],
        userForm: null
    }
    ,
    1: [
        {
            nvMessage: ["במה אני יכול לעזור?"]
            , nvTextBtn: "עזרה טכנית"
            , nvActionName: "resetMessage"
            , dataParams: "technicalSupport"
            , iLstBtnPtr: 2
            , lstPages: [PAGES_ID.all]
        },
        {
            nvMessage: null
            , nvActionName: "resetMessage"
            , dataParams: "broadcastMessage"
            , nvTextBtn: "שליחת הודעה לשידור"
            , iLstBtnPtr: 4
            , lstPages: [PAGES_ID.ClickerComponent]
        },
        {
            nvMessage: ["אחרי משחק או תוך כדי?"]
            , nvActionName: "resetMessage"
            , dataParams: "feedback"
            , nvTextBtn: "פידבק ומשוב"
            , iLstBtnPtr: 3
            , lstPages: [PAGES_ID.all]
        },
        {
            nvMessage: ["נשמח לשחק אתכם!, <br> איזה סוג משחק מתאים לכם?"]
            , nvActionName: "resetMessage"
            , nvTextBtn: "הזמנת משחק"
            , dataParams: "orderGame"
            , iLstBtnPtr: 5
            , lstPages: [PAGES_ID.all]
        }

    ],
    2: [

        {
            nvMessage: ["מה הבעיה?"]
            , nvActionName: null
            , nvTextBtn: "בעיה עם המייל"
            , iLstBtnPtr: 32
            , lstPages: [PAGES_ID.LoginComponent, PAGES_ID.LoginEmailComponent, PAGES_ID.ResetAndVerifyComponent]
        }
        , {
            nvMessage: ["מה הבעיה?"]
            , nvActionName: null
            , nvTextBtn: "בעיה עם הסיסמה"
            , iLstBtnPtr: 35
            , lstPages: [PAGES_ID.LoginComponent, PAGES_ID.LoginEmailComponent, PAGES_ID.ResetAndVerifyComponent]
        }
        , {
            nvMessage: ["מה הבעיה?"]
            , nvActionName: null
            , nvTextBtn: "בעית התחברות כללית"
            , iLstBtnPtr: 40
            , lstPages: [PAGES_ID.LoginComponent, PAGES_ID.LoginEmailComponent, PAGES_ID.ResetAndVerifyComponent]
        },
        /////////////////////////////////////////
        {
            nvMessage: ["מה הבעיה איתו?"]
            , nvActionName: null
            , nvTextBtn: "קוד המשחק"
            , iLstBtnPtr: 42
            , lstPages: [PAGES_ID.GameNavigationComponent]
        },
        //////////////////////////////////////////
        {
            nvMessage: ["מה עם זה?"]
            , nvActionName: null
            , nvTextBtn: "כינוי אישי"
            , iLstBtnPtr: 43
            , lstPages: [PAGES_ID.RegisterGameComponent]
        },
        {
            nvMessage: ["מה עם זה?"]
            , nvActionName: null
            , nvTextBtn: "קבוצות"
            , iLstBtnPtr: 45
            , lstPages: [PAGES_ID.RegisterGameComponent]
        }
        , {
            nvMessage: ["מה עם זה?"]
            , nvActionName: null
            , nvTextBtn: "המשחק"
            , iLstBtnPtr: 46
            , lstPages: [PAGES_ID.RegisterGameComponent]
        },
        ////////////////////////////////////////////
        {
            nvTextBtn: "ווידאו ושמע"
            , nvMessage: ["מה בדיוק הבעיה?"]
            , nvActionName: null
            , iLstBtnPtr: 6
            , lstPages: [PAGES_ID.ClickerComponent]
        }
        , {
            nvMessage: ["מה בדיוק הבעיה?"]
            , nvActionName: null
            , nvTextBtn: "שאלות ותשובות"
            , iLstBtnPtr: 9
            , lstPages: [PAGES_ID.ClickerComponent]
        }
        , {
            nvMessage: ["מה הבעיה?"]
            , nvActionName: null
            , nvTextBtn: "ניקוד וכינוי אישי"
            , iLstBtnPtr: 60
            , lstPages: [PAGES_ID.ClickerComponent]
        },
        freeTextToMail
        // , {
        //     nvMessage: ["???"]
        //     , nvActionName: null
        //     , nvTextBtn: "משהו אחר"
        //     , iLstBtnPtr: 0
        //     , lstPages: [PAGES_ID.all]
        // }
    ],
    3: [
        {
            nvMessage: ["אני ממליץ להתרכז משחק, ובסיומו לכתוב לי משוב.<br>אם ישנה בעיה אות תקלה, אשמח לעזור בכל שאלה."]
            , nvTextBtn: "תוך כדי משחק"
            , nvActionName: null
            , iLstBtnPtr: 31
            , lstPages: [PAGES_ID.all]
        },
        {
            nvTextBtn: "כבר סיימנו"
            , nvMessage: [
                "יופי, מקווה שנהנית במשחק וצברת מלא נקודות!<br>אשמח להתרשמות שלך. אני מעביר אותה לחזקי ולשאר הצוות."
            ]
            , nvActionName: null
            , iLstBtnPtr: 19
            , lstPages: [PAGES_ID.all]
        }
    ],
    4: [
        {
            nvMessage: null
            , nvActionName: ["setColor", "startFillForm"]
            , dataParams: 'yellow'
            , iSubmitBtn: 18
            , nvTextBtn: 'ד"ש או מסר למשתתפים'
            // , nvTitleForm: 'מסירת ד"ש או מסר למשתתפים'
            // , nvActionName: 'setColor'

            , userForm: userBroadcastMessage
            , iLstBtnPtr: 0
            , lstPages: [PAGES_ID.all]

        },
        {
            nvMessage: null
            , nvActionName: ["setMessageType", "setColor", "startFillForm"]
            , dataParams: ["broadcastOratorMessage", 'blue']
            , nvTextBtn: "הודעה למנחה"
            , iSubmitBtn: 18
            // , nvTitleForm: 'מסירת הודעה למנחה'
            , userForm: userBroadcastMessage
            , iLstBtnPtr: 0
            , lstPages: [PAGES_ID.all]
        },
    ],
    5: [
        {
            nvMessage: null
            , nvActionName: "startFillForm"
            , iSubmitBtn: 15
            , nvTitleForm: "הזמנת משחק אונליין"
            , nvTextBtn: "משחק אונליין"
            , iLstBtnPtr: 0
            , lstPages: [PAGES_ID.all]
            , userForm: userDetailForm
        },
        {
            nvMessage: null
            , nvActionName: "startFillForm"
            , iSubmitBtn: 15
            , nvTitleForm: "הזמנת משחק טריוויה עם קליקרים"
            , nvTextBtn: "טריוויה עם קליקרים"
            , iLstBtnPtr: 0
            , lstPages: [PAGES_ID.all]
            , userForm: userDetailForm
        },
        {
            nvMessage: ["טריוויה בהפעלה עצמית זה חידון המורכב משאלות המותאמות בשבילכם,<br>ערכה של מחשב ו50 קליקרים במשלוח או באיסוף עצמי ממס’ ערים.<br>כרגע, משחקי האונליין מופעל רק עם מנחה של מלחמת מוחות."]
            , nvActionName: null
            , nvTextBtn: "משחק בהפעלה עצמית"
            , iLstBtnPtr: 16
            , lstPages: [PAGES_ID.all]
        },
    ],
    6: [
        {
            nvMessage: [`בחלונית הוידאו, בשמאל למטה יש לחצן שמע.<br>
            מה שצריך לעשות: להגביה את הווליום עד הסוף.<br>
            <br>
            בכל רענון או הפעלה מחדש של הוידאו - לזכור להפעיל את השמע.`]
            , nvTextBtn: "לא שומעים"
            , nvActionName: null
            , iLstBtnPtr: 13
            , lstPages: [PAGES_ID.ClickerComponent]
        }
        , {
            nvMessage: [`אוו אוי.<br>
            המשחק מורכב מוידאו בחלק העליון, וטקסט חי בחלק התחתון.<br>
            מה מופיע אצלך?`]
            , nvActionName: null
            , nvTextBtn: "לא רואים"
            , iLstBtnPtr: 36
            , lstPages: [PAGES_ID.ClickerComponent]
        }
        , {
            nvMessage: ["בחלונית הוידאו, מימין למטה יש גלגל הגדרות.<br>אפשר להעלות שם את איכות הוידאו.",
                "אם הוידאו עדיין מטושטש, כנראה שקליטת האינטרנט אצלך חלשה. <br>אני ממליץ לחפש מיקום עם חיבור אינטרנטי חזק יותר לזמן המשחק. "]
            , nvActionName: null
            , nvTextBtn: "וידאו מטושטש"
            , iLstBtnPtr: 13
            , lstPages: [PAGES_ID.ClickerComponent]
        }
        , {
            nvMessage: [`אפשר לצמצם את הדילי בכמה דרכים:<br><br>

            1. בלחיצה כפולה על הכפתור העגול, בימין למעלה<br>
             (זה שיש בו תמונה של קליקר - שלט).
             <br><br>
            2. בשינוי הגדרות הוידאו, ע”י לחיצה על גלגל השיניים בחלנות הוידאו
            שמאל למטה.
            <br><br>
            3. גלישה בחיבור אינטרנט חזק יותר (קווי או מיקום עם קליטה חזקה יותר).`]
            , nvActionName: null
            , nvTextBtn: "דיליי בשידור"
            , iLstBtnPtr: 50
            , lstPages: [PAGES_ID.ClickerComponent]
        },freeTextToMail
    ],
    36: [
        {
            nvMessage: ["בחלק העליון (של הוידאו) מה מופיע?"]
            , nvTextBtn: "רק את השאלות והתשובות"
            , nvActionName: null
            , iLstBtnPtr: 7
            , lstPages: [PAGES_ID.ClickerComponent]
        }
        , {
            nvMessage: ["זה כנראה איטיות בטעינת העמוד, צריך לחכות בסבלנות"]
            , nvActionName: null
            , nvTextBtn: "כלום, מסך סגול"
            , iLstBtnPtr: 13
            , lstPages: [PAGES_ID.ClickerComponent]
        }
    ],
    7: [
        {
            nvTextBtn: "ריבוע שחור, כיתוב באנגלית"
            , nvMessage: ["זו תקלה אצלינו במערכת.<br>שלחתי דיווח. מקווה שנסדר את זה בקרוב."]
            , nvActionName: ["setColor", "sendBugReport"]
            , dataParams: ["red", { nvMessage: "לא רואים, רואים רק שאלות ותשובות<br> איפה שהווידאו- רואים ריבוע שחור, כיתוב באנגלית", bEmail: true }]
            , iLstBtnPtr: 13
            , lstPages: [PAGES_ID.ClickerComponent]
        }
        , {
            nvMessage: ["יתכן שיש לך סינון על האינטרנט?"]
            , nvActionName: null
            , nvTextBtn: "ריבוע שחור, בלי כיתוב"
            , iLstBtnPtr: 8
            , lstPages: [PAGES_ID.ClickerComponent]
        }
        , {
            nvMessage: ["(למעלה בימין מופיע עיגול ובתוכו תמונה של קליקר (שלט.<br>לחיצה עליו והוידאו יופיע."]
            , nvActionName: null
            , nvTextBtn: "אין פה ריבוע שחור"
            , iLstBtnPtr: 49
            , lstPages: [PAGES_ID.ClickerComponent]
        },freeTextToMail


    ],
    8: [{
        nvMessage: []
        , nvActionName: "startFillForm"
        , nvTitleForm: "רואים רק ריבוע שחור, בלי כיתוב, יש סינון"
        , nvTextBtn: "כן"
        // , iSubmitBtn: 48
        , iLstBtnPtr: 0
        , nvActionSubmit: "sendReport"
        , submitParams: { nvTitle: "שם ספק סינון-", nvNextBtn: "48" }
        , lstPages: [PAGES_ID.ClickerComponent]
        , userForm: [{ nvQuestion: "של איזה ספק?", nvFieldName: "שם ספק הסינון" }]
    },
    {
        nvMessage: ["שלחתי דיווח. מקווה שנסדר את זה בקרוב."]
        , nvActionName: "sendBugReport"
        , dataParams: { nvMessage: "רואים רק ריבוע שחור, בלי כיתוב, אין סינון" }
        , nvTextBtn: "לא"
        , iLstBtnPtr: 13
        , lstPages: [PAGES_ID.ClickerComponent]

    }
    ],
    9: [
        {
            nvMessage: ["למה?"]
            , nvActionName: null
            , nvTextBtn: "אי אפשר לענות תשובה"
            , iLstBtnPtr: 10
            , lstPages: [PAGES_ID.ClickerComponent]
        },
        {
            nvMessage: ["אי אפשר.<br>אחרי שלחצת על תשובה, אין אפשרות לשנות."]
            , nvActionName: null
            , nvTextBtn: "אני רוצה לשנות תשובה"
            , iLstBtnPtr: 13
            , lstPages: [PAGES_ID.ClickerComponent]
        },
        {
            nvMessage: ["מה הטעות?"]
            , nvActionName: ["startFillForm", "setAvoidEnter"]
            , dataParams: [, true]
            , nvTextBtn: "יש לכם טעות בשאלה/תשובות!"
            , bResetText: true
            , nvTitleForm: "יש טעות בשאלה/תשובות"
            // , iSubmitBtn: 55
            // , iLstBtnPtr: 0
            , nvActionSubmit: "sendReport"
            , submitParams: { nvNextBtn: "55", nvTitle: "" }
            , lstPages: [PAGES_ID.all]
            , userForm: [{ nvQuestion: "נשמח לתשובה מפורטת:", nvFieldName: "פירוט הטעות" }]
        },freeTextToMail
    ],
    10: [
        {
            nvMessage: [`יש דיליי בין השידור לשאלות בזמן אמת.<br>הוידאו מופיע מספר שניות אחרי שההצבעה נסגרת<br>
            ולכן זה נראה שיש תקלה בהצבעה.`]
            , nvActionName: null
            , nvTextBtn: "ההצבעה סגורה"
            , iLstBtnPtr: 56
            , lstPages: [PAGES_ID.ClickerComponent]
        },
        {
            nvMessage: ["יתכן שההצבעה סגורה?"]
            , nvActionName: null
            , nvTextBtn: "לא נלחץ"
            , iLstBtnPtr: 59
            , lstPages: [PAGES_ID.all]
        },
        {
            nvMessage: ["תשובות עונים בחלקו התחתון של המסך ולא בלחיצה על הוידאו."]
            , nvActionName: null
            , nvTextBtn: "הווידאו נעצר"
            , iLstBtnPtr: 13
            , lstPages: [PAGES_ID.ClickerComponent]
        },freeTextToMail
    ],
    11: [
        {
            nvMessage: ["אז, לחיצה לבחירת הנושא <br> ונראה מה קורה עם זה :"]
            , nvTextBtn: "הבנתי, קדימה"
            , nvActionName: null
            , iLstBtnPtr: 1
            , lstPages: [PAGES_ID.all]
        },
        {
            nvMessage: ["אמנם אני בוט, אבל אדם אמיתי תדרך אותי :) <br> אוכל לענות ולעזור לך, בדיוק כמו חבר צוות חי ונושם, ואפילו מהר יותר.",
                "שננסה בינתיים כאן בצ’אט?<br>אפשר להפסיק בכל שלב, אני לא אעלב."]
            , nvTextBtn: "אני רוצה עזרה מבנאדם אמיתי"
            , nvActionName: null
            , iLstBtnPtr: 12
            , lstPages: [PAGES_ID.all]
        },
    ],
    12: [
        {
            nvMessage: ["אז, לחיצה לבחירת הנושא <br> ונראה מה קורה עם זה :"]
            , nvTextBtn: "אוקי, בא ננסה"
            , nvActionName: null
            , iLstBtnPtr: 1
            , lstPages: [PAGES_ID.all]
        },
        {
            nvMessage: [`אין בעיה. אפשר לפנות לצוות במייל x93132@gmail.com.<br>
            אני יכול גם לשלוח את המסר בשבילך לתיבת המייל של הצוות`]
            , nvTextBtn: "עדיף שלא"
            , nvActionName: "resetMessage"
            , dataParams: "technicalSupport"
            , lstPages: [PAGES_ID.all]
            , iLstBtnPtr: 62

        },
    ],
    62: [
        {
            nvMessage: ["אוקי. <br>לעוד שאלות - אני זמין בלחיצה."]
            , nvTextBtn: "תודה, אבל אשלח כבר בעצמי"
            , nvActionName: null
            , iLstBtnPtr: 14
            , lstPages: [PAGES_ID.all]
        },
        {
            // nvMessage: ["אוקי. <br>לעוד שאלות - אני זמין בלחיצה."]
            nvTextBtn: "כן, תשלח בשבילי את המסר"
            , nvActionName: ["setAvoidEnter", "startFillForm"]
            , dataParams: [true]
            , lstPages: [PAGES_ID.all]
            , iSubmitBtn: 61
            , userForm: userGlobalForm
        },
    ],
    13: [
        {
            nvMessage: ["בשמחה, בשביל מה אני כאן? :) <br>לעוד שאלות - אני זמין בלחיצה."]
            , nvTextBtn: "אוקי, תודה"
            , nvActionName: null
            , iLstBtnPtr: 14
            , lstPages: [PAGES_ID.all]
        },
    ],
    14: [
        {
            nvMessage: []
            , nvTextBtn: "סגירה"
            , nvActionName: "closeChat"
            , iLstBtnPtr: 0
            , lstPages: [PAGES_ID.all]
        },
        {
            nvMessage: ["בכיף,<br> באיזה נושא?"]
            , nvTextBtn: "יש לי עוד שאלה"
            , nvActionName: null
            , iLstBtnPtr: 1
            , lstPages: [PAGES_ID.all]
        }
    ],
    15: [
        {
            nvMessage: ["שלחתי את ההזמנה לצוות המשרד<br> בזמן הקרוב ייצרו איתך קשר מטעם מלחמת מוחות"],
            nvTextBtn: "שליחה",
            nvActionName: "sendMessage",
            iLstBtnPtr: 14,
            lstPages: [PAGES_ID.all],
            userForm: null
        }
    ],
    16: [
        {
            nvMessage: null,
            nvTextBtn: "אני רוצה להזמין",
            nvActionName: "startFillForm",
            nvTitleForm: "הזמנת משחק טריוויה הפעלה עצמית",
            iSubmitBtn: 15,
            iLstBtnPtr: 0,
            lstPages: [PAGES_ID.all],
            userForm: userDetailForm
        },
        {
            nvMessage: ["כרגע אין אופציה כזו.<br>אפשר להשאיר פרטים, וכשמשחקי אונליין בהפעלה עצמית יהיו זמינים -נשמח לעדכן אותך!"],
            nvTextBtn: "אני רוצה רק אונליין",
            nvActionName: null,
            iLstBtnPtr: 17,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: null
            , nvTextBtn: "אני רוצה אונליין עם הנחייה של מלחמת מוחות"
            , nvActionName: "startFillForm"
            , iSubmitBtn: 15
            , nvTitleForm: "הזמנת משחק אונליין"
            , iLstBtnPtr: 0
            , lstPages: [PAGES_ID.all]
            , userForm: userDetailForm
        },
        {
            nvMessage: ["אוקי.<br>לעוד פרטים על הפעלה עצמית בליווי קליקרים - אני כאן."],
            nvTextBtn: "אז לא, תודה",
            nvActionName: null,
            iLstBtnPtr: 14,
            lstPages: [PAGES_ID.all],
            userForm: null
        }
    ],
    17: [
        {
            nvMessage: ["אוקי.<br>לעוד פרטים על הפעלה עצמית בליווי קליקרים - אני כאן."],
            nvTextBtn: "לא, תודה",
            nvActionName: null,
            iLstBtnPtr: 14,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["אין בעיה"],
            nvTextBtn: "כן, תעדכנו אותי",
            nvActionName: "startFillForm",
            nvTitleForm: "רוצה להתעדכן כשהפעלה עצמית אונליין תהיה פעילה",
            iSubmitBtn: 20,
            iLstBtnPtr: 0,
            lstPages: [PAGES_ID.all],
            userForm: user2DetailForm
        }
    ],
    18: [
        {
            nvMessage: ["שלחתי את ההודעה אל האולפן",
                "קיבלתי מלא הודעות להעביר לאולפן,<br>מקווה שאת ההודעה שלך חזקי יקריא בשידור."
            ],
            nvTextBtn: "שליחה",
            nvActionName: "userBroadcastMessage",
            iLstBtnPtr: 14,
            lstPages: [PAGES_ID.all],
            userForm: null
        }
    ],
    19: [
        {

            nvTextBtn: "היה נהדר!",
            nvActionName: ["setColor", "setAvoidEnter", "startFillForm"],
            dataParams: ["green", true],
            nvTextToSend: "התרשמות כללית: היה נהדר!",
            // iLstBtnPtr: 21,
            lstPages: [PAGES_ID.all],
            bResetText: true,
            nvActionSubmit: "freeTypeFeedback",
            submitParams: "good_feedback",
            userForm: [{ nvQuestion: "נשמח לשמוע התרשמות במילים שלך", nvFieldName: "" }],
        },
        {

            nvTextBtn: "צריך שיפור",
            nvActionName: ["setColor", "setAvoidEnter", "startFillForm"],
            dataParams: ["red", true],
            nvTextToSend: "התרשמות כללית: צריך שיפור!",
            // iLstBtnPtr: 27,
            bResetText: true,
            lstPages: [PAGES_ID.all],
            nvActionSubmit: "freeTypeFeedback",
            submitParams: "bad_feedback",
            userForm: [{ nvQuestion: "נשמח לשמוע התרשמות במילים שלך", nvFieldName: "" }],
        }
    ],
    good_feedback:
    {
        nvMessage: ["תודה רבה! כיף לקבל כזה פידבק :)<br>מחמם את הלב",
            "כדי ששאר המשחקים שלנו יהיו כאלו מוצלחים וכולם יהנו,<br>אשמח לדירוג שלך:<br>איך היו איכות הוידאו והשמע?"],
        iLstBtnPtr: 21,
        lstPages: [PAGES_ID.all],
    },
    bad_feedback:
    {
        nvMessage: ["):",
            "כדי שהמשחקים הבאים יהיו מוצלחים יותר,<br>חשוב לנו הדירוג שלך:<br>איך היו איכות הוידאו והשמע?"
        ],
        iLstBtnPtr: 27,
        lstPages: [PAGES_ID.all],
    },
    20: [
        {
            nvMessage: ["שמרתי את הפרטים.<br>אעדכן אותך כשהפעלה עצמית במשחק אונליין תהיה פעילה."],
            nvTextBtn: "שליחה",
            nvActionName: "sendMessage",
            iLstBtnPtr: 14,
            lstPages: [PAGES_ID.all],
            userForm: null
        }
    ],
    21: [
        {
            nvMessage: ["מה לגבי רמת השאלות?"],
            nvTextBtn: "ווידאו רצוף ושמע איכותי",
            nvActionName: null,
            nvTextToSend: "איכות הוידאו והשמע: ווידאו רצוף ושמע איכותי",
            iLstBtnPtr: 22,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["מה לגבי רמת השאלות?"],
            nvTextBtn: "תקלות בהתחלה, אח”כ הסתדר",
            nvActionName: null,
            nvTextToSend: "איכות הוידאו והשמע: תקלות בהתחלה, אח”כ הסתדר",
            iLstBtnPtr: 22,
            lstPages: [PAGES_ID.all],
            userForm: null
        }
    ],
    22: [
        {
            nvMessage: ["כמה זמן היה המשחק?"],
            nvTextBtn: "קשה מדי",
            nvActionName: null,
            nvTextToSend: "רמת השאלות: קשה מדי",
            iLstBtnPtr: 23,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["כמה זמן היה המשחק?"],
            nvTextBtn: "בדיוק כמו שצריך",
            nvActionName: null,
            nvTextToSend: "רמת השאלות: בדיוק כמו שצריך",
            iLstBtnPtr: 23,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["כמה זמן היה המשחק?"],
            nvTextBtn: "קלה מדי",
            nvActionName: null,
            nvTextToSend: "רמת השאלות: קלה מדי",
            iLstBtnPtr: 23,
            lstPages: [PAGES_ID.all],
            userForm: null
        }
    ],
    23: [
        {
            nvMessage: ["וזה הרגיש:"],
            nvTextBtn: "חצי שעה",
            nvActionName: null,
            nvTextToSend: "משך המשחק: חצי שעה",
            iLstBtnPtr: 24,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["וזה הרגיש:"],
            nvTextBtn: "45 דקות",
            nvActionName: null,
            nvTextToSend: "משך המשחק: 45 דקות",
            iLstBtnPtr: 24,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["וזה הרגיש:"],
            nvTextBtn: "שעה",
            nvActionName: null,
            nvTextToSend: "משך המשחק: שעה",
            iLstBtnPtr: 24,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["וזה הרגיש:"],
            nvTextBtn: "מעל שעה",
            nvActionName: null,
            nvTextToSend: "משך המשחק: מעל שעה",
            iLstBtnPtr: 24,
            lstPages: [PAGES_ID.all],
            userForm: null
        }
    ],
    24: [
        {
            nvMessage: ["תודה! אנחנו מתייחסים ברצינות לכל משוב<br>לומדים ומשתפרים בכל פעם :)",
                "לפני שניפרד,<br>לשלוח אליך עדכונים על משחקי טריוויה חינמיים?"
            ],
            nvTextBtn: "ארוך מדי",
            nvActionName: null,
            nvTextToSend: "וזה הרגיש: ארוך מדי",
            iLstBtnPtr: 25,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["תודה! אנחנו מתייחסים ברצינות לכל משוב<br>לומדים ומשתפרים בכל פעם :)",
                "לפני שניפרד,<br>לשלוח אליך עדכונים על משחקי טריוויה חינמיים?"
            ],
            nvTextBtn: "לא צריך יותר",
            nvActionName: null,
            nvTextToSend: "וזה הרגיש: לא צריך יותר",
            iLstBtnPtr: 25,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["תודה! אנחנו מתייחסים ברצינות לכל משוב<br>לומדים ומשתפרים בכל פעם :)",
                "לפני שניפרד,<br>לשלוח אליך עדכונים על משחקי טריוויה חינמיים?"
            ],
            nvTextBtn: "קצר, הזמן טס",
            nvActionName: null,
            nvTextToSend: "וזה הרגיש: קצר, הזמן טס",
            iLstBtnPtr: 25,
            lstPages: [PAGES_ID.all],
            userForm: null
        }
    ],
    25: [
        {
            nvMessage: [`משחקי טריוויה חינמים הפתוחים לכולם.<br><br>
            אנחנו מנסים כל מיני פיצרים חדשים<br>
            ומארגנים משחק מאתגר עם מלא שחקנים, כדי לבדוק שזה עובד.
            <br><br>
            ההשתתפות חינם, המשחקים ספונטנים ללא זמן קבוע <br>
            (ולפעמים יש גם פרסים שווים)`
            ],
            nvTextBtn: "מה זה?",
            nvActionName: null,
            iLstBtnPtr: 26,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: [`היה כיף לשחק איתך!<br>
            מחכים לראות אותך במשחק הבא.<br>
            נעדכן כמובן...`,
                "תודה על המשוב"
            ],
            nvTextBtn: "אני כבר שם :)",
            nvTextToSend: "הצטרפות לעידכונים: אני כבר שם :)",
            nvActionName: "sendMessage",
            iLstBtnPtr: 14,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: [`אוקי. אז סיימנו?<br>
            תודה על המשוב!`
            ],
            nvTextBtn: "לא, תודה",
            nvTextToSend: "הצטרפות לעידכונים: לא , תודה",
            nvActionName: "sendMessage",
            iLstBtnPtr: 14,
            lstPages: [PAGES_ID.all],
            userForm: null
        }
    ],
    26: [
        {

            nvMessage: [],
            nvTextBtn: "להרשמה לעידכונים במייל",
            // nvTitleForm: "הצטרפות לעדכונים במייל",
            nvActionName: "startFillForm",
            nvActionSubmit: "registerEmail",
            userForm: [{ nvQuestion: "איזה מייל לעדכן?", nvFieldName: "מייל להצטרפות לעדכונים" }],
            // iLstBtnPtr: 14,
            lstPages: [PAGES_ID.all],

        },
        // <a class="embeds-link text-blue" href="https://did.li/XioCN">להרשמה לווצאפ לחץ כאן</a><br>
        {
            nvMessage: [`<a class="embeds-link text-blue" href="https://did.li/XioCN">להרשמה לווצאפ לחץ כאן</a><br>
            כיף שהצטרפת! מחכים לראות אותך במשחק הבא.<br>
            נעדכן כמובן...`,
                "תודה על המשוב"
            ],
            nvTextBtn: "לקבוצת הווצאפ",
            // nvTextToSend: "הצטרפות לעידכונים: הרשמה לקבוצת הווצאפ",
            // nvActionName: "sendMessage",
            iLstBtnPtr: 14,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: [`אוקי. אז סיימנו?<br>
            תודה על המשוב!`
            ],
            nvTextBtn: "לא, תודה",
            // nvTextToSend: "הצטרפות לעידכונים: לא , תודה",
            nvActionName: "sendMessage",
            iLstBtnPtr: 14,
            lstPages: [PAGES_ID.all],
            userForm: null
        }
    ],
    27: [
        {
            nvMessage: ["דיליי של כמה שניות (בערך)?"],
            nvTextBtn: "היה דיליי רציני",
            nvActionName: null,
            nvTextToSend: "איכות הוידאו והשמע: היה דיליי רציני",
            iLstBtnPtr: 28,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["דיליי של כמה שניות (בערך)?"],
            nvTextBtn: "וידאו קטוע ובאיכות ירודה",
            nvActionName: null,
            nvTextToSend: "איכות הוידאו והשמע: וידאו קטוע ובאיכות ירודה",
            iLstBtnPtr: 28,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["איך שיחקת?"],
            nvTextBtn: "הוידיאו היה מצויין",
            nvActionName: null,
            nvTextToSend: "איכות הוידאו והשמע: מצויין",
            iLstBtnPtr: 30,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
    ],
    28: [
        {
            nvMessage: ["ניסת לרענן את הדף במהלך המשחק או לחפש עזרה טכנית?"],
            nvTextBtn: "בין 10-15 שניות",
            nvActionName: null,
            nvTextToSend: "אורך הדיליי: בין 10-15 שניות",
            iLstBtnPtr: 29,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["ניסת לרענן את הדף במהלך המשחק או לחפש עזרה טכנית?"],
            nvTextBtn: "סביבות 30 שניות",
            nvActionName: null,
            nvTextToSend: "אורך הדיליי: סביבות 30 שניות",
            iLstBtnPtr: 29,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["ניסת לרענן את הדף במהלך המשחק או לחפש עזרה טכנית?"],
            nvTextBtn: "למעלה מדקה",
            nvActionName: null,
            nvTextToSend: "אורך הדיליי: למעלה מדקה",
            iLstBtnPtr: 29,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
    ],
    29: [
        {
            nvMessage: ["איך שיחקת?"],
            nvTextBtn: "כן, כלום לא עזר",
            nvActionName: null,
            nvTextToSend: "ניסית לרענן או לחפש עזרה טכנית: כן, כלום לא עזר",
            iLstBtnPtr: 30,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["איך שיחקת?"],
            nvTextBtn: "כן, עזר לבסוף",
            nvActionName: null,
            nvTextToSend: "ניסית לרענן או לחפש עזרה טכנית: כן, עזר לבסוף",
            iLstBtnPtr: 30,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["חבל, אני כאן הייתי זמין אליך.<br>איך שיחקת?"],
            nvTextBtn: "לא",
            nvActionName: null,
            nvTextToSend: "ניסית לרענן או לחפש עזרה טכנית: לא",
            iLstBtnPtr: 30,
            lstPages: [PAGES_ID.all],
            userForm: null
        }
    ],
    30: [
        {
            nvMessage: ["הבנתי. מעביר את הנתונים<br>נבדוק את זה לעומק אצלינו.",
                "מה לגבי רמת השאלות?"
            ],
            nvTextBtn: "במחשב",
            nvActionName: null,
            nvTextToSend: "דרך התחברות: במחשב",
            iLstBtnPtr: 22,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["הבנתי. מעביר את הנתונים<br>נבדוק את זה לעומק אצלינו.",
                "מה לגבי רמת השאלות?"
            ],
            nvTextBtn: "דפדפן בסמארטפון",
            nvActionName: null,
            nvTextToSend: "דרך התחברות: דפדפן בסמארטפון",
            iLstBtnPtr: 22,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["הבנתי. מעביר את הנתונים<br>נבדוק את זה לעומק אצלינו.",
                "מה לגבי רמת השאלות?"
            ],
            nvTextBtn: "באפליקציה",
            nvActionName: null,
            nvTextToSend: "דרך התחברות: באפליקציה",
            iLstBtnPtr: 22,
            lstPages: [PAGES_ID.all],
            userForm: null
        },

    ],
    31: [
        {
            nvMessage: ["במה אני יכול לעזור?"],
            nvTextBtn: "עזרה טכנית",
            nvActionName: "resetMessage",
            dataParams: "technicalSupport",
            iLstBtnPtr: 2,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["יופי!<br>אשמח להתרשמות שלך. אני מעביר אותה לחזקי ולשאר הצוות."],
            nvTextBtn: "שליחת משוב בכל אופן",
            nvActionName: null,
            iLstBtnPtr: 19,
            lstPages: [PAGES_ID.all],
            userForm: null
        }

    ],
    32: [
        {
            nvMessage: ["כן, לפעמים זה אפשרי בקישור מיוחד.<br>כדאי לבדוק בהודעת העדכון שקיבלת על המשחק אם יש שם קישור נוסף."],
            nvTextBtn: "אפשר להכנס בלי מייל?",
            nvActionName: null,
            iLstBtnPtr: 14,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["צריך לבדוק שכתבת אותה כמו שצריך, ללא רווח או תווים בעברית.<br>אולי  שכחת @?"],
            nvTextBtn: "כתובת המייל שגויה",
            nvActionName: null,
            iLstBtnPtr: 33,
            lstPages: [PAGES_ID.all],
            userForm: null
        },freeTextToMail
    ],
    33: [
        {
            nvMessage: ["שמח שהסתדרת.<br>לעוד שאלות - אני זמין פה :)"],
            nvTextBtn: "הסתדרתי, תודה",
            nvActionName: null,
            iLstBtnPtr: 14,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["אממ...<br>אולי נרשמת עם כתובת מייל אחרת?"],
            nvTextBtn: "לא, זה לא זה",
            nvActionName: null,
            iLstBtnPtr: 34,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
    ]
    //todo-חסר תגובה
    , 34: [
        {
            nvMessage: ["אז כדאי לנסות להתחבר עם כתובת המייל הנוספת.<br>אם זה לא עוזר, לחיצה על כניסה דרך גוגל, וזהו."],
            nvTextBtn: "אולי",
            nvActionName: null,
            iLstBtnPtr: 14,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["אוקי.<br>ליתר ביטחון- אשלח אליך קישור לאיפוס סיסמה.<br>זה בהחלט עשוי לעזור"],
            nvTextBtn: "אין מצב",
            nvActionName: "startFillForm",
            nvActionSubmit: "sendMailResetPassword",
            iLstBtnPtr: 0,
            bResetText: true,
            lstPages: [PAGES_ID.all],
            userForm: [{ nvQuestion: "לאיזה כתובת מייל?" }]
        },
    ]
    , 35: [
        {
            nvMessage: ["לא נורא, זה קורה לכולם.<br>אשלח אליך קישור לאיפוס סיסמה."],
            nvTextBtn: "שכחתי סיסמה",
            nvActionName: "startFillForm",
            userForm: [{ nvQuestion: "לאיזה כתובת מייל?" }],
            nvActionSubmit: "sendMailResetPassword",
            iLstBtnPtr: 0,
            bResetText: true,
            lstPages: [PAGES_ID.all],

        },
        {
            nvMessage: ["כנראה שכבר שיחקת איתנו פעם דרך כניסה מהירה.<br>הסיסמה שלך היא התחלת המייל. כל מה שנמצא לפני ה @."],
            nvTextBtn: "אין לי מושג מה הסיסמה שלי",
            nvActionName: null,
            iLstBtnPtr: 39,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
    ]
    , 37: [
        {
            nvMessage: ["שמח שהסתדרת.<br>לעוד שאלות - אני זמין פה :)"],
            nvTextBtn: "הסתדרתי, תודה",
            nvActionName: null,
            iLstBtnPtr: 14,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["לפני שאשלח שוב:<br>בדיקה בתיבת הספאם. אולי המייל נכנס לשם?"],
            nvTextBtn: "לא קיבלתי מייל לאיפוס",
            nvActionName: null,
            iLstBtnPtr: 38,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
    ]
    , 38: [
        {
            nvMessage: ["שמח שהסתדרת.<br>לעוד שאלות - אני זמין פה :)"],
            nvTextBtn: "מצאתי!",
            nvActionName: null,
            iLstBtnPtr: 14,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["אין בעיה. שולח שוב."],
            nvTextBtn: "לא, תשלח אלי שוב",
            nvActionName: "startFillForm",
            nvActionSubmit: "sendMailResetPassword",
            iLstBtnPtr: 0,
            bResetText: true,
            lstPages: [PAGES_ID.all],
            userForm: [{ nvQuestion: "חשוב להקפיד לכתוב את כתובת המייל בצורה תקינה." }]
        },
    ],
    39: [{
        nvMessage: ["שמח שהסתדרת.<br>לעוד שאלות - אני זמין פה :)"],
        nvTextBtn: "נכון, תודה",
        nvActionName: null,
        iLstBtnPtr: 14,
        lstPages: [PAGES_ID.all],
        userForm: null
    },
    {
        nvMessage: ["טוב. אז נאפס סיסמה."],
        nvTextBtn: "לא, זה לא זה",
        nvActionName: "startFillForm",
        nvActionSubmit: "sendMailResetPassword",
        iLstBtnPtr: 0,
        bResetText: true,
        lstPages: [PAGES_ID.all],
        userForm: [{ nvQuestion: "לאיזו כתובת מייל לשלוח קישור לאיפוס סיסמה?" }]
    }]
    ,
    //to do : חסרה תגובה
    40: [
        {
            nvMessage: ["מה קרה בדיוק?"],
            nvTextBtn: "לא מצליח להתחבר",
            nvActionName: null,
            iLstBtnPtr: 41,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: [`כל משחק מוגבל בכמות השחקנים.<br>
            אפשר לנסות בעוד כמה דקות, אולי מישהו יצא מהמשחק ותהיה לך אפשרות להכנס במקומו.`],
            nvTextBtn: "המשחק בתפוסה מלאה",
            nvActionName: null,
            iLstBtnPtr: 13,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["זה סימן שהמערכת באמצע לרשום אותך, קצת סבלנות וזה משתחרר<br>אם אחרי הרבה זמן המערכת לא מגיבה- נסה לרענן את הדף"]
            // ,nvActionName: "sendBugReport"
            // , dataParams:"הכפתורים לא לחיצים, במסך ההתחברות"
            , nvTextBtn: "הכפתורים לא לחיצים",
            iLstBtnPtr: 13,
            lstPages: [PAGES_ID.all],
            userForm: null
        },freeTextToMail
    ]
    ,
    //to do : חסרה תגובה
    41: [
        {
            nvMessage: ["שלחתי דיווח. הייתי ממליץ לך בנתיים לבדוק את תקינות החיבור שלך לרשת.<br>כדאי גם לבדוק אם שם המשתמש והסיסמה שהזנת מדויקים"]
            // ,nvActionName: "sendBugReport"
            // , dataParams:"כתוב ש: החיבור למערכת נכשל"
            , nvTextBtn: "כתוב ש: החיבור למערכת נכשל",
            iLstBtnPtr: 13,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["שלחתי דיווח. הייתי ממליץ לך בנתיים לבדוק את תקינות החיבור שלך לרשת."]
            // ,nvActionName: "sendBugReport"
            // , dataParams:"הכניסה דרך גוגל לא עובדת"
            , nvTextBtn: "הכניסה דרך גוגל לא עובדת",
            iLstBtnPtr: 14,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["נאפס סיסמה ,מקווה שזה יעזור."],
            nvTextBtn: "אין לי מושג, הסיסמה והמייל תקינים",
            nvActionName: "startFillForm",
            nvActionSubmit: "sendMailResetPassword",
            iLstBtnPtr: 0,
            bResetText: true,
            lstPages: [PAGES_ID.all],
            userForm: [{ nvQuestion: "לאיזה מייל לשלוח את הקישור?" }]
        },freeTextToMail
    ],
    //to do : חסרה תגובה
    42: [
        {
            nvMessage: ["בהודעת העדכון על המשחק, יש גם קוד משחק המורכב מ6 ספרות."]
            , nvActionName: null
            , nvTextBtn: "אין לי קוד משחק"
            , iLstBtnPtr: 13
            , lstPages: [PAGES_ID.GameNavigationComponent]
        }
        // , {
        //     nvMessage: ["???"]
        //     , nvActionName: null
        //     , nvTextBtn: "יש לי רק 6 ספרות ולא 7"
        //     , iLstBtnPtr: 13
        //     , lstPages: [PAGES_ID.GameNavigationComponent]
        // }
        , {
            nvMessage: ["שלחתי דיווח. הייתי ממליץ לך בנתיים לנסות להשיג את קוד המשחק."]
            , nvActionName: "sendBugReport"
            , dataParams: { nvMessage: "לא מצליח להכנס למשחק עם הקוד שברשותי" }
            , nvTextBtn: "לא מצליח להיכנס"
            , iLstBtnPtr: 13
            , lstPages: [PAGES_ID.GameNavigationComponent]
        }
        ,freeTextToMail
    ],
    43: [
        {
            nvMessage: ["אפשר לשלב רק אותיות בעברית ומספרים.<br>אולי יש בכינוי שלך תו מיוחד כמו גרש או סימון מיוחד?"]
            , nvActionName: null
            , nvTextBtn: "הכינוי בעברית ויש שגיאה"
            , iLstBtnPtr: 13
            , lstPages: [PAGES_ID.all]
        }
        , {
            nvMessage: [`יש לך טעם ממש טוב ובחרת שם מוצלח.<br>
            שחקן אחר תפס אותו במשחק הזה.<br>
            אולי במשחק הבא זה היה הכינוי שלך :)`]
            , nvActionName: null
            , nvTextBtn: "הכינוי שלי תפוס"
            , iLstBtnPtr: 44
            , lstPages: [PAGES_ID.all]
        }
        ,freeTextToMail
    ]
    , 44: [
        {
            nvMessage: ["בשמחה, בשביל מה אני כאן? :) <br>לעוד שאלות - אני זמין בלחיצה."]
            , nvTextBtn: "אוקי, תודה"
            , nvActionName: null
            , iLstBtnPtr: 14
            , lstPages: [PAGES_ID.all]
        },
        {
            nvMessage: [`כנראה התחברת מדפדפן או מכשיר אחר.<br>
            מה שצריך לעשות זה: להכנס מאיפה שנכנס בפעם הראשונה,<br>
            ללחוץ למעלה בשמאל על “יציאה מהמשחק”.
            ולהכנס שוב.`]
            , nvTextBtn: "התחברתי מקודם עם הכינוי הזה"
            , nvActionName: null
            , iLstBtnPtr: 14
            , lstPages: [PAGES_ID.all]
        }

    ]
    , 45: [
        {
            nvMessage: ["צריך לבחור קבוצה בכל חלוקה.<br>אם לא ראית את כולן, כנראה שכמה מהן מסתתרות ובגלילה הן יופיעו."]
            , nvTextBtn: "סימנתי קבוצה ואי אפשר להכנס"
            , nvActionName: null
            , iLstBtnPtr: 13
            , lstPages: [PAGES_ID.all]
        },
        {
            nvMessage: [`זה רק משחק.<br>
            אחרי שנסיים אף אחד לא יזכור לאיזו קבוצה השתייכת. (חוץ ממך, אולי)`]
            , nvTextBtn: "אני לא מזדהה עם החלוקות"
            , nvActionName: null
            , iLstBtnPtr: 13
            , lstPages: [PAGES_ID.all]
        }
        ,freeTextToMail
    ]
    //to do : חסרה תגובה
    , 46: [
        {
            nvMessage: ["נכנסת מאוחר מידי והמשחק כבר הסתיים. <br>בפעם הבאה כדאי להתחבר בשעה שנקבעה."]
            , nvTextBtn: "כתוב שהמחשחק הסתיים"
            , nvActionName: null
            , iLstBtnPtr: 47
            , lstPages: [PAGES_ID.all]
        },
        {
            nvMessage: [`כל משחק מוגבל בכמות השחקנים.<br>
            אפשר לנסות בעוד כמה דקות, אולי מישהו יצא מהמשחק ותהיה לך אפשרות להכנס במקומו.`],
            nvTextBtn: "המשחק בתפוסה מלאה",
            nvActionName: null,
            iLstBtnPtr: 13,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: [`זה סימן שהמערכת באמצע לרשום אותך, קצת סבלנות וזה משתחרר<br>אם אחרי הרבה זמן המערכת לא מגיבה- נסה לרענן את הדף
            <br>לא כדאי לרענן כל שניה כי זה רק מכביד יותר. וגם גורם שתגובתך לא תיקלט בצורה תקינה`]
            , nvTextBtn: "הכפתור לא לחיץ"
            , nvActionName: null
            , iLstBtnPtr: 13
            , lstPages: [PAGES_ID.all]
        },freeTextToMail
        ],
    //to do : חסרה תגובה
    47: [
        {
            nvMessage: ["בשמחה, בשביל מה אני כאן? :) <br>לעוד שאלות - אני זמין בלחיצה."]
            , nvTextBtn: "אוקי, תודה"
            , nvActionName: null
            , iLstBtnPtr: 13
            , lstPages: [PAGES_ID.all]
        },
        {
            nvMessage: ["שלחתי דיווח. מקווה שהעניין יתברר בקרוב."]
            , nvActionName: "sendBugReport"
            , dataParams: { nvMessage: "כתוב על המשחק שהסתיים, והוא אמור להתחיל עכשיו" }
            , nvTextBtn: "לא הגיוני, המשחק אמור להתחיל עכשיו"
            , iLstBtnPtr: 14
            , lstPages: [PAGES_ID.all]
        }
    ],
    48:
    {
        nvMessage: ["תודה!<br>שלחתי דיווח. מקווה שנסדר את זה בקרוב."],
        // nvTextBtn: "שליחה",
        // nvActionName: "sendMessage",
        iLstBtnPtr: 13,
        lstPages: [PAGES_ID.all],
        userForm: null
    }
    ,
    49: [
        {
            nvMessage: ["יופי!<br>את השמע מסדרים בחלונית הוידאו, בשמאל למטה. להגביה עד הסוף."],
            nvTextBtn: "עובד",
            iLstBtnPtr: 13,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["שלחתי דיווח. מקווה שנסדר את זה בקרוב.<br>אולי החלפת התקן השמע תעזור (אוזניות, רמקולים)"]
            , nvActionName: "sendBugReport"
            , dataParams: { nvMessage: "לא שומעים, ולא עוזר להגביה" },
            nvTextBtn: "לא עוזר",
            iLstBtnPtr: 13,
            lstPages: [PAGES_ID.all],
            userForm: null
        }
    ],
    50: [
        {
            nvMessage: ["בשמחה, בשביל מה אני כאן? :) <br>לעוד שאלות - אני זמין בלחיצה."],
            nvTextBtn: "אוקי, תודה",
            iLstBtnPtr: 14,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["דיליי של עד 7 שניות הוא נורמלי.<br>הדיליי אצלך גבוה יותר?"],
            nvTextBtn: "כלום לא עוזר",
            iLstBtnPtr: 51,
            lstPages: [PAGES_ID.all],
            userForm: null
        }
    ],
    51: [
        {
            nvMessage: ["דיליי של כמה שניות (בערך)?"],
            nvTextBtn: "כן",
            iLstBtnPtr: 52,
            bResetText: true,
            nvTextToSend: "יש דיליי בווידאו",
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["כדי לקבל שידור איכותי חייב להיות דילי , אנחנו עושים מאמצים לצמצם אותו כמה שיותר, אבל זה מה שמתאפשר כרגע<br> איך התחברת?"],
            nvTextBtn: "לא",
            iLstBtnPtr: 53,
            bResetText: true,
            nvTextToSend: "יש דיליי בווידאו, פחות מ7 שניות",
            lstPages: [PAGES_ID.all],
            userForm: null
        }
    ],
    52: [
        {
            nvMessage: ["איך התחברת?"],
            nvTextBtn: "בין 10-15 שניות",
            nvActionName: null,
            nvTextToSend: "אורך הדיליי: בין 10-15 שניות",
            iLstBtnPtr: 53,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["איך התחברת?"],
            nvTextBtn: "סביבות 30 שניות",
            nvActionName: null,
            nvTextToSend: "אורך הדיליי: סביבות 30 שניות",
            iLstBtnPtr: 53,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["איך התחברת?"],
            nvTextBtn: "למעלה מדקה",
            nvActionName: null,
            nvTextToSend: "אורך הדיליי: למעלה מדקה",
            iLstBtnPtr: 53,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
    ],
    53: [{
        nvMessage: ["הבנתי. מעביר את הנתונים<br>שלחתי דיווח. נבדוק את זה לעומק אצלינו."],
        nvTextBtn: "במחשב",
        nvActionName: "sendMessage",
        nvTextToSend: "דרך התחברות: במחשב",
        iLstBtnPtr: 13,
        lstPages: [PAGES_ID.all],
        userForm: null
    },
    {
        nvMessage: ["הבנתי. מעביר את הנתונים<br>שלחתי דיווח. נבדוק את זה לעומק אצלינו."],
        nvTextBtn: "דפדפן בסמארטפון",
        nvActionName: "sendMessage",
        nvTextToSend: "דרך התחברות: דפדפן בסמארטפון",
        iLstBtnPtr: 13,
        lstPages: [PAGES_ID.all],
        userForm: null
    },
    {
        nvMessage: ["הבנתי. מעביר את הנתונים<br>שלחתי דיווח. נבדוק את זה לעומק אצלינו."],
        nvTextBtn: "באפליקציה",
        nvActionName: "sendMessage",
        nvTextToSend: "דרך התחברות: באפליקציה",
        iLstBtnPtr: 13,
        lstPages: [PAGES_ID.all],
        userForm: null
    }],
    55:
    {
        nvMessage: ["כל הכבוד על העירנות!<br>שלחתי את ההודעה שלך אל הצוות במשרד.<br>נבדוק את הענין ונסדר אצלינו. תודה!"],
        // nvTextBtn: "שליחה",
        // nvActionName: "sendMessage",
        iLstBtnPtr: 13,
        lstPages: [PAGES_ID.all],
        userForm: null
    }
    ,
    56:
        [{
            nvMessage: [`אפשר לצמצם את הדילי בכמה דרכים:<br><br>
            1. בלחיצה כפולה על הכפתור העגול, בימין למעלה<br>
             (זה שיש בו תמונה של קליקר - שלט).
             <br><br>
            2. בשינוי הגדרות הוידאו, ע”י לחיצה על גלגל השיניים בחלנות הוידאו
            שמאל למטה.
            <br><br>
            3. גלישה בחיבור אינטרנט חזק יותר (קווי או מיקום עם קליטה חזקה יותר).`],
            nvTextBtn: "מה עושים עם הדיליי?",
            nvActionName: null,
            iLstBtnPtr: 50,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["ייתכן שהשחקן שלך מושהה.<br>אפשר לשחרר אותו בלחיצה על פרופיל השחקן, בשמאל למעלה"],
            nvTextBtn: "לא, זה משהו אחר",
            nvActionName: null,
            iLstBtnPtr: 57,
            lstPages: [PAGES_ID.all],
            userForm: null
        }
        ],
    57: [
        {
            nvMessage: ["בשמחה, בשביל מה אני כאן? :) <br>לעוד שאלות - אני זמין בלחיצה."],
            nvTextBtn: "אוקי, תודה",
            iLstBtnPtr: 14,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["מוזר מאוד"],
            nvTextBtn: "לא עזר"
            , nvActionName: "startFillForm"
            , bResetText: true
            , nvTitleForm: "לא מצליח לענות תשובות, בדקתי, אני לא מושהה, וזה לא קשור לדיליי"
            , iSubmitBtn: 58
            , iLstBtnPtr: 0
            , lstPages: [PAGES_ID.all]
            , userForm: [
                { nvQuestion: "נשמח לתשובה מפורטת:", nvFieldName: "פירוט הבעיה" },
                { nvQuestion: "וכתובת המייל איתה התחברת?", nvFieldName: "כתובת המייל" }
            ]
        }
    ],
    58: [
        {
            nvMessage: ["אוקי,<br>שלחתי דיווח. נבדוק את זה לעומק אצלינו."],
            nvTextBtn: "שליחה",
            nvActionName: "sendMessage",
            iLstBtnPtr: 13,
            lstPages: [PAGES_ID.all],
            userForm: null
        }
    ],
    59: [
        {
            nvMessage: [`יש דיליי בין השידור לשאלות בזמן אמת.<br>הוידאו מופיע מספר שניות אחרי שההצבעה נסגרת<br>
            ולכן זה נראה שיש תקלה בהצבעה.`],
            nvTextBtn: "כן",
            nvActionName: null,
            iLstBtnPtr: 56,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: ["ייתכן שהשחקן שלך מושהה.<br>אפשר לשחרר אותו בלחיצה על פרופיל השחקן, בשמאל למעלה"],
            nvTextBtn: "לא",
            nvActionName: null,
            iLstBtnPtr: 57,
            lstPages: [PAGES_ID.all],
            userForm: null
        }
    ],
    60: [
        {
            nvMessage: [`אם בלחיצה על תשובה כתוב שהיא נקלטה, אז אל דאגה.<br>
            אצלינו במערכת המשחק הניקוד שלך מתעדכן.<br><br>
            כנראה קיים באג בתצוגה של הנקודות אצלך.<br>
            שלחתי דיווח. מקווה שנסדר את זה בקרוב.`],
            nvTextBtn: "לא נוספות לי נקודות",
            iLstBtnPtr: 13,
            lstPages: [PAGES_ID.all],
            userForm: null
        },
        {
            nvMessage: [`קלי קלות.<br>
            לחיצה למעלה בשמאל על “רישום לקבוצות”<br>
           ואפשר לשנות כינוי..`],
            nvTextBtn: "אני רוצה לשנות כינוי",
            iLstBtnPtr: 13,
            lstPages: [PAGES_ID.all],
            userForm: null
        },freeTextToMail
    ],
    61: [
        {
            nvMessage: ["אוקי,<br>שלחתי את המסר למייל של מלחמת מוחות, בקרוב יחזרו אליך לכתובת המייל שנתת."],
            nvTextBtn: "שלח את המסר לצוות מלחמת מוחות",
            nvActionName: "sendMessageToMail",
            iLstBtnPtr: 13,
            lstPages: [PAGES_ID.all],
            userForm: null
        }
    ],
}
