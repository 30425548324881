export class ChatMessage {
    public uMessageId: string="";
    public nvMailAdress: string="";
    public nvDisplayName: string="";
    public uid: string="";
    public nvMessage: string="";
    public lstMessage: string[]=[];
    public nvPage: string="";
    public dtDate: Date;
    public bRead: boolean = false;
    public nvColor: string="";
    public bPublish: boolean;
    public dtPublishDate:number;

    constructor() {
        this.uMessageId = new Date().getTime().toString()
        this.dtDate = new Date();
    }
}