import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Alert, AlertType } from '../../Models/Alert';
import { AlertService } from '../../Services/alert.service';

@Component({
  selector: 'mm-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit, OnDestroy {

  alerts = new Array<Alert>();
  subscription: Subscription = new Subscription();

  get alertTypes() {
    return AlertType;
  }

  constructor(private AlertService: AlertService) {

  }

  ngOnInit() {
    this.loadAlerts();
  }

  loadAlerts() {
    this.subscription.add(
      this.AlertService.getAlertsAsync()
        .subscribe(alerts => {
          if (!alerts) {
            this.alerts = new Array<Alert>();
          } else {
            this.alerts = alerts;
          }
        })
    )
  }

  removeAlert(alertIndex) {
    this.AlertService.removeAlertByIndex(alertIndex);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
