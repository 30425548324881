import { Injectable } from '@angular/core';
import { AppProxy } from './app.proxy';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  version = 11;

  constructor(private proxy: AppProxy) { }

  logError(functionName: string, exception: string) {
    // console.log(`LogService.logError`);
    // debugger // post
    // return;
    const iUserId = localStorage.getItem('iUserId');
    return this.proxy
      .post('LogWebError', {
        // TODO: tammy - add ip to log
        // ip: navigator.userAgent?,
        // add version
        iUserId: iUserId ? parseInt(iUserId) : 0,
        functionName: `v${this.version} - ${functionName}`,
        exception: exception
      })
      .then(res => { })
      .catch((error: any) => {
        console.error(`LogService.logError error. message: ${error.message}`);
      });
  }

  logExtraWebError(functionName: string, exception: string) {
    // console.log(`LogService.logError`);
    // debugger // post
    // return;
    const iUserId = localStorage.getItem('iUserId');
    return this.proxy
      .post('LogExtraWebError', {
        // TODO: tammy - add ip to log
        // ip: navigator.userAgent?,
        // add version
        iUserId: iUserId ? parseInt(iUserId) : 0,
        functionName: `${this.version} - ${functionName}`,
        exception: exception
      })
      .then(res => { })
      .catch((error: any) => {
        console.error(`LogService.logError error. message: ${error.message}`);
      });
  }
}
