import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Game } from '../../Models/Game';
import { GameService } from '../../Services/game.service';
import { ChatMessage } from '../../Models/ChatMessage';
import { ChatMessageService } from '../../Services/chat-message.service';
import { FireAuthService } from '../../Services/fireauth.service';
import { GlobalService } from '../../Services/global.service';
import { PAGES_ID, ROBOT_RESPONSE_DATA, RobotResponse, UserForm, EMAIL_DATA, userGlobalForm } from './chat-support.data';
import { EmailService } from 'src/app/Services/email.service';
const POOL_ID: string = "00000000-0000-0000-0000-000000000000";
export const MESSAGE_TYPE: any = {
  feedback: { fireStoreUid: "feedback", nvText: "שליחת משוב" },
  broadcastMessage: { fireStoreUid: "broadcastMessage", nvText: "שליחת הודעה לשידור" },
  broadcastOratorMessage: { fireStoreUid: "broadcastOratorMessage", nvText: "שליחת הודעה למנחה" },
  technicalSupport: { fireStoreUid: "technicalSupport", nvText: "עזרה טכנית" },
  orderGame: { fireStoreUid: "orderGame", nvText: "הזמנת משחק" }
};
export const PAGE_DATA = {
  'ClickerComponent': { nvName: "שלט" },
  'GameNavigationComponent': { nvName: "כניסה למשחק (עם קוד סודי)" },
  'RegisterGameComponent': { nvName: "רישום שלט לקבוצות במשחק" },
  'UserDetailsComponent': { nvName: "עריכת פרטי משתמש" },
  'LoginComponent': { nvName: "הרשמה / התחברות" },
  'LoginEmailComponent': { nvName: "רישום מוקדם עם מייל" },
  'ForgotPasswordComponent': { nvName: "שכחתי סיסמה" },
  'ResetAndVerifyComponent': { nvName: "קביעת סיסמה חדשה לאחר איפוס" },
}
// const STEP_TYPE: any = { choseType: 1, fillText: 2, successMessage: 3, type: -1, exit: 4, typeConcat: 5 };

@Component({
  selector: 'mm-chat-support',
  templateUrl: './chat-support.component.html',
  styleUrls: ['./chat-support.component.scss']
})
export class ChatSupportComponent implements OnInit {
  @ViewChild('chatbot', { static: true }) private chatScreen: ElementRef;
  @Output() closeMe = new EventEmitter<number>();
  readonly objectKeys = Object.keys;

  readonly staticResponseData = ROBOT_RESPONSE_DATA;
  readonly staticPageData = PAGE_DATA;
  get bPinChat() {
    return !this.bDragged && this.nvCurrentPage == 'ClickerComponent';
  }
  bDragged: boolean = false;
  uGameId: string = POOL_ID;
  subscription: Subscription = new Subscription();
  firebaseUser: firebase.User;
  nvTextMessage: string = "";
  nvCurrentPage: string = "";
  nvTextToSend: Array<string> = new Array<string>();
  message: ChatMessage = new ChatMessage();
  messageType: any;
  extraData = { iSubmitBtn: 0, nvActionSubmit: null, userForm: null, indxFormKey: 0, bNewMessage: true, submitParams: null, bAvoidEnter: false }
  // nvActionSubmit: string;
  minimize: boolean = false;
  robotMessage = [];

  backupUserForm: Array<UserForm>;
  // indxFormKey: number = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private fireAuthService: FireAuthService,
    private chatMessageService: ChatMessageService,
    private gameService: GameService,
    private emailService: EmailService,
    private globalService: GlobalService) {
    this.subscription.add(
      this.fireAuthService.getUserFirebaseAsync()
        .subscribe((firebaseUser: firebase.User) => {
          this.firebaseUser = firebaseUser;
          this.setUserMessage();
          console.log(this.firebaseUser);
        })
    );
    this.subscription.add(
      this.activatedRoute.queryParams.subscribe(params => {
        if (params.uGameId) {
          this.uGameId = params.uGameId;
        } else {
          this.uGameId = POOL_ID;
        }
      })
    )
    this.subscription.add(
      this.gameService.getGameAsync()
        .subscribe(async (game: Game) => {
          if (game) {
            this.uGameId = game.uGameId;
          }
        })
    );
    this.subscription.add(
      this.globalService.getCurrentPageAsync()
        .subscribe((nvCurrentPage: string) => {
          this.nvCurrentPage = nvCurrentPage;
          console.log(this.nvCurrentPage);
        })
    );
  }

  ngOnInit() {
    this.robotResponse(Object.assign(new RobotResponse(), ROBOT_RESPONSE_DATA.response_1));
  }


  robotResponse(robotResponse: RobotResponse) {
    let indx = 0;
    if (this.staticResponseData[robotResponse.iLstBtnPtr]) {
      robotResponse['lstBtn'] = this.staticResponseData[robotResponse.iLstBtnPtr].filter(b => {
        return b.lstPages && b.lstPages.findIndex(p => p == PAGES_ID.all || p == PAGES_ID[this.nvCurrentPage]) > -1
      });
    }
    setTimeout(() => {
      indx = this.robotMessage.push({ iType: 1, bTypint: 1, robotResponse: Object.assign({}, robotResponse) }) - 1;
      this.scrollDown();
    }, 1000);
    setTimeout(() => {
      this.robotMessage[indx].bTypint = 0;
      this.scrollDown();
    }, 2000);
  }

  scrollDown(): void {
    setTimeout(() => {
      this.chatScreen.nativeElement.scrollTop = this.chatScreen.nativeElement.scrollHeight;
    }, 10);
  }


  btnClick(btn: RobotResponse, parent: RobotResponse) {
    this.robotMessage.push({ nvMessage: btn.nvTextBtn, iType: 2 });
    this.scrollDown();
    this.robotResponse(btn);

    if (this.extraData.userForm)
      this.backupUserForm = Object.assign({}, this.extraData.userForm);
    this.extraData.userForm = btn.userForm;
    this.extraData.indxFormKey = 0;
    if (btn.bResetText) {
      this.nvTextToSend = [];
    }
    if (this.extraData.userForm) {
      if (btn.nvTitleForm)
        this.nvTextToSend.push(btn.nvTitleForm);
      this.extraData.iSubmitBtn = btn.iSubmitBtn;
      this.extraData.nvActionSubmit = btn.nvActionSubmit;
      this.extraData.submitParams = btn.submitParams;
    }


    let lstAction = [].concat(btn.nvActionName);
    let lstParams = [].concat(btn.dataParams);
    for (let i = 0; i < lstAction.length; i++) {
      if (lstAction[i] && this[lstAction[i]]) {
        this[lstAction[i]](lstParams[i]);
      }
    }

    if (btn.nvTextToSend) {
      this.addMessageToArray(btn.nvTextToSend);
    }


    parent["lstBtn"] = [];
  }

  setUserMessage() {
    if (this.firebaseUser && this.message) {
      this.message.uid = this.firebaseUser.uid;
      this.message.nvMailAdress = this.firebaseUser.email.includes("@milchemetmochot.com") ? '' : this.firebaseUser.email;
      this.message.nvDisplayName = this.firebaseUser.displayName;
      this.message.nvPage = this.nvCurrentPage;
    }
  }

  resetMessage(typeIndx) {
    this.messageType = MESSAGE_TYPE[typeIndx];
    this.nvTextToSend = [];
    this.message = new ChatMessage();
    this.setUserMessage();
    this.extraData = { iSubmitBtn: 0, nvActionSubmit: null, userForm: null, indxFormKey: 0, bNewMessage: true, submitParams: null, bAvoidEnter: false };
  }
  setMessageType(typeIndx) {
    this.messageType = MESSAGE_TYPE[typeIndx];
  }

  userBroadcastMessage() {
    if (this.backupUserForm)
      this.addMessageToArray(this.backupUserForm[0]["value"] + " : <b>" + this.backupUserForm[1]["value"] + "</b>")
  }

  setAvoidEnter(bAvoidEnter: boolean) {
    this.extraData.bAvoidEnter = bAvoidEnter;
  }

  startFillForm() {
    const emptyR: RobotResponse = Object.assign(new RobotResponse(), ROBOT_RESPONSE_DATA.response_empty);
    if (this.extraData.userForm[this.extraData.indxFormKey]) {
      emptyR.nvMessage = [this.extraData.userForm[this.extraData.indxFormKey].nvQuestion]
      this.robotResponse(emptyR);
    }
    else {
      emptyR.iLstBtnPtr = this.extraData.iSubmitBtn;
      this.robotResponse(emptyR);
      console.log(this.extraData.userForm);
    }
  }

  onSubmit(bEnterClick: boolean = false): void {
    if (!this.nvTextMessage || (bEnterClick && this.extraData.bAvoidEnter)) {
      return;
    }
    this.setAvoidEnter(false);
    this.robotMessage.push({ nvMessage: this.nvTextMessage, iType: 2 });
    this.scrollDown();

    if (this.extraData.nvActionSubmit && this[this.extraData.nvActionSubmit]) {
      this[this.extraData.nvActionSubmit](this.nvTextMessage, this.extraData.submitParams);
      this.nvTextMessage = null;
      return;
    }

    if (this.extraData.userForm && this.extraData.userForm[this.extraData.indxFormKey]) {
      this.nvTextToSend.push(this.extraData.userForm[this.extraData.indxFormKey].nvFieldName + ' : ' + this.nvTextMessage);
      this.extraData.userForm[this.extraData.indxFormKey]["value"] = this.nvTextMessage;
      this.nvTextMessage = null;
      this.extraData.indxFormKey++;
      this.startFillForm();
    }
  }

  freeTypeFeedback(nvText, nvNextBtn) {
    this.addMessageToArray(`<b>${nvText.replace(/(\r\n|\n|\r)/gm, " , ")}</b>`);
    this.pushConstmessage(ROBOT_RESPONSE_DATA[nvNextBtn]);
  }

  sendReport(nvText, extraData) {
    this.nvTextToSend.push(`${extraData.nvTitle}${nvText.replace(/(\r\n|\n|\r)/gm, " , ")}`);
    this.sendMessage();
    this.pushConstmessage(ROBOT_RESPONSE_DATA[extraData.nvNextBtn]);
  }

  registerEmail() {
    this.nvTextToSend.push(this.extraData.userForm[this.extraData.indxFormKey].nvFieldName + ' : ' + this.nvTextMessage);

    this.sendEmail(EMAIL_DATA.x93132, `הצטרפות לקבלת עידכונים`,
      `אשמח להצטרף לרשימת התפוצה לקבלת עידכונים על המשחקים הבאים<br>
    המייל שלי: <b> ${this.nvTextMessage}</b>`
    )
    this.sendMessage();
    this.pushConstmessage(ROBOT_RESPONSE_DATA.wellcome);
  }

  pushConstmessage(constMessage) {
    const emptyR: RobotResponse = Object.assign(new RobotResponse(), constMessage);
    this.robotResponse(emptyR);
  }
  setColor(nvColor: string) {
    this.message.nvColor = nvColor ? nvColor : "";
  }

  sendMessage() {
    this.message.nvMessage = this.nvTextToSend.join("<br>");
    let bPoolGame: boolean = false;
    if (this.messageType.fireStoreUid == MESSAGE_TYPE.orderGame.fireStoreUid) {
      this.sendEmail(EMAIL_DATA.x93131, `הזמנת משחק חדש`, this.message.nvMessage);
      bPoolGame = true;
    }
    this.addMessageToArray(this.message.nvMessage, bPoolGame);
    // this.nvTextToSend = [];
  }

  addMessageToArray(nvMessage, bPoolGame: boolean = false) {
    this.message.nvMessage = nvMessage;
    if (this.extraData.bNewMessage) {
      this.chatMessageService.addMessageGameCollection(this.messageType.fireStoreUid, bPoolGame ? POOL_ID : this.uGameId, this.message);
      this.extraData.bNewMessage = false;
    } else
      this.chatMessageService.addMessageToArray(this.messageType.fireStoreUid, this.uGameId, this.message)
  }

  // startFreeText() {

  //   this.pushConstmessage({
  //     iSubmitBtn: 61
  //     , userForm: userGlobalForm
  //   });

  // }

  sendEmail(nvTarget, nvSubject, nvMessage) {
    this.emailService.sendEmail(nvTarget, nvSubject, nvMessage);
  }

  sendBugReport(bugData) {
    this.addMessageToArray(bugData.nvMessage);
    if (bugData.bEmail) {
      this.sendEmail(EMAIL_DATA.x93132, "דיווח על תקלה במערכת משתמשים",
        `${bugData.nvMessage}<br>
        פרטי משתמש מדווח:<br>
      ${this.message.nvMailAdress}<br>
      ${this.message.nvDisplayName}
      ${!this.message.nvDisplayName && !this.message.nvMailAdress ? "לא ידוע" : ""}`
      )
    }
  }

  sendMessageToMail(nvTitle) {

    let message = `אני רוצה עזרה מצוות מלחמת מוחות. המסר שלי:<br>
    ${this.backupUserForm[0]["value"].replace(/(\r\n|\n|\r)/gm, " <br> ")}<br>
    פרטים ליצירת קשר:<br>
    ${this.backupUserForm[1]["value"]}<br>
    
 
    פרטי משתמש הידועים לדני:<br>

     ${this.message.nvMailAdress}--
     ${this.message.nvDisplayName}
     
  `
    console.log(message);
    this.sendEmail(EMAIL_DATA.x93132, "רוצה עזרה מבנאדם אמיתי",
      message
    )

    // this.addMessageToArray(message);
  }

  sendMailResetPassword(nvMailAddress) {
    //לעבור על זה שיהיה יותר בטוח
    // let nvMailAddress = this.nvTextToSend[this.nvTextToSend.length - 1].split(":")[1].trim();
    if (nvMailAddress) {
      nvMailAddress = nvMailAddress.replace(/(\r\n|\n|\r)/gm, "");
      this.fireAuthService.sendPasswordResetEmail(nvMailAddress);
    }
    this.pushConstmessage(ROBOT_RESPONSE_DATA.resetPassord);
  }

  closeChat() {
    this.closeMe.emit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
