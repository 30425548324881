
export const AlertType = { danger: 'danger', success: 'success', warning: 'warning' }

export class Alert {
    message: string;
    type: string;
    id: number;

    constructor(message, type: string, id: number) {
        this.message = message;
        this.type = type;
        this.id = id;
    }
}