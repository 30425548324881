import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from 'src/app/Services/firebase.service';
import { FirestoreService } from 'src/app/Services/firestore.service';

@Component({
  selector: 'mm-report-game-navigation',
  templateUrl: './report-game-navigation.component.html',
  styleUrls: ['./report-game-navigation.component.css']
})
export class ReportGameNavigationComponent implements OnInit {
  uClickerId: string;
  gameData: any = {};
  constructor(private firebaseService: FirebaseService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.parent.params.subscribe(params => {
      //  console.log(params);
      this.uClickerId = params.uClickerId;
    })

    this.firebaseService.getSnapshotChanges("currentGameResult").then(gameData => {
      if (gameData) {
        this.gameData = gameData;
      } else {
        this.gameData = {};
      }
    });
  }
  selectGame() {
    this.router.navigate(['gameNavigation'], { relativeTo: this.route });
  }
  showResult() {
    this.router.navigate(['result'], { relativeTo: this.route });
  }
}
