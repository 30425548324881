import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FirebaseService } from '../../Services/firebase.service';
import { ClickerOnGame } from '../../Models/ClickerOnGame';
import { ClickerOnGameService } from '../../Services/clicker-on-game.service';
import { SysTable } from '../../Services/SysTables.service';
import { GameService } from '../../Services/game.service';
import { Game } from '../../Models/Game';
import { FirestoreService } from '../../Services/firestore.service';
import { FirestoreClickerOnGame } from '../../Models/FirestoreClickerOnGame';
import { AlertService } from '../../Services/alert.service';
import { AlertType } from '../../Models/Alert';
import { FireAuthService } from '../../Services/fireauth.service';

@Component({
  selector: 'mm-clicker',
  templateUrl: './clicker.component.html',
  styleUrls: ['./clicker.component.css']
})
export class ClickerComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();
  relativeSubscription: Subscription = new Subscription();
  game: Game;
  clickerOnGame: FirestoreClickerOnGame;
  iDisplayMode: number = 0;
  isOnAir = false;
  bLiveBroadcast = false;
  bShowResults = false;
  iVotingStatus: number = SysTable.VotingStatus.close;
  myVotingOpenTime: number;
  timeOutIDs = new Array<any>();

  constructor(
    private alertService: AlertService,
    private firebaseService: FirebaseService,
    private firestoreService: FirestoreService,
    private fireAuthService: FireAuthService,
    private clickerOnGameService: ClickerOnGameService,
    private gameService: GameService) { }

  ngOnInit() {
    // this.firebaseService.getHideBroadcast().then(bHideBroadcast => {
    //   this.iDisplayMode = bHideBroadcast ? 1 : 0;
    // });
    this.subscription.add(
      this.gameService.getGameAsync()
        .subscribe((game: Game) => {
          this.game = game;
          if (game) {
            this.initClickerOnGame();
            this.startSubscribe();
          } else {
            this.clearData();
          }
        })
    );
  }

  initClickerOnGame() {
    this.subscription.add(
      this.firestoreService.getClickerOnGameAsync()
        .subscribe(async (clickerOnGame: FirestoreClickerOnGame) => {
          if (!clickerOnGame) {
            this.clickerOnGame = null;
          } else if (!clickerOnGame.clickerOnGameGroups || clickerOnGame.clickerOnGameGroups.length === 0) {
            this.clickerOnGame = null;
            //TODO: tammy - במקרה של תקלה בשליפת משחק או שחקן להוציא מהמשחק ??
            // this.alertService.addAlert('השחקן לא נשלף באופן תקין', AlertType.danger);
            this.alertService.addAlert('clicker.alert.get-player-fail', AlertType.danger);
          } else {

            if (!this.clickerOnGame || this.clickerOnGame.nvPlayerName !== clickerOnGame.nvPlayerName) {
              this.firestoreService.setTemporaryDisplayName(clickerOnGame.nvPlayerName);
            }

            this.clickerOnGame = Object.assign(this.clickerOnGame ? this.clickerOnGame : {}, clickerOnGame);
          }
        })
    );
  }

  clearData() {
    this.clickerOnGame = null;
    this.isOnAir = false;
    this.bLiveBroadcast = false;
    this.bShowResults = false;
    this.iVotingStatus = SysTable.VotingStatus.close;
    this.myVotingOpenTime = null;
    this.relativeSubscription.unsubscribe();
    this.relativeSubscription = new Subscription();
    this.timeOutIDs = new Array<any>();
  }

  startSubscribe() {
    //this.updatingGameOnAirChanges();
    this.updatingVotingStatusChanges();
    this.updatingShowResultsChanges();
    this.updatingEndCollectAndCalcScoresChanges();
    this.updatingLiveBroadcastChanges();
    // this.updatingiCurrentTimerChanges();
  }

  // updatingGameOnAirChanges() {
  //   this.relativeSubscription.add(
  //     this.firebaseService
  //       .getGameOnAirChanges(this.game.uGameId)
  //       .subscribe(isOnAir => {
  //         this.isOnAir = isOnAir;
  //       })
  //   );
  // }

  updatingLiveBroadcastChanges() {
    this.subscription.add(
      this.firebaseService
        .getGameLiveBroadcastChanges(this.game.uGameId)
        .subscribe(bLiveBroadcast => {
          this.bLiveBroadcast = bLiveBroadcast;
        })
    );
  }

  updatingShowResultsChanges() {
    this.relativeSubscription.add(
      this.firebaseService
        .getShowResultsChanges(this.game.uGameId)
        .subscribe(bShowResults => {
          this.bShowResults = bShowResults;
          if (this.bShowResults) {
            //TODO: to delete
            // דיליי של 2 שניות כדי לתת זמן לחישוב הניקוד בשרת
            // this.timeOutIDs.push(
            //   setTimeout(() => this.refreshClickerData(), 4000)
            // );
            // this.refreshClickerData();
          }
        })
    );
  }

  updatingEndCollectAndCalcScoresChanges() {
    this.relativeSubscription.add(
      this.firebaseService
        .getEndCollectAndCalcScoresChanges(this.game.uGameId)
        .subscribe(bEndCollectAndCalcScores => {
          if (!this.clickerOnGame || this.clickerOnGame.iScore == null || bEndCollectAndCalcScores) {
            this.refreshClickerData();
          }
        })
    );
  }

  updatingVotingStatusChanges() {
    this.relativeSubscription.add(
      this.firebaseService
        .getVotingStatusChanges(this.game.uGameId)
        .subscribe(async iVotingStatus => {
          if (iVotingStatus === SysTable.VotingStatus.LoadAnswersClickers || iVotingStatus === SysTable.VotingStatus.selectOption) {
            const bFighterOnly = await this.firebaseService.getbFighterOnly(this.game.uGameId);
            if (bFighterOnly) {
              this.iVotingStatus = SysTable.VotingStatus.close;
              this.myVotingOpenTime = 0;
            } else {
              this.iVotingStatus = iVotingStatus;
              // אם המשתמש לא ריפרש באמצע הטיימר- מעדכנים את שעת הפתיחה אצלו (myVotingOpenTime === null)
              // אחרת- זמן התגובה שלו מחושב בשרת (myVotingOpenTime = 0)
              if (this.myVotingOpenTime === 0 && iVotingStatus === SysTable.VotingStatus.LoadAnswersClickers) {
                this.myVotingOpenTime = new Date().getTime();
              } else if (iVotingStatus !== SysTable.VotingStatus.close) {
                this.myVotingOpenTime = 0;
              }
            }
          } else {
            this.iVotingStatus = iVotingStatus;
          }
        })
    );
  }

  updateMyVotingOpenTime(time: number) {
    this.myVotingOpenTime = time;
  }

  async refreshClickerData() {
    if (this.clickerOnGame && this.clickerOnGame.clickerOnGameGroups.length > 0 && this.clickerOnGame.iStatus !== SysTable.constantClickerStatus.gameDelayed) {
      const value = await this.gameService.getSingleFields('iScore,iStatus', 'TClickerOnGame', 'uClickerOnGameId', this.clickerOnGame.clickerOnGameGroups[0].uClickerOnGameId);
      if (value) {
        const iStatus = Number(value['iStatus']);
        const iScore = Number(value['iScore']);
        const bStatusChanged = this.clickerOnGame.iStatus !== iStatus;
        const cogDetails = { iStatus: iStatus, iScore: iScore };
        const updateRes = await this.firestoreService.addOrUpdateClickerOnGame(this.fireAuthService.getUserFirebase().uid, this.game.uGameId, cogDetails)
        if (updateRes) {
          if (bStatusChanged) {
            if (iStatus === SysTable.constantClickerStatus.play) {
              // this.alertService.addAlert('השחקן שלך הופעל בהצלחה', AlertType.success);
              this.alertService.addAlert('clicker.alert.active-player-success', AlertType.success);
            } else {
              // this.alertService.addAlert('שים לב! השחקן שלך הושהה מהמשחק', AlertType.warning);
              this.alertService.addAlert('clicker.alert.player-delayed', AlertType.warning);
            }
          }
          return;
        } else {
          this.clickerOnGame.iStatus = iStatus;
          this.clickerOnGame.iScore = Math.floor(iScore);
        }
      } else {
        // this.alertService.addAlert('אירעה שגיאה בעדכון נתוני השחקן', AlertType.danger);
        this.alertService.addAlert('clicker.alert.update-player-fail', AlertType.danger);
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.relativeSubscription.unsubscribe();
  }

  onCopy(event: any) {
    event.preventDefault();
  }

  // Math = Math;
  // timer = { iCurrentTimer: 0, bPlayTimer: false };
  // updatingiCurrentTimerChanges() {
  //   this.relativeSubscription.add(this.firebaseService
  //     .getiCurrentTimer(this.game.uGameId)
  //     .subscribe(_timer => {
  //       //this.clearTimeOuts();
  //       this.timer.iCurrentTimer = _timer;
  //       this.getbPlayTimer().then(res => {
  //         this.setTimer();
  //       })
  //     })
  //   );
  // }
  // getbPlayTimer(): Promise<boolean> {
  //   return this.firebaseService
  //     .getbPlayTimer(this.game.uGameId)
  //     .then(_bPlayTimer => {
  //       this.timer.bPlayTimer = _bPlayTimer;
  //       return this.timer.bPlayTimer;
  //     });
  // }
  // setTimer() {
  //   if (this.timer.iCurrentTimer > 0 && this.timer.bPlayTimer) {
  //     this.timer.iCurrentTimer -= 0.01;
  //     this.timeOutIDs.push(setTimeout(this.setTimer.bind(this), 10));
  //   }
  //   else {//סיום הטיימר סופית
  //     this.clearTimeOuts();
  //   }
  // }
}
