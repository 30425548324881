import { Guid } from "./Guid";

export class FirestoreClickerOnGame {
    iScore: number;
    iStatus: number;
    nvPlayerName: string;
    clickerOnGameGroups: Array<FirestoreClickerOnGameGroup>;

    constructor(iScore: number, iStatus: number, nvPlayerName: string, clickerOnGameGroups: Array<FirestoreClickerOnGameGroup> = new Array<FirestoreClickerOnGameGroup>()) {
        this.iScore = iScore;
        this.iStatus = iStatus;
        this.nvPlayerName = nvPlayerName;
        this.clickerOnGameGroups = clickerOnGameGroups;
    }
}

export class FirestoreClickerOnGameGroup {
    uClickerOnGameId: string
    uGroupId: string;

    constructor(uGroupId: string = null, uClickerOnGameId = null) {
        this.uClickerOnGameId = uClickerOnGameId ? uClickerOnGameId : Guid.newGuid();
        this.uGroupId = uGroupId;
    }
}
