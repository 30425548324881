import { Clicker } from './Clicker';
import { Guid } from './Guid';
import { SysTable } from '../Services/SysTables.service';
// import { SysTable } from '../services/SysTables.service';
// import { Media } from './Media';

export class ClickerOnGame extends Clicker {

    public uClickerOnGameId: string;
    public uClickerId: string;
    public uGameId: string;
    public uGroupId: string;
    public nvPlayerName: string;
    public iScore: number;
    public iStatus: number;
    public iClickerOnGameType: number;
    public bSpecialClicker: boolean;
    public iTotalAmount: number;
    public iNumberErrors: number;
    public uPodiumId: string;
    public iSelfWorth: number;
    public bAvoidActivate: boolean;

    // public bIsNew: boolean;
    // public logo: Media;

    public iCreatedByUserId: number;
    public dtCreatedate: Date;
    public iLastModifyUserId: number;
    public dtLastModifyDate: Date;
    public bSysRowStatus: boolean;


    constructor(uClickerOnGameId: string) {
        super();
        this.iClickerOnGameType = SysTable.constantClickerOnGameType.regular;
        this.bSpecialClicker = false;
        this.uClickerOnGameId = uClickerOnGameId ? uClickerOnGameId : Guid.newGuid();
        this.iStatus = SysTable.constantClickerStatus.play;
        this.iScore = 0;
        this.iTotalAmount = 0;
        this.iNumberErrors = 0;
        // this.bIsNew = true;
       // this.dtCreatedate = new Date();

    }

}
