import { Injectable } from '@angular/core';
import { FireAuthService } from './fireauth.service';
import { UserService } from './user.service';
import { User } from '../Models/User';
import { LogService } from './log.service';
import { FirebaseService } from './firebase.service';
import { FirestoreUser } from '../Models/FirestoreUser';
import { FirestoreService } from './firestore.service';
import { AlertService } from './alert.service';
import { AlertType } from '../Models/Alert';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(
        private alertService: AlertService,
        private userService: UserService,
        private fireAuthService: FireAuthService,
        private logService: LogService,
        private firestoreService: FirestoreService,
        private firebaseService: FirebaseService) { }

    logOut() {
        //TODO: tammy - לקרא לפונקציה בכניסה למערכת שנכשלה או אחרי יצירת חשבון
        // this.logOut();
        this.fireAuthService.logOut();
    }

    getPasswordFromEmailAddress(email: string): string {
        let password = email.toLocaleLowerCase().split('@')[0];
        for (let i = password.length; i < 6; i++) {
            password = '0' + password;
        }
        return password;
    }

    getDisplayNameFromEmailAddress(email: string): string {
        return email.split('@')[0];
    }

    /* return types
    user: success
    null: service is not available | createUserWithEmailAndPassword failed | registerUserWeb failed | firestoreService.addUser failed */
    async registerNewUser(user: User, bCreateUserFireAuth = true): Promise<User> {
        const bServerAvailable = await this.firebaseService.getServiceAble('registerNewUser');
        if (bServerAvailable) {

            if (bCreateUserFireAuth) {
                const createRes = await this.fireAuthService.createUserWithEmailAndPassword(user.nvMailAddress, user.nvPassword);
                if (!createRes) {
                    return null;
                }
                user.uFirebaseId = createRes.uid;
            }

            const userWebRes = await this.userService.registerUserWeb(user);
            if (!userWebRes) {
                // this.alertService.addAlert(userWebRes === false ? 'המשחק בתפוסה מלאה. נסו להירשם בעוד מספר דקות' : 'הרישום למערכת נכשל', AlertType.danger);
                this.alertService.addAlert(userWebRes === false ? 'services.login.game-fully' : 'services.login.register-faild', AlertType.danger);
                return null;
            }
            user.iUserId = (userWebRes as User).iUserId;
            user.uClickerId = (userWebRes as User).uClickerId;

            const firestoreUser = new FirestoreUser(
                user.uClickerId,
                user.iUserId,
                user.nvDisplayName,
                user.nvMailAddress,
                user.nvPassword,
                user.bAcceptedRules,
                user.bMailingList);
            const firestoreRes = await this.firestoreService.addUser(user.uFirebaseId, firestoreUser);
            if (!firestoreRes) {
                // this.alertService.addAlert('הרישום למערכת נכשל', AlertType.danger);
                this.alertService.addAlert('services.login.register-faild', AlertType.danger);
                return null;
            }

            if (!bCreateUserFireAuth) {
                // this.logOut();
            }
            return user;

        } else {
            // this.alertService.addAlert('המשחק בתפוסה מלאה. נסו להכנס בעוד מספר דקות', AlertType.danger);
            this.alertService.addAlert('services.login.game-fully', AlertType.danger);
            return null;
        }
    }

    /* return types
    user: success
    null: user already exists | getUserProviders failed | registerNewUser failed */
    async register(user: User, bEmailVerification: boolean, bCreateUserFireAuth = true, bPopupUserDetails = false): Promise<User> {
        if (bCreateUserFireAuth) {
            const userProviders = await this.fireAuthService.getUserProviders(user.nvMailAddress);
            if (!userProviders) {
                return null;
            } else if (userProviders.filter(provider => provider === 'password').length > 0) {// משתמש כבר רשום במערכת עם סיסמה
               // const message = 'כתובת המייל הזו כבר רשומה אצלינו. אין צורך להרשם שוב, <a class="embeds-link text-blue" href="#/login?bIsNew=0">להתחברות</a>';
                const message = 'כתובת המייל הזו כבר רשומה אצלינו. אין צורך להרשם שוב, <a class="embeds-link text-blue" href="#/login?bIsNew=0">להתחברות</a>';
                this.alertService.addAlert(message, AlertType.danger, 20000);
                return null;
            } else if (userProviders.length === 1 && userProviders.filter(provider => provider === 'google.com').length > 0) {// משתשמש רשום רק עם חשבון גוגל
                const strLoginlink = `<a class="embeds-link text-blue" href="#/forgotPassword?nvMailAddress=${user.nvMailAddress}">איפוס סיסמה</a>`;
                const message = `הינך רשום למערכת עם חשבון גוגל. <br> כדי להיכנס עם סיסמה לחץ על ${strLoginlink} בהתחברות למערכת`;
                this.alertService.addAlert(message, AlertType.danger, 20000);
                return null;
            }
        }

        const registerUser = await this.registerNewUser(user, bCreateUserFireAuth);
        if (!registerUser) {
            return null;
        }

        if (bPopupUserDetails) {
            // TODO: tammy - להוסיף אפשרות של פופאפים במערכת
            alert(` פרטי המשתמש שלך הם\nמייל: ${user.nvMailAddress}\nסיסמה: ${user.nvPassword}`);
            this.alertService.addAlert('services.login.email-detail-send', AlertType.success);
        }
        else if (bEmailVerification) {
            // this.alertService.addAlert('שלום :) שלחנו לכתובת המייל קישור לאימות. לחיצה, אימות ונתחיל.', AlertType.success);
            this.alertService.addAlert('services.login.email-reset-send', AlertType.success);
        }

        return user;
    }

    async loginWithEmailAndPassword(nvMailAddress: string, nvPassword: string, bEmailVerification: boolean, bMessageWrongPass = true, bRegister = true): Promise<boolean> {
        const loginRes = await this.fireAuthService.loginWithEmailAndPassword(nvMailAddress, nvPassword, bEmailVerification, bMessageWrongPass);
        if (loginRes === null && bRegister) {
            const user = this.fireAuthService.getUserFirebase();
            // רישום המשתמש בדטהבייס והפיירסטור במקרה והוא קיים רק בפיירבייס
            user.displayName = user.displayName ? user.displayName : this.getDisplayNameFromEmailAddress(user.email);
            const newUser = new User(user.uid, user.displayName, nvMailAddress, nvPassword, true, false);//bMailingList
            const registerRes = await this.register(newUser, false, false);
            if (registerRes) {
                console.log(`LoginService.loginWithEmailAndPassword. reregister user succeeded`);
                this.logService.logError(`LoginService.loginWithEmailAndPassword -> register. newUser: ${JSON.stringify(newUser)}`, `reregister user succeeded`);
                return this.loginWithEmailAndPassword(nvMailAddress, nvPassword, bEmailVerification, bMessageWrongPass, false);
            } else {
                console.error(`LoginService.loginWithEmailAndPassword. reregister user failed`);
                this.logService.logError(`LoginService.loginWithEmailAndPassword -> register. newUser: ${JSON.stringify(newUser)}`, `reregister user failed`);
                return false;
            }
        } else if (loginRes === true) {
            return true;
        } else if (loginRes && loginRes.uid) {
            const bResend = await confirm(`חשבונך לא מאומת.\nנשלח אליך מייל אימות חשבון ברישום למערכת.\nאנא אמת את חשבונך כדי להיכנס למשחק או לחץ אישור לשליחת מייל אימות חדש`);
            if (bResend) {
                const resendRes = await this.fireAuthService.sendEmailVerification(loginRes);
                if (resendRes) {
                    this.alertService.addAlert('service.login.sending-email-success', AlertType.success);
                    // this.alertService.addAlert('מייל האימות נשלח בהצלחה.', AlertType.success);
                } else {
                    // this.alertService.addAlert('שליחת מייל אימות חשבון נכשלה', AlertType.danger);
                    this.alertService.addAlert('service.login.sending-email-faild', AlertType.danger);
                }
            }
            // this.logOut();
            return false;
        } else if (loginRes && loginRes.code) {//error fireauth
            // this.logOut();
            return false;
        } else {
            // this.logOut();
            // this.alertService.addAlert('החיבור למערכת נכשל', AlertType.danger);
            this.alertService.addAlert('service.login.connect-faild', AlertType.danger);
            return false;
        }
    }

    async loginWithRandomUser(uGameId: string): Promise<boolean> {
        if (uGameId) {
            const loginRes = await this.userService.loginWithRandomUser(uGameId);
            if (typeof loginRes === 'object') {
                const user = loginRes;
                return this.loginWithEmailAndPassword(user.nvMailAddress, user.nvPassword, false, true);
            } else {
                if (loginRes == 2) {
                    // this.alertService.addAlert('אין שחקנים זמניים פנויים', AlertType.danger, 0);
                    this.alertService.addAlert('service.login.no-free-random-player', AlertType.danger, 0);
                } else if (loginRes == 3) {
                    // this.alertService.addAlert('המשחק בתפוסה מלאה, נסו להכנס בעוד מספר דקות :(', AlertType.danger, 0);
                    this.alertService.addAlert('service.login.game-fully-booked', AlertType.danger, 0);
                } else {
                    // this.alertService.addAlert('הכניסה למערכת נכשלה', AlertType.danger);
                    this.alertService.addAlert('service.login.enter-fail', AlertType.danger);
                }
                return false;
            }
        } else {
            // this.alertService.addAlert('חסר קוד משחק', AlertType.danger);
            this.alertService.addAlert('service.login.game-code-invalid', AlertType.danger);
            return false;
        }
    }

    async loginWithGoogle(bAcceptedRules: boolean, bMailingList: boolean): Promise<boolean> {
        const loginRes = await this.fireAuthService.loginWithGoogle(bMailingList);
        if (!loginRes) {
            // this.logOut();
            return false;
        } else if (typeof loginRes === 'string') {
            const userFb = this.fireAuthService.getUserFirebase();
            userFb.displayName = userFb.displayName ? userFb.displayName : this.getDisplayNameFromEmailAddress(userFb.email);
            const newUser = new User(userFb.uid, userFb.displayName, userFb.email, null, bAcceptedRules, bMailingList);
            const user = await this.register(newUser, false, false);
            if (user) {
                if (loginRes === 'old user') {
                    console.log(`LoginService.loginWithGoogle. reregister user succeeded`);
                    this.logService.logError(`LoginService.loginWithGoogle -> register. newUser: ${JSON.stringify(newUser)}`, `reregister user succeeded`);
                }
                const bSetUser = await this.firestoreService.setUserFirestore(user.uFirebaseId);
                if (!bSetUser) {
                    // this.logOut();
                    // this.alertService.addAlert('הכניסה למערכת נכשלה', AlertType.danger);
                    this.alertService.addAlert('service.login.enter-fail', AlertType.danger);
                    return false;
                }
                return true;
            } else {
                // this.logOut();
                console.error(`LoginService.loginWithGoogle error. ${loginRes === 'old user' ? 'reregister user failed' : 'failed to created user'}`);
                this.logService.logError(`LoginService.loginWithGoogle -> register error. newUser: ${JSON.stringify(newUser)}`, loginRes === 'old user' ? 'reregister user failed' : 'failed to created new user (user exists only on firebase)');
                return false;
            }
        }
        return true;
    }

    /*return types
    true: success
    false: login failed (error | wrong password | exists only with google account)
    null: failed (getUserProviders | register) */
    async loginOrRegisterWithEmail(nvMailAddress: string, bAcceptedRules: boolean, bMailingList: boolean): Promise<boolean> {
        const password = this.getPasswordFromEmailAddress(nvMailAddress);
        const userProviders = await this.fireAuthService.getUserProviders(nvMailAddress);
        if (!userProviders) {
            return null;
        }

        if (userProviders.length === 0) {
            const newUser = new User(null, this.getDisplayNameFromEmailAddress(nvMailAddress), nvMailAddress, password, bAcceptedRules, bMailingList);
            const registerRes = await this.register(newUser, true, true, true);
            if (!registerRes) {
                return null;
            }
            userProviders.push('password');
        }

        let loginRes = false;
        if (userProviders.filter(p => p === 'password').length > 0) {
            loginRes = await this.loginWithEmailAndPassword(nvMailAddress, password, false, false);
        }

        if (loginRes) {
            //TODO: tammy - זמני, עד שכל המשתמשים יאשרו את התנאים
            await this.firestoreService.updateUserDetails(this.fireAuthService.getUserFirebase().uid, bAcceptedRules, bMailingList);

            // TODO: tammy - למחוק אחרי כמה זמן שכולם כבר יודעים מה הסיסמה שלהם
            if (userProviders.length > 0 && ((new Date('12/15/2020')).getTime() - this.fireAuthService.getCreationTime(this.fireAuthService.getUserFirebase())) > 0) {// created before 2020.12.15                
                alert(` פרטי המשתמש שלך הם\nמייל: ${nvMailAddress}\nסיסמה: ${password}`);
            }
            return true;
        } else {
            return false;
        }

    }
}
