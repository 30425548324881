import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import "rxjs";
import { ajax } from 'rxjs/ajax';
import { BehaviorSubject, of } from 'rxjs';
//import * as moment from 'moment';
// const regexIso8601 = /^(\d{4}|\+\d{6})(?:-(\d{2})(?:-(\d{2})(?:T(\d{2}):(\d{2}):(\d{2})\.(\d{1,})(Z|([\-+])(\d{2}):(\d{2}))?)?)?)?$/;
import { SYSTEM } from "../../assets/config/config";
import { map, retry, catchError } from 'rxjs/operators';
import { WaitIconService } from './wait-icon.service';

@Injectable()
export class AppProxy {

  constructor(private http: HttpClient) { }

  post(url: string, data: any): Promise<any> {
    return this.http
      .post(`${SYSTEM.base_service_url}Service.svc/${url}`, data)
      .toPromise();
      // debugger
    //TODO: tammy - הודעה למשתמש על נפילות של בעיות בקליטה
    // Http failure response for ... 0 Unknown Error
  }

  get(url: string, data: any, baseUrl?: string): Promise<any> {
    return this.http
      .get(`${(baseUrl ? baseUrl : SYSTEM.base_service_url) + 'Service.svc/'}${url}`, data)
      .toPromise();
  }

  getCurrentTime() {
    return ajax('https://worldtimeapi.org/api/timezone/Asia/Jerusalem')
      .pipe(
        retry(1), // Retry up to 3 times before failing
        map(res => {
          if (!res.response) {
            // throw new Error('Value expected!');
            return null;
          }
          return res.response;
        }),
        catchError(err => of([])))
      .toPromise()
      .catch((error: any) => {
        console.error(`getCurrentTime error. message: ${error.message}`);
      });
  }

  //   private convertData(data, isPost) {
  //     if (!(data instanceof Object) || data instanceof Date) {
  //       let prop = data;
  //       // parse string date
  //       if (
  //         isPost === false &&
  //         (prop instanceof String || typeof prop === "string") &&
  //         prop.indexOf("/Date(") > -1
  //       ) {
  //         let date = prop.substring(
  //           prop.indexOf("(") + 1,
  //           prop.indexOf("+") != -1 ? prop.indexOf("+") : prop.indexOf(")")
  //         );
  //         prop = new Date(parseInt(date));
  //       } else if (isPost && prop instanceof Date) {
  //         // convert to string date
  //         prop=this.getUTCDate(prop)
  //       }
  //       else if (isPost && (typeof prop === "string" || prop instanceof String) && moment(prop.toString(), moment.ISO_8601, true).isValid()) {
  //         var milliseconds = Date.parse(prop.toString())
  //         if (!isNaN(milliseconds)) {
  //           prop =this.getUTCDate(new Date(milliseconds));
  //         }
  //       }
  //       return prop;
  //     }






  //     // parse array / object
  //     let isArr = data instanceof Array;
  //     let arrayData = [];
  //     let objectData = {};

  //     if (data) {
  //       Object.keys(data).forEach(key => {
  //         // dictionary
  //         if (
  //           !isPost &&
  //           isArr &&
  //           data[key] &&
  //           Object.keys(data[key]).length === 2 &&
  //           data[key].Key &&
  //           data[key].Value != null
  //         ) {
  //           arrayData[data[key].Key] = this.convertData(data[key].Value, isPost);
  //         } else if (isArr) {
  //           // array
  //           arrayData.push(this.convertData(data[key], isPost));
  //         } else {
  //           // object
  //           objectData[key] = this.convertData(data[key], isPost);
  //         }
  //       });
  //     }
  //     return isArr ? arrayData : objectData;
  //   }


  //   getUTCDate(date:Date):string
  //   {
  //     let d = Date.UTC(
  //       date.getFullYear(),
  //       date.getMonth(),
  //       date.getDate(),
  //       date.getHours(),
  //       date.getMinutes()
  //     );
  //     let strDate =
  //       d.toString() === "NaN" || d === NaN
  //         ? null
  //         : "/Date(" +
  //         Date.UTC(
  //           date.getFullYear(),
  //           date.getMonth(),
  //           date.getDate(),
  //           date.getHours(),
  //           date.getMinutes()
  //         ) +
  //         ")/";

  //         return strDate;
  //   }

}
