import { Component, OnDestroy, OnInit } from '@angular/core';
import { FireAuthService } from '../../Services/fireauth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WaitIconService } from '../../Services/wait-icon.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../Services/alert.service';
import { AlertType } from '../../Models/Alert';

@Component({
  selector: 'mm-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();
  bSubmited: boolean = true;
  resetPasswordForm = new FormGroup({
    nvMailAddress: new FormControl('', [<any>Validators.required, , Validators.email])
  });

  constructor(
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private fireAuthService: FireAuthService,
    private waitIconService: WaitIconService) {
    this.subscription.add(
      this.activatedRoute.queryParams.subscribe(params => {
        if (params) {
          if (params.nvMailAddress) {
            this.resetPasswordForm.get('nvMailAddress').setValue(params.nvMailAddress);
            this.resetPasswordForm.get('nvMailAddress').markAsDirty();
          }
        }
      })
    );
  }

  ngOnInit() {
  }

  async resetPassword(resetPasswordForm: FormGroup) {
    this.bSubmited = false;

    if (!resetPasswordForm.valid) {
      // this.alertService.addAlert('כדי לאפס את הסיסמה - יש להזין את כתובת המייל שאיתה נרשמת למערכת', AlertType.warning);
      this.alertService.addAlert('forgot-password.alert.email-required', AlertType.warning);
      this.bSubmited = true;
      return;
    }

    this.waitIconService.setWaitVisible(true);
    const exists = await this.fireAuthService.isUserExist(resetPasswordForm.get('nvMailAddress').value);
    if (!exists) {
      this.waitIconService.setWaitVisible(false);
      this.bSubmited = true;
      if (exists === false) {
        // this.alertService.addAlert('לא ניתן לאפס את הסיסמה עבור כתובת מייל זו. האם בטוח שהכתובת תקינה?', AlertType.danger);
        this.alertService.addAlert('forgot-password.alert.email-invalid', AlertType.danger);
      }
      return;
    }

    const resetEmailRes = await this.fireAuthService.sendPasswordResetEmail(resetPasswordForm.get('nvMailAddress').value);
    if (resetEmailRes) {
      // this.alertService.addAlert('שלחנו לכתובת המייל הודעה ובה קישור לאיפוס סיסמה. בהצלחה!', AlertType.success);
      this.alertService.addAlert('forgot-password.alert.email-reset-send', AlertType.success);
    }

    this.waitIconService.setWaitVisible(false);
    this.bSubmited = true;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
