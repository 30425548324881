import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { Alert } from '../Models/Alert';


@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private translate: TranslateService,private sanitizer: DomSanitizer) { }

  private $alerts = new BehaviorSubject<Array<Alert>>(new Array<Alert>());
  private alerts = new Array<Alert>();
  private alertIdentity = 0;

  addAlert(message: string, type: string, duration = 5000, params: any = null) {
    // console.log(this.translate.instant(message))
    const id = this.alertIdentity++;
    this.alerts.push(new Alert(this.sanitizer.bypassSecurityTrustHtml(this.translate.instant(message,params)), type, id));
    this.$alerts.next(this.alerts);
    if (duration > 0) {
      setTimeout(() => {
        this.removeAlert(id);
      }, duration);
    }
  }

  removeAlert(id: number) {
    let index = this.alerts.findIndex(m => m.id = id);
    this.alerts.splice(index, 1);
  }

  removeAlertByIndex(alertIndex: number) {
    this.alerts.splice(alertIndex, 1);
    this.$alerts.next(this.alerts);
  }

  getAlertsAsync() {
    return this.$alerts;
  }

}
