import { Component, OnInit, OnDestroy } from '@angular/core';
import { GameService } from '../../Services/game.service';
import { Subscription } from 'rxjs';
import { Game } from '../../Models/Game';
import { FireAuthService } from '../../Services/fireauth.service';
import { Router } from '@angular/router';
import { FirestoreService } from '../../Services/firestore.service';
import { FirestoreUser } from '../../Models/FirestoreUser';
import { ChatMessageService } from '../../Services/chat-message.service';
import { GlobalService } from '../../Services/global.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  volumMedia = "volumMedia";
  readonly languageData =
    {
      0: { name: "עברית", viewBtnName: "English", i18Name: 'he' },
      1: { name: "English", viewBtnName: "עברית", i18Name: 'en' }

    }
  currentLanguage: number = 0;
  // TODO: tammy - לבטל את ה static ולהעביר דרך סרביס
  static bFooterIcn = true;
  get staticBFooterIcn() {
    return AppComponent.bFooterIcn;
  }

  bShowChat: boolean = false;
  messagedialog: boolean = false;
  title = 'מלחמת מוחות';
  firebaseUser: firebase.User;
  firestoreUser: FirestoreUser;
  uGameId: string;
  bAvoidChat:boolean=false;
  // now;
  bAnderstand: boolean;
  bAccontNav: boolean;
  bOutGameNav: boolean;
  gameNavigationTxt: string;

  subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private firestoreService: FirestoreService,
    private fireAuthService: FireAuthService,
    private gameService: GameService,
    private globalService: GlobalService
    , private translate: TranslateService) {
    this.translate.addLangs(['en', 'he']);
    this.changeLanguage(false);
    this.bAnderstand = localStorage.bAnderstand;
    this.subscription.add(
      this.fireAuthService.getUserFirebaseAsync()
        .subscribe((firebaseUser: firebase.User) => {
          this.firebaseUser = firebaseUser;
        })
    );

    this.subscription.add(
      this.firestoreService.getUserFirestoreAsync()
        .subscribe((firestoreUser: FirestoreUser) => {
          let user;
          if (firestoreUser) {
            user = Object.assign({}, firestoreUser);
            if (firestoreUser['bTemporaryUser']) {
              user['temporaryDisplayName'] = user.nvDisplayName && user.nvDisplayName !== user.nvMailAddress.split('@')[0] ?
                user.nvDisplayName : user['temporaryDisplayName'];
            }
          }
          this.firestoreService = user;
        })
    );

    this.subscription.add(
      this.gameService.getGameAsync()
        .subscribe((game: Game) => {
          if (game)
            this.title = game.nvName;
          else {
            this.translate.get("app.title.milchemet-mochot").subscribe(res => {
              this.title = res;
            })
            // this.title=this.translate.stream()
          }

        })
    );
  }

  ngOnInit() {
    // let timeRes;
    // setInterval(async () => { // replace with timeout
    //   if (SYSTEM.id > 1) {
    //     timeRes = await this.proxy.getCurrentTime()
    //     this.now = timeRes ? timeRes['datetime'] : Date.now();
    //   } else {
    //     this.now = Date.now();
    //   }
    // }, 1000);
  }
  setbAnderstand() {
    this.bAnderstand = true;
    localStorage.bAnderstand = true;
  }
  onRouterOutletActivate(event) {
    this.bAccontNav = true;
    this.bOutGameNav = false;
    this.uGameId = null;
    AppComponent.bFooterIcn = true;
    this.globalService.setCurrentPageAsync(event.constructor.name);
    switch (event.constructor.name) {
      case 'LoginComponent':
      case 'LoginEmailComponent':
      case 'ForgotPasswordComponent':
      case 'ResetAndVerifyComponent':
        this.bAccontNav = false;
        break;
      case 'UserDetailsComponent':
        this.bOutGameNav = true;
        this.gameNavigationTxt = 'game-enter'// 'כניסה למשחק';
        break;
      case 'GameComponent':
        this.bOutGameNav = true;
        this.uGameId = event.route.snapshot.params.uGameId;
        this.messagedialog = false;
        this.gameNavigationTxt = 'game-exit'// 'יציאה מהמשחק';
        break;
    }
    this.bAvoidChat=event.constructor.name=='ReportGameNavigationComponent';
  }

  async logoutTemporaryUser() {
    const bContinue = await confirm(`יציאה מהמשחק תמחק את הפרטים והניקוד שלך. האם ברצונך להמשיך?`);
    if (bContinue) {
      this.router.navigate(['login']);
    }
  }

  changeLanguage(bChange: boolean = true) {
    if (bChange)
      this.currentLanguage = this.currentLanguage == 0 ? 1 : 0;
    this.translate.setDefaultLang(this.languageData[this.currentLanguage].i18Name);
    this.translate.use(this.languageData[this.currentLanguage].i18Name);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  // getMessageDocumentSnapshot() {
  //   this.fireMessageService.getMessageDocumentSnapshot("feedback").then(res => {
  //     console.log(res);
  //   }).catch(err => {
  //     console.log(err);
  //   });
  // }
  // addMessageGameCollection()
  // {
  //   this.fireMessageService.addMessageGameCollection("feedback","00000000-0000-0000-0000-000000000000");
  // }

}
