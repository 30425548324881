
export class User {
    public iUserId: number;
    public uClickerId: string;
    public nvIDNumber: string;
    public nvPhoneNumber: string;
    public nvMailAddress: string;
    public nvPassword: string;
    public nvDisplayName: string;
    public uFirebaseId: string;
    public bAcceptedRules: boolean=true;
    public bMailingList: boolean=false;
    public bIsManager: boolean;

    constructor(uFirebaseId: string, nvDisplayName: string, nvMailAddress: string, nvPassword?: string, bAcceptedRules: boolean = false, bMailingList: boolean = false) {
        this.uFirebaseId = uFirebaseId;
        this.nvDisplayName = nvDisplayName;
        this.nvMailAddress = nvMailAddress;
        this.nvPassword = nvPassword;
        this.bAcceptedRules = bAcceptedRules;
        this.bMailingList = bMailingList;
    }
}
