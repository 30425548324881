import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginService } from '../../Services/login.service';
import { GameService } from '../../Services/game.service';
import { WaitIconService } from '../../Services/wait-icon.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FirestoreService } from '../../Services/firestore.service';
import { FacebookService } from '../../Services/facebook.service';
import { AlertService } from '../../Services/alert.service';
import { AlertType } from '../../Models/Alert';
import { FirebaseService } from 'src/app/Services/firebase.service';
import { SYSTEM } from 'src/assets/config/config';
import { URL_GOOGLE_GROUP } from 'src/app/Models/Constants';

@Component({
  selector: 'mm-login-email',
  templateUrl: './login-email.component.html',
  styleUrls: ['./login-email.component.css']
})
export class LoginEmailComponent implements OnInit, OnDestroy {

  bSubmited: boolean = true;
  subscription: Subscription = new Subscription();
  uGameId: string;
  gameWelcomeTxt: SafeHtml = "";
  nvLicenseText: SafeHtml = "";

  loginForm = new FormGroup({
    nvMailAddress: new FormControl('', [<any>Validators.required, Validators.email]),
    bAcceptedRules: new FormControl(false, [<any>Validators.required, Validators.pattern('true')]),
    bMailingList: new FormControl(false),
    // bAcceptedSendMail: new FormControl(false, [<any>Validators.required, Validators.pattern('true')])
    bAcceptedSendMail: new FormControl(false, [])
  });

  get getGoogleGroupUrl(){
    return URL_GOOGLE_GROUP;
  }
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private loginService: LoginService,
    private gameService: GameService,
    private firestoreService: FirestoreService,
    private firebaseService: FirebaseService,
    private sanitizer: DomSanitizer,
    private facebookService: FacebookService,
    private waitIconService: WaitIconService) {
    this.subscription.add(
      this.activatedRoute.queryParams.subscribe(params => {
        if (params) {
          if (params.nvMailAddress) {
            this.loginForm.get('nvMailAddress').setValue(params.nvMailAddress);
            this.loginForm.get('nvMailAddress').markAsDirty();
          }
          if (params.uGameId) {
            this.uGameId = params.uGameId;
            if (params.uGameId.toLowerCase() == SYSTEM.firebase_config.CONST_GAME.uGameId) {
              this.firebaseService.getSnapshotChanges("currentGameResult/uGameId").then(gameId => {
                if (gameId && gameId != params.uGameId) {
                  this.uGameId = gameId;
                  // console.log(`loginEmail?uGameId=${this.uGameId}`)
                  // this.router.navigate([`loginEmail?uGameId=${gameId}`]);
                  this.router.navigate(
                    [],
                    {
                      relativeTo: this.activatedRoute,
                      queryParams: { uGameId: gameId },
                      queryParamsHandling: 'merge'
                    });
                  this.loadGame();
                } else {
                  this.loadGame();
                }
              });

            } else {

              // כניסה אוטומטית
              // if (localStorage.getItem('nvMailAddress') !== null || localStorage.getItem('nvMailAddress') === '') {
              //   this.router.navigate([`${this.uGameId}/register`]);
              // } else {
              this.loadGame();
              // }
            }

          } else {
            // this.alertService.addAlert('קישור לא תקין', AlertType.danger);
            this.alertService.addAlert('login-email.alert.link-invalid', AlertType.danger);
            this.moveToLogin();
            // TODO: tammy - אחרי הניווט זה מגיעה שוב לפה לפני שעובר סופי ללוגין - למה??
          }
        }
      })
    );
  }
  loadGame() {
    this.loadGameWelcomeTxt(this.uGameId);
    this.loadLicenseTextTxt();
    this.facebookService.addTrack();
  }
  moveToLogin() {
    const queryParams = {};
    const nvMailAddress = this.loginForm.get('nvMailAddress').value.trim();
    if (nvMailAddress) {
      queryParams['nvMailAddress'] = nvMailAddress;
    }
    if (this.uGameId) {
      queryParams['uGameId'] = this.uGameId;
    }
    this.router.navigate([`login`], { queryParams: queryParams });
  }

  ngOnInit() {
    setTimeout(() => { // בשביל לתת זמן לפונקציות שדורשות הרשאה להתבצע לפני יציאת המשתמש מהחשבון
      this.loginService.logOut();
    }, 3000);
  }

  loadGameWelcomeTxt(uGameId: string) {
    this.firestoreService
      .getGameWelcomeTxtDocumentSnapshot(uGameId)
      .then(res => {
        if (res) {
          this.gameWelcomeTxt = this.sanitizer.bypassSecurityTrustHtml(res);
        } else {
          this.gameWelcomeTxt = null;
        }
      })
  }
  loadLicenseTextTxt() {
    this.firebaseService
      .getLicenseTextSnapshot()
      .then(res => {
        if (res) {
          this.nvLicenseText = this.sanitizer.bypassSecurityTrustHtml(res);
        } else {
          this.nvLicenseText = null;
        }
      })
  }

  async submitForm(loginValueForm: FormGroup) {
    this.bSubmited = false;

    if (!loginValueForm.get('nvMailAddress').valid) {
      // this.alertService.addAlert(`יש להכניס מייל כדי להיכנס למשחק`, AlertType.danger, 20000);
      this.alertService.addAlert(`login-email.alert.email-required`, AlertType.danger, 20000);
      this.bSubmited = true;
      return;
    } else if (!loginValueForm.get('bAcceptedRules').valid) {
      this.alertService.addAlert(`login-email.alert.accepted-rules-required`, AlertType.danger, 20000);
      // this.alertService.addAlert(`לא ניתן להירשם ללא הסכמה לתנאים`, AlertType.danger, 20000);
      this.bSubmited = true;
      return;
    } 
    // else if (!loginValueForm.get('bAcceptedSendMail').valid && this.nvLicenseText) {
    //   this.alertService.addAlert(`שכחת לאשר את העברת המייל`, AlertType.danger, 20000);
    //   this.bSubmited = true;
    //   return;
    // }

    const nvMailAddress = loginValueForm.get('nvMailAddress').value.trim();

    this.waitIconService.setWaitVisible(true);
    const res = await this.loginService.loginOrRegisterWithEmail(nvMailAddress, loginValueForm.get('bAcceptedRules').value, loginValueForm.get('bMailingList').value);
    this.waitIconService.setWaitVisible(false);
    this.bSubmited = true;
    if (res == null) {//נכשל ברישום
      return;
    } else if (res) {
      await this.registerUserToGame(nvMailAddress);
      this.router.navigate([`${this.uGameId}/register`]);
    } else {
      await this.registerUserToGame(nvMailAddress);
      this.alertService.addAlert(`סיסמה זריז ונתחיל :)`, AlertType.success, 20000);
      this.moveToLogin();
    }
  }

  async registerUserToGame(nvMailAddress: string) {
    const registerUserGameRes = await this.gameService.registerUserGame(this.uGameId, nvMailAddress);
    if (!registerUserGameRes) {
      this.alertService.addAlert(`אירעה שגיאה במהלך הרישום למשחק`, AlertType.danger, 20000);
    }
  }

  loginAsAnonymous() {
    this.router.navigate([`${this.uGameId}/register`], { queryParams: { email: 'random' } });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

