import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { Question } from '../../Models/Question';
import { SysTable } from '../../Services/SysTables.service';
import { Subscription } from 'rxjs';
import { FirebaseService } from '../../Services/firebase.service';
import { ResponseService } from '../../Services/response.service';
import { ClickerResponse } from '../../Models/Response';
import { ClickerOnGame } from '../../Models/ClickerOnGame';
import { FirestoreService } from '../../Services/firestore.service';
import { WaitIconService } from '../../Services/wait-icon.service';
import { FirestoreClickerOnGame, FirestoreClickerOnGameGroup } from '../../Models/FirestoreClickerOnGame';
import { AlertService } from '../../Services/alert.service';
import { AlertType } from '../../Models/Alert';
import { ClickerOnGameService } from '../../Services/clicker-on-game.service';
import { FireAuthService } from '../../Services/fireauth.service';

@Component({
  selector: 'mm-clicker-question',
  templateUrl: './clicker-question.component.html',
  styleUrls: ['./clicker-question.component.scss']
})
export class ClickerQuestionComponent implements OnInit, OnDestroy {

  constantQuestionTypes = SysTable.constantQuestionType;
  readonly MAX_ALERT = 3;
  bSubmited: boolean = true;
  subscription: Subscription = new Subscription();
  uClickerResponsed: string = '0';
  currentQuestion: Question;
  iMaxAnswer = 0;
  iAlertCounter = { reciverClose: 0, duplicateResponse: 0 }
  @Input() uGameId: string;
  @Input() bShowResults: boolean;
  @Input() iVotingStatus: number;
  @Input() myVotingOpenTime: number;
  @Input() clickerOnGame: FirestoreClickerOnGame;

  @Output() myVotingOpenTimeChange = new EventEmitter<number>();

  constructor(
    private alertService: AlertService,
    private firebaseService: FirebaseService,
    private fireAuthService: FireAuthService,
    private responseService: ResponseService,
    private firestoreService: FirestoreService,
    private clickerOnGameService: ClickerOnGameService,
    private waitIconService: WaitIconService) { }

  ngOnInit() {
    this.updatingQuestionChanges();
    this.updatingMaxAnswerChanges();
  }

  updatingQuestionChanges() {
    this.subscription.add(
      this.firebaseService
        .getCurrentQuestionChanges(this.uGameId)
        .subscribe(async currentQuestion => {
          const oldQuestionId = this.currentQuestion ? this.currentQuestion.uQuestionId : null;
          this.currentQuestion = currentQuestion;
          if (this.currentQuestion === null ||
            (this.currentQuestion && oldQuestionId && this.currentQuestion.uQuestionId != oldQuestionId)) {
            this.uClickerResponsed = null;
            this.bShowResults = false;

          } else if (this.uClickerResponsed === '0' || this.currentQuestion.iStatusType === SysTable.constantStatusTypeQuestion.done) {
            const response = await this.responseService.getResponseByQuestion(this.currentQuestion.uQuestionId, this.firestoreService.getUserFirestore().uClickerId);
            if (response && response[0]) {
              this.uClickerResponsed = response[0].uAnswerId;
            } else {
              this.uClickerResponsed = null;
            }
          }
        })
    );
  }

  updatingMaxAnswerChanges() {
    this.subscription.add(
      this.firebaseService
        .getMaxAnswerChanges(this.uGameId)
        .subscribe(iMaxAnswer => {
          this.iMaxAnswer = iMaxAnswer;
        })
    );
  }

  async sendAnswer(uAnswerId: string) {
    this.bSubmited = false;

    if (this.iVotingStatus === SysTable.VotingStatus.LoadAnswersClickers
      && (this.uClickerResponsed === null || this.uClickerResponsed === '0' ||
        this.currentQuestion.iType !== SysTable.constantQuestionType.regular)) {

      if (this.clickerOnGame && this.clickerOnGame.clickerOnGameGroups && this.clickerOnGame.clickerOnGameGroups.length > 0) {

        if (this.clickerOnGame.iStatus !== SysTable.constantClickerStatus.play) {
          this.waitIconService.setWaitVisible(true);
          const activateRes = await this.clickerOnGameService.updateClickerStatus(
            this.fireAuthService.getUserFirebase().uid, this.firestoreService.getUserFirestore().iUserId,
            this.uGameId, this.clickerOnGame.clickerOnGameGroups, SysTable.constantClickerStatus.play);
          this.waitIconService.setWaitVisible(false);
          if (!activateRes) {
            this.alertService.addAlert('השחקן שלך מושהה מהמשחק ולא יכול לענות תשובות', AlertType.danger);
            this.bSubmited = true;
            return;
          }
        }

        let responseTime;
        if (this.myVotingOpenTime) {// אם זמן הפתיחה עדיין שמור אצלינו = חישוב לפני זמן פתיחת ההצבעה בדפדפן
          responseTime = new Date().getTime() - this.myVotingOpenTime;
        } else {// חישוב לפי זמן פתיחת ההצבעה בשרת
          // const serverVotingOpenTime = await this.firebaseService.getVotingOpenTime(this.uGameId);
          // responseTime = (long)(DateTime.UtcNow.Subtract(new DateTime(1970, 1, 1))).TotalMilliseconds  -  serverVotingOpenTime;
          responseTime = 30000;
          // this.myVotingOpenTime = ??; // TODO: tammy - to complete (חישוב זמן לפי השרת או מספר קבוע)
        }

        const lstResponse = new Array<ClickerResponse>();
        this.clickerOnGame.clickerOnGameGroups.forEach(cog => {
          const response: ClickerResponse = new ClickerResponse();
          response.uClickerOnGameId = cog.uClickerOnGameId;
          response.uQuestionId = this.currentQuestion.uQuestionId;
          response.iResponseTime = responseTime;
          response.uAnswerId = uAnswerId;
          response.iSource = SysTable.constantResponseSource.web;
          // response.iIndexResponse = ; // מתעדכן בשרת
          lstResponse.push(response);
        });

        // TODO: tammy - firestoreService.addResponseLst
        // this.firestoreService.addResponseLst(this.uGameId, this.currentQuestion.uQuestionId, lstResponse)
        // ----------------------------
        this.waitIconService.setWaitVisible(true);
        const insertRes = await this.responseService.insertResponsesWeb(this.firestoreService.getUserFirestore().iUserId, lstResponse, this.uGameId, this.myVotingOpenTime ? false : true);
        this.waitIconService.setWaitVisible(false);
        if (insertRes) {
          if (this.myVotingOpenTime) {
            this.alertService.addAlert('תגובתך נקלטה תוך ' + (responseTime / 1000) + ' שניות מאז שנפתחה המערכת ', AlertType.success);
          } else {
            this.alertService.addAlert('תגובתך נקלטה במערכת', AlertType.success);
          }
          this.uClickerResponsed = uAnswerId;
          this.myVotingOpenTime = 0;
          this.myVotingOpenTimeChange.emit(0);
        } else {
          this.alertService.addAlert('שים לב! תגובתך לא נקלטה במערכת', AlertType.danger);
        }
      }

    } else if (this.uClickerResponsed !== null && this.uClickerResponsed !== '0' &&
      this.currentQuestion.iType == SysTable.constantQuestionType.regular) {
      if (this.iAlertCounter.duplicateResponse >= this.MAX_ALERT) {
        setTimeout(() => { this.iAlertCounter.duplicateResponse = 0 }, 6000);
      } else {
        this.alertService.addAlert('כבר ענית תשובה פעם אחת', AlertType.warning,2000);
        this.iAlertCounter.duplicateResponse++;
      }

    } else {
      if (this.iAlertCounter.reciverClose >= this.MAX_ALERT) {
        setTimeout(() => { this.iAlertCounter.reciverClose = 0 }, 6000);
      } else {
        this.alertService.addAlert('ההצבעה סגורה כרגע', AlertType.warning,2000);
        this.iAlertCounter.reciverClose++;
      }
    }

    this.bSubmited = true;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.target["localName"] == "body" && Number(event.key) <= this.iMaxAnswer) {
      let answer = this.currentQuestion.lstAnswers.find(a => (a.cClickerSign.charCodeAt(0) - 64).toString() == event.key);
      if (answer) {
        this.sendAnswer(answer.uAnswerId);
      }
    }
  }
}
