import { Injectable } from '@angular/core';
//import { ChatMessage } from '@app/models/chatMessage.model';
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel
} from '@microsoft/signalr';
import { BehaviorSubject, Subject } from 'rxjs';

export class ChatMessage {
  public sent: string;
  public message: string;
  constructor(public _message: string = '') {
    this.sent = new Date().toISOString();
    this.message = _message;
  }
}

@Injectable({ providedIn: 'root' })
export class NotificationService {
  messageReceived$ = new Subject<ChatMessage>();
  connectionEstablished$ = new BehaviorSubject<boolean>(false);

  private hubConnection: HubConnection;

  // constructor() {
  //   this.createConnection();
  //   this.registerOnServerEvents();
  //   this.startConnection();
  // }

  // sendChatMessage(message: ChatMessage) {
  //   this.hubConnection.invoke('SendMessage', message).catch(err=>{
  //     console.info(err);
  //   });
  // }

  // private createConnection() {
  //   this.hubConnection = new HubConnectionBuilder()
  //     .withUrl(environment.baseUrls.server + 'coolmessages')
  //     .withAutomaticReconnect()
  //     .configureLogging(LogLevel.Information)
  //     .build();
  // }

  // private startConnection() {
  //   if (this.hubConnection.state === HubConnectionState.Connected) {
  //     return;
  //   }

  //   this.hubConnection.start().then(
  //     () => {
  //       console.log('Hub connection started!');
  //       this.connectionEstablished$.next(true);
  //     },
  //     error => console.error(error)
  //   );
  // }

  // private registerOnServerEvents(): void {

  //   this.hubConnection.on('Send', (data: any) => {
  //     console.log('data', data);
  //     this.messageReceived$.next(data);
  //   });

  // }
}
