
//export const CONST_GAME={uGameId:"d33ccdd6-c5e9-4ff8-b689-e5b52828e423",nvSecretCode:"222444"}
//export const 
const QA_firebaseConfig = {
  apiKey: 'AIzaSyCmxqAUKLd9KfZyF1CV2TKqHhseHNTgpWw',
  authDomain: 'milchemetmochot-ae634.firebaseapp.com',
  databaseURL: 'https://milchemetmochot-ae634.firebaseio.com',
  projectId: 'milchemetmochot-ae634',
  storageBucket: 'milchemetmochot-ae634.appspot.com',
  messagingSenderId: '692469997326',
  appId: '1:692469997326:web:3b39f6b300f27387fd415f',
  measurementId: 'G-V64R1Q0V98',
  CONST_GAME:{uGameId:"7554d472-245f-46a0-a605-6e9f7387c24e",nvSecretCode:"33473907"}
};
const live_firebaseConfig = {
  apiKey: 'AIzaSyAbLoO0PDJoRSsqB8Zh0QJ813MLg3E4lDI',
  authDomain: 'milchemet-mochot.firebaseapp.com',
  databaseURL: 'https://milchemet-mochot.firebaseio.com',
  projectId: 'milchemet-mochot',
  storageBucket: 'milchemet-mochot.appspot.com',
  messagingSenderId: '77803117558',
  appId: '1:77803117558:web:324fff3dc89e5c1e9185d7',
  measurementId: 'G-4G5RK4E42G',
  CONST_GAME:{uGameId:"d33ccdd6-c5e9-4ff8-b689-e5b52828e423",nvSecretCode:"222444"}
};

export const SYSTEMS = {
  DEV: {
    id: 1,
    base_service_url: 'http://localhost/MilchemetMochot/Service/',
    //base_service_url: 'http://localhost:10236/Service/',
    firebase_config: live_firebaseConfig
  },
  LOCAL: {
    id: 2,
    base_service_url: 'http://localhost/MilchemetMochot/Service/',
    firebase_config: live_firebaseConfig
  },
  WEBIT_TRACK: {
    id: 3,
    base_service_url: 'http://qa.webit-track.com/MilchemetMochot/Service/',
    firebase_config: QA_firebaseConfig
  },
  WEBIT_QA: {
    id: 4,
    base_service_url: 'http://qa.webit-track.com/MilchemetMochotTest/Service/',
    firebase_config: QA_firebaseConfig
  },
  QA: {
    id: 5,
    base_service_url: 'http://91.202.169.81/MilchemetMochotQA/Service/',
    firebase_config: QA_firebaseConfig
  },
  LIVE: {
    id: 6,
    base_service_url: 'https://thebrainswar.com/Service/',
    firebase_config: live_firebaseConfig
  },
  ARCHIVE: {
    id: 7,
    base_service_url: 'http://91.202.169.81/MilchemetMochotArchive/Service/',
    firebase_config: live_firebaseConfig
  }
};
const system = localStorage.getItem('system');
console.log(system);
export const SYSTEM = SYSTEMS[system];
